import { useEffect, useRef } from 'react';
import { MdErrorOutline } from 'react-icons/md';
import ReactCodeInput from 'react-code-input';
import './InputPIN.css';

const InputPIN = ({ feedback, fields, value, ...props }) => {
  const inputPINRef = useRef();

  useEffect(() => {
    for (let index = 0; index < fields; index += 1) {
      inputPINRef.current.state.input[index] = value[index] || '';
    }
  }, [value]);

  return (
    <div>
      <ReactCodeInput
        ref={inputPINRef}
        fields={fields}
        value={value}
        inputStyleInvalid={{ color: 'black' }}
        isValid={!feedback}
        {...props}
      />

      {feedback && (
        <div className="text-red-500 text-xs mt-3 flex items-center space-x-1">
          <MdErrorOutline size={16} />
          <span>{feedback}</span>
        </div>
      )}
    </div>
  );
};

export default InputPIN;
