import { SERVICE_TRANSACTION } from '@/services';
import { HELPERS } from '@/utils';
import { TYPES_TRANSACTION } from './transaction.types';

const getTransactionList = (params) => {
  return (dispatch) => {
    dispatch({ type: TYPES_TRANSACTION.SET_TRANSACTION_LIST_LOADING, isLoading: true });

    SERVICE_TRANSACTION.getTransactionList(params, (res) => {
      if (res.error) HELPERS.showNotificationServiceError(res.error);

      dispatch({
        type: TYPES_TRANSACTION.GET_TRANSACTION_LIST,
        payload: res.payload,
        error: res.error,
      });
    });
  };
};

const getTransactionDetail = (invoiceNumber) => {
  return (dispatch) => {
    dispatch({ type: TYPES_TRANSACTION.SET_TRANSACTION_DETAIL_LOADING, isLoading: true });

    SERVICE_TRANSACTION.getTransactionDetail(invoiceNumber, (res) => {
      if (res.error) HELPERS.showNotificationServiceError(res.error);

      dispatch({
        type: TYPES_TRANSACTION.GET_TRANSACTION_DETAIL,
        payload: res.payload,
        error: res.error,
      });
    });
  };
};

export const ACTION_TRANSACTION = { getTransactionList, getTransactionDetail };
