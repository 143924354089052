import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Tabs } from '@/components/atoms';
import ProfileHeader from './components/ProfileHeader';
import IDPSaya from './tabs/IDPSaya';
import PerformaSaya from './tabs/PerformaSaya';
import MyModule from './tabs/MyModule';
import MyTicket from './tabs/MyTicket';

const Profile = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const TABS = [
    { title: IDPSaya.Title.key, hash: IDPSaya.Hash, active: !location.hash },
    { title: PerformaSaya.Title.key, hash: PerformaSaya.Hash },
    { title: MyModule.Title.key, hash: MyModule.Hash },
    { title: MyTicket.Title.key, hash: MyTicket.Hash },
  ];

  return (
    <>
      <ProfileHeader />
      <div className="overflow-x-scroll">
        <Container>
          <div className="inline-flex">
            {TABS.map((tab) => (
              <Tabs
                key={tab.title}
                title={t(tab.title)}
                active={tab.active || location.hash === tab.hash}
                onClick={() => history.push(tab.hash)}
              />
            ))}
          </div>
        </Container>
      </div>
      <div className="bg-gray-100">
        <Container>
          <div className="py-8">
            {(!location.hash || location.hash === IDPSaya.Hash) && <IDPSaya />}
            {location.hash === PerformaSaya.Hash && <PerformaSaya />}
            {location.hash === MyModule.Hash && <MyModule />}
            {location.hash === MyTicket.Hash && <MyTicket />}
          </div>
        </Container>
      </div>
    </>
  );
};

export default Profile;
