import { useEffect } from 'react';
import { Line, Doughnut } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Button, ContentLoader } from '@/components/atoms';
import { ACTION_PROFILE } from '@/redux/actions';
import PATH from '@/routes/path';

const PerformaSaya = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const reportMonthlyLoading = useSelector((state) => state.profile.reportMonthly.isLoading);
  const reportMonthlyData = useSelector((state) => state.profile.reportMonthly.payload);
  const reportSummaryLoading = useSelector((state) => state.profile.reportSummary.isLoading);
  const reportSummaryData = useSelector((state) => state.profile.reportSummary.payload);

  useEffect(() => {
    const startDate = moment().subtract(6, 'months').format('YYYY-MM-DD');
    const endDate = moment().format('YYYY-MM-DD');

    dispatch(ACTION_PROFILE.getReportMonthly(startDate, endDate));
    dispatch(ACTION_PROFILE.getReportSummary());
  }, []);

  return (
    <div className="space-y-8">
      <div className="bg-white md:py-8 grid grid-cols-2 md:divide-x-2 md:grid-cols-4 rounded-md">
        <div className="px-3 py-8 md:py-3 text-center w-full odd:border-r-2 md:odd:border-r-0 border-b md:border-b-0 border-t md:border-0">
          <div className="text-3xl font-bold text-gray-500 mb-3">2</div>
          <div className="text-sm text-gray-500">{t('modul_berjalan')}</div>
        </div>
        <div className="px-3 py-8 md:py-3 text-center w-full odd:border-r-2 md:odd:border-r-0 border-b md:border-b-0 border-t md:border-0">
          <div className="text-3xl font-bold text-gray-500 mb-3">0</div>
          <div className="text-sm text-gray-500">{t('modul_selesai')}</div>
        </div>
        <div className="px-3 py-8 md:py-3 text-center w-full odd:border-r-2 md:odd:border-r-0 border-b md:border-b-0 border-t md:border-0">
          <div className="text-3xl font-bold text-gray-500 mb-3">-</div>
          <div className="text-sm text-gray-500">{t('nilai_rata_rata')}</div>
        </div>
        <div className="px-3 py-8 md:py-3 text-center w-full odd:border-r-2 md:odd:border-r-0 border-b md:border-b-0 border-t md:border-0">
          <div className="text-3xl font-bold text-gray-500 mb-3">0</div>
          <div className="text-sm text-gray-500">{t('modul_belum_berjalan')}</div>
        </div>
      </div>

      <div className="md:flex space-y-6 md:space-y-0 md:space-x-10 items-center bg-blue-100 p-8 rounded-md">
        <div>
          <p className="text-gray-500">
            The process may seem strange and yet it is very true. I did not so much gain the knowledge of things by
            the words, as words by the experience I had of things. <br />
            <br /> ― Plutarch
          </p>
        </div>
        <div>
          <Button
            linkTo={PATH.CATALOG}
            className="w-full md:w-44"
            size={Button.SIZES.LARGE}
            variant={Button.VARIANTS.SECONDARY}
          >
            {t('explore_module')}
          </Button>
        </div>
      </div>

      <div className="md:flex space-y-8 md:space-y-0 md:space-x-8">
        <div className="bg-white rounded-md p-8 w-full">
          <div className="mb-3">
            <div className="text-2xl font-bold mb-2 text-gray-500">{t('aktivitas')}</div>
            <div className="text-gray-500">{t('penyelesaian_materi_setiap_bulan')}</div>
          </div>
          {reportMonthlyLoading && <ContentLoader className="mt-5" />}
          {!reportMonthlyLoading && reportMonthlyData && (
            <Line
              data={{
                labels: reportMonthlyData.map((item) => item.label),
                datasets: [
                  {
                    label: t('materi_selesai'),
                    data: reportMonthlyData.map((item) => item.topic_count),
                    backgroundColor: [
                      'rgba(255, 99, 132, 0.2)',
                      'rgba(54, 162, 235, 0.2)',
                      'rgba(255, 206, 86, 0.2)',
                      'rgba(75, 192, 192, 0.2)',
                      'rgba(153, 102, 255, 0.2)',
                      'rgba(255, 159, 64, 0.2)',
                      'rgba(120, 89, 84, 0.2)',
                      'rgba(12, 120, 84, 0.2)',
                      'rgba(12, 80, 154, 0.2)',
                      'rgba(9, 100, 14, 0.2)',
                      'rgba(100, 100, 54, 0.2)',
                      'rgba(200, 60, 100, 0.2)',
                    ],
                    borderColor: [
                      'rgba(255, 99, 132, 1)',
                      'rgba(54, 162, 235, 1)',
                      'rgba(255, 206, 86, 1)',
                      'rgba(75, 192, 192, 1)',
                      'rgba(153, 102, 255, 1)',
                      'rgba(255, 159, 64, 1)',
                      'rgba(120, 89, 84, 1)',
                      'rgba(12, 120, 84, 1)',
                      'rgba(12, 80, 154, 1)',
                      'rgba(9, 100, 14, 1)',
                      'rgba(100, 100, 54, 1)',
                      'rgba(200, 60, 100, 1)',
                    ],
                    borderWidth: 1,
                  },
                ],
              }}
            />
          )}
        </div>

        <div className="flex flex-col items-center bg-white rounded-md p-8 md:w-1/3">
          <div className="mb-3">
            <div className="text-2xl font-bold mb-2 text-gray-500">{t('pencapaian_modul')}</div>
            <div className="text-gray-500">{t('dari_jumlah_materi_seluruh_modul_yang_berjalan_dan_selesai')}</div>
          </div>
          {reportSummaryLoading && <ContentLoader className="mt-5" />}
          {!reportSummaryLoading && reportSummaryData && (
            <Doughnut
              data={{
                labels: [t('sudah_selesai'), t('on_progress')],
                datasets: [
                  {
                    data: [reportSummaryData.completed_courses, reportSummaryData.enrolled_courses],
                    backgroundColor: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)'],
                    borderWidth: 1,
                  },
                ],
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

PerformaSaya.Title = {
  value: 'Performa Saya',
  key: 'my_performance',
};

PerformaSaya.Hash = '#performa-saya';

export default PerformaSaya;
