import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { notify } from 'react-notify-toast';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PATH from '@/routes/path';
import { VALIDATION_SCHEMA } from '@/utils';
import { InputText, Button } from '@/components/atoms';
import { ACTION_AUTH } from '@/redux/actions';
import { CLIENT, KEY } from '@/config';
import AuthContainer from './components/AuthContainer';

const INITIAL_VALUES = {
  name: '',
  phone: '',
  email: '',
  password: '',
  confirmPassword: '',
};

const Register = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);

  const handleRegister = (value) => {
    const data = {
      name: value.name,
      phone: value.phone,
      password: value.password,
      email: value.email,
      client_token: KEY.CLIENT_TOKEN,
    };

    dispatch(
      ACTION_AUTH.register(data, () => {
        history.push(PATH.AUTH_LOGIN);
        notify.show(
          `Terima kasih sudah mendaftar di ${CLIENT.NAME}, Mohon cek email Anda terlebih dahulu untuk aktivasi email.`,
          'success'
        );
      })
    );
  };

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      validationSchema={VALIDATION_SCHEMA.registerSchema}
      onSubmit={handleRegister}
    >
      {({ values, handleChange, handleSubmit, setFieldValue, getFieldMeta }) => (
        <Form onSubmit={handleSubmit}>
          <AuthContainer>
            <div className="mb-12">
              <h1 className="text-2xl font-semibold mb-3 text-primary">
                {t('auth_sign_up_to').replace('[CLIENT_NAME]', CLIENT.NAME)}
              </h1>
              <p className="text-gray-500 text-sm">
                {t('auth_create_your_account').replace('[CLIENT_NAME]', CLIENT.NAME)}
              </p>
            </div>
            <div className="mb-12 space-y-6">
              <InputText
                variant={InputText.VARIANTS.MATERIAL}
                name="name"
                placeholder={t('form_input.fullname')}
                onChange={handleChange}
                value={values.name}
                meta={getFieldMeta('name')}
              />

              <InputText
                variant={InputText.VARIANTS.MATERIAL}
                name="email"
                placeholder="Email"
                onChange={handleChange}
                value={values.email}
                meta={getFieldMeta('email')}
              />

              <InputText
                variant={InputText.VARIANTS.MATERIAL}
                className="mb-3"
                name="phone"
                icon={<span className="text-sm text-gray-400">+62</span>}
                placeholder={t('form_input.phone')}
                onChange={(event) => {
                  const { value } = event.target;
                  setFieldValue('phone', value[0] === '0' ? value.substr(1, value.length) : value, true);
                }}
                value={values.phone}
                meta={getFieldMeta('phone')}
              />

              <InputText
                className="mb-3"
                variant={InputText.VARIANTS.MATERIAL}
                onChange={handleChange}
                iconSuffix={
                  <button
                    className="hover:text-gray-400"
                    type="button"
                    onClick={() => setIsShowPassword(!isShowPassword)}
                  >
                    {isShowPassword ? <FaEye size={20} /> : <FaEyeSlash size={20} />}
                  </button>
                }
                type={isShowPassword ? 'text' : 'password'}
                name="password"
                placeholder="Password"
                value={values.password}
                autoComplete="off"
                meta={getFieldMeta('password')}
              />

              <InputText
                className="mb-3"
                variant={InputText.VARIANTS.MATERIAL}
                onChange={handleChange}
                iconSuffix={
                  <button
                    className="hover:text-gray-400"
                    type="button"
                    onClick={() => setIsShowConfirmPassword(!isShowConfirmPassword)}
                  >
                    {isShowConfirmPassword ? <FaEye size={20} /> : <FaEyeSlash size={20} />}
                  </button>
                }
                type={isShowConfirmPassword ? 'text' : 'password'}
                name="confirmPassword"
                placeholder={t('form_input.confirm_password')}
                value={values.confirmPassword}
                autoComplete="off"
                meta={getFieldMeta('confirmPassword')}
              />
            </div>
            <Button className="w-full mb-6" submit>
              {t('auth_register')}
            </Button>
            <div className="text-gray-400 text-sm text-center">
              {t('auth_already_have_an_account')}{' '}
              <Link to={PATH.AUTH_LOGIN} className="link">
                {t('here')}
              </Link>
            </div>
          </AuthContainer>
        </Form>
      )}
    </Formik>
  );
};

export default Register;
