const Tabs = ({ title, active, onClick, className }) => {
  return (
    <button
      key={title}
      className={`w-40 md:w-auto text-center py-3 md:px-6 text-sm border-b-2 ${
        active ? 'border-primary text-primary' : 'border-transparent text-gray-500'
      } ${className}`}
      type="button"
      onClick={onClick}
    >
      <span className="w-60">{title}</span>
    </button>
  );
};

export default Tabs;
