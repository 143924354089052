import * as TYPES_ACTIVITY from './activity.types';

const showCatalogMenu = (show) => {
  return (dispatch) => {
    dispatch({ type: show ? TYPES_ACTIVITY.SHOW_CATALOG_MENU : TYPES_ACTIVITY.HIDE_CATALOG_MENU });
  };
};

const toggleSideNav = () => {
  return (dispatch) => dispatch({ type: TYPES_ACTIVITY.TOGGLE_SIDENAV });
};

const showTopLoadingBar = (show) => {
  return (dispatch) => {
    dispatch({ type: show ? TYPES_ACTIVITY.SHOW_TOP_LOADING_BAR : TYPES_ACTIVITY.HIDE_TOP_LOADING_BAR });
  };
};

const showHeader = (show) => {
  return (dispatch) => {
    dispatch({ type: show ? TYPES_ACTIVITY.SHOW_HEADER : TYPES_ACTIVITY.HIDE_HEADER });
  };
};

const showFooter = (show) => {
  return (dispatch) => {
    dispatch({ type: show ? TYPES_ACTIVITY.SHOW_FOOTER : TYPES_ACTIVITY.HIDE_FOOTER });
  };
};

const showBottomNav = (show) => {
  return (dispatch) => {
    dispatch({ type: show ? TYPES_ACTIVITY.SHOW_BOTTOM_NAV : TYPES_ACTIVITY.HIDE_BOTTOM_NAV });
  };
};

export const ACTION_ACTIVITY = {
  showCatalogMenu,
  toggleSideNav,
  showTopLoadingBar,
  showHeader,
  showFooter,
  showBottomNav,
};
