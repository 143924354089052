import { ImQuotesLeft } from 'react-icons/im';
import { BackgroundImage } from '@/components/atoms';
import PropTypes from 'prop-types';

const TestimonialGrid = ({ backgroundClassName, backgroundOpacity, overlayOpacity, items, overlayColor }) => {
  return (
    <div className="md:flex md:items-stretch">
      {items.map((item) => (
        <div key={item.author} className="flex-1">
          <div className="relative h-full">
            <BackgroundImage
              className={backgroundClassName}
              src={item.backgroundSrc}
              opacity={backgroundOpacity}
              overlayOpacity={overlayOpacity}
              overlayColor={overlayColor}
            />
            <div className="px-14 lg:px-20 xl:px-40">
              <div className="py-14 text-white">
                <ImQuotesLeft className="text-xl mb-5" />
                <p className="text-xl">{item.testimonial}</p>
                <div className="mt-5">{item.author}</div>
                <div className="mt-1">{item.institution}</div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

TestimonialGrid.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.string,
      role: PropTypes.string,
      intitution: PropTypes.string,
      testimonial: PropTypes.string.isRequired,
      bgSrc: PropTypes.string,
    })
  ).isRequired,
  overlayColor: PropTypes.string,
  overlayOpacity: PropTypes.number,
  backgroundOpacity: PropTypes.number,
  backgroundClassName: PropTypes.string,
};

TestimonialGrid.defaultProps = {
  overlayColor: null,
  backgroundClassName: '',
  backgroundOpacity: 0.5,
  overlayOpacity: 0.5,
};

export default TestimonialGrid;
