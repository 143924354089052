import { TYPES_PROFILE } from './profile.types';

const INITIAL_STATE = {
  updateProfile: {
    isLoading: false,
  },
  events: {
    payload: null,
    isLoading: true,
  },
  module: {
    payload: null,
    isLoading: true,
  },
  reportMonthly: {
    payload: null,
    isLoading: true,
  },
  reportSummary: {
    payload: null,
    isLoading: true,
  },
};

const profileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES_PROFILE.PROFILE_UPDATE_LOADING:
      return {
        ...state,
        updateProfile: { isLoading: action.isLoading },
      };

    case TYPES_PROFILE.GET_EVENT_TICKETS_LOADING:
      return { ...state, events: { isLoading: action.isLoading } };
    case TYPES_PROFILE.GET_EVENT_TICKETS_DATA:
      return {
        ...state,
        events: { payload: action.payload, error: action.error, isLoading: action.isLoading },
      };

    case TYPES_PROFILE.GET_MODULE_LOADING:
      return { ...state, module: { isLoading: action.isLoading } };
    case TYPES_PROFILE.GET_MODULE_DATA:
      return {
        ...state,
        module: { payload: action.payload, error: action.error, isLoading: action.isLoading },
      };

    case TYPES_PROFILE.GET_REPORT_MONTHLY_LOADING:
      return { ...state, reportMonthly: { isLoading: action.isLoading } };
    case TYPES_PROFILE.GET_REPORT_MONTHLY_DATA:
      return {
        ...state,
        reportMonthly: { payload: action.payload, error: action.error, isLoading: action.isLoading },
      };

    case TYPES_PROFILE.GET_REPORT_SUMMARY_LOADING:
      return { ...state, reportSummary: { isLoading: action.isLoading } };
    case TYPES_PROFILE.GET_REPORT_SUMMARY_DATA:
      return {
        ...state,
        reportSummary: { payload: action.payload, error: action.error, isLoading: action.isLoading },
      };

    default:
      return state;
  }
};

export default profileReducer;
