import { TYPES_QUIZ } from './quiz.types';

const INITIAL_STATE = {
  data: {
    payload: null,
    error: null,
    isLoading: false,
  },
  assessment: {
    payload: null,
    isLoading: false,
  },
};

const quizReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES_QUIZ.GET_QUIZ_LOADING:
      return { ...state, data: { isLoading: action.isLoading } };
    case TYPES_QUIZ.GET_QUIZ:
      return {
        ...state,
        data: {
          payload: action.payload,
          error: action.error,
          isLoading: false,
        },
      };

    case TYPES_QUIZ.POST_QUIZ_ASSESSMENT_LOADING:
      return { ...state, assessment: { isLoading: action.isLoading } };
    case TYPES_QUIZ.POST_QUIZ_ASSESSMENT:
      return {
        ...state,
        assessment: {
          payload: action.payload,
          error: action.error,
          isLoading: false,
        },
      };

    default:
      return state;
  }
};

export default quizReducer;
