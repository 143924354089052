import { useSelector } from 'react-redux';
import CourseTopicList from './CourseTopicList';

function CourseTabTopicList({ className, activeResource, onChangeActiveResource }) {
  const topicList = useSelector((state) => state.course.detail.payload?.modules);
  const isLoading = useSelector((state) => state.course.detail.loading);

  return (
    <div className={className}>
      {!isLoading &&
        topicList.length > 0 &&
        topicList.map((chapter, index) => (
          <CourseTopicList
            key={chapter.uuid}
            title={chapter.title}
            resources={chapter.topics}
            activeResource={activeResource}
            onChangeActiveResource={onChangeActiveResource}
            open={activeResource ? activeResource.chapterIndex === index : index === 0}
          />
        ))}
    </div>
  );
}

CourseTabTopicList.Hash = '#topicList';
CourseTabTopicList.Title = {
  value: 'Topics',
  translationKey: 'topic',
};

export default CourseTabTopicList;
