import { SERVICE_HOME } from '@/services';
import { HELPERS } from '@/utils';
import { GET_HOME_DATA, GET_HOME_LOADING } from './home.types';

const getHomeData = () => {
  return (dispatch) => {
    dispatch({ type: GET_HOME_LOADING });

    SERVICE_HOME.getHomeData((res) => {
      if (res.error) HELPERS.showNotificationServiceError(res.error);

      dispatch({
        type: GET_HOME_DATA,
        payload: res.payload,
        error: res.error,
      });
    });
  };
};

export const ACTION_HOME = { getHomeData };
