import { TYPES_CATEGORY } from './category.types';

const INITIAL_STATE = {
  list: {
    payload: null,
    loading: true,
  },
};

const categoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES_CATEGORY.GET_CATEGORY_LIST_DATA_LOADING:
      return { ...state, list: { loading: action.loading || true } };
    case TYPES_CATEGORY.GET_CATEGORY_LIST_DATA:
      return { ...state, list: { payload: action.payload, loading: false } };
    default:
      return state;
  }
};

export default categoryReducer;
