import { useState } from 'react';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import { MdReport } from 'react-icons/md';
import useOnClickOutside from 'react-cool-onclickoutside';
import { useTranslation } from 'react-i18next';
import QnaReportModalForm from './QnaReportModalForm';

const QnaDotsDropdownButton = ({ uuid }) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenReportModalForm, setIsOpenReportModalForm] = useState(false);

  const ref = useOnClickOutside(() => setIsOpen(false));

  return (
    <>
      <QnaReportModalForm
        uuid={uuid}
        open={isOpenReportModalForm}
        onClose={() => setIsOpenReportModalForm(false)}
      />

      <div className="relative">
        <button
          className="hover:bg-gray-100 rounded-md p-1 transition-all"
          type="button"
          onClick={() => setIsOpen(!isOpen)}
        >
          <HiOutlineDotsVertical size={16} />
        </button>
        {isOpen && (
          <div ref={ref} className="absolute animate-fade-in-down z-10 right-0 mt-1">
            <div className="bg-white overflow-hidden rounded-md shadow-md">
              <div className="flex flex-col">
                <button
                  className="flex items-center justify-start space-x-3 bg-red-500 text-white hover:bg-red-400 transition-all py-2 px-5"
                  type="button"
                  onClick={() => {
                    setIsOpenReportModalForm(true);
                    setIsOpen(false);
                  }}
                >
                  <MdReport size={16} />
                  <span className="text-sm">{t('report')}</span>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default QnaDotsDropdownButton;
