const PROFILE_UPDATE = 'PROFILE_UPDATE';
const PROFILE_UPDATE_LOADING = 'PROFILE_UPDATE_LOADING';
const GET_EVENT_TICKETS_DATA = 'GET_EVENT_TICKETS_DATA';
const GET_EVENT_TICKETS_LOADING = 'GET_EVENT_TICKETS_LOADING';
const GET_MODULE_DATA = 'GET_MODULE_DATA';
const GET_MODULE_LOADING = 'GET_MODULE_LOADING';
const GET_REPORT_MONTHLY_LOADING = 'GET_REPORT_MONTHLY_LOADING';
const GET_REPORT_MONTHLY_DATA = 'GET_REPORT_MONTHLY_DATA';
const GET_REPORT_SUMMARY_LOADING = 'GET_REPORT_SUMMARY_LOADING';
const GET_REPORT_SUMMARY_DATA = 'GET_REPORT_SUMMARY_DATA';

export const TYPES_PROFILE = {
  PROFILE_UPDATE_LOADING,
  PROFILE_UPDATE,
  GET_EVENT_TICKETS_DATA,
  GET_EVENT_TICKETS_LOADING,
  GET_MODULE_DATA,
  GET_MODULE_LOADING,
  GET_REPORT_MONTHLY_LOADING,
  GET_REPORT_MONTHLY_DATA,
  GET_REPORT_SUMMARY_LOADING,
  GET_REPORT_SUMMARY_DATA,
};
