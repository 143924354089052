import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CgDanger } from 'react-icons/cg';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { notify } from 'react-notify-toast';
import { Button, Container } from '@/components/atoms';
import PATH from '@/routes/path';
import { ACTION_ACTIVITY, ACTION_COURSE } from '@/redux/actions';
import { CONSTANTS, HELPERS } from '@/utils';
import { ProductShare } from '@/components/molecules';
import CourseTopicList from './components/CourseTopicList';
import CourseContent from './components/CourseContent';
import CourseHeader from './components/CourseHeader';

const CourseDetail = () => {
  // ========================== HOOKS INITIATORS
  const { t } = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  // ========================== REDUX DATA
  const payload = useSelector((state) => state.course.detail.payload);
  const isLoading = useSelector((state) => state.course.detail.loading);
  const isLoggedIn = useSelector((state) => state.auth.loggedIn);

  // ========================== COMMON VARIABLES
  const productType = CONSTANTS.PRODUCT_TYPE.COURSE;
  const slug = params.uid;

  // ========================== STATES
  const [activeResource, setActiveResource] = useState(null);
  const [activeChapterIndex, setActiveChapterIndex] = useState(null);

  // ========================== HANDLER FUNCTIONS
  const handleLoadData = () => {
    dispatch(ACTION_COURSE.getCourseDetailData(slug));
  };

  const handleChangeActiveResource = (selectedResource) => {
    if (!isLoggedIn) {
      notify.show(t('anda_harus_login_terlebih_dahulu'), 'warning');
      history.push(`${PATH.AUTH_LOGIN}?redirect=${PATH.COURSE}/${slug}`);
    } else if (selectedResource.locked && !payload.enrolled) {
      notify.show(t('anda_belum_terdaftar_di_course_ini'), 'warning');
    } else if (selectedResource.locked) {
      notify.show(t('anda_harus_menyelesaikan_resource_sebelumnya_terlebih_dahulu'), 'warning');
    } else setActiveResource(selectedResource);
  };

  const handleInitialActiveResource = () => {
    if (payload) {
      const chapters = payload.modules;

      let selectedResource = null;

      chapters.some((chapter, chapterIndex) => {
        const isSelected = chapter.topics.some((resource, resourceIndex) => {
          const isLastIndexChapter = chapterIndex === chapters.length - 1;
          const isLastIndexResource = resourceIndex === chapter.topics.length - 1;
          selectedResource = { ...resource, isLastResource: isLastIndexChapter && isLastIndexResource };
          return !resource.locked && !resource.completed; // break
        });

        if (isSelected) setActiveChapterIndex(chapterIndex);
        return isSelected; // break
      });

      setActiveResource(selectedResource);
    }
  };

  const handleBuyCourse = () => {
    const { title, price, uuid } = payload;

    history.push(`${PATH.PAYMENT}`, { title, productType, price, slug, uuid });
  };

  // ========================== EFFECT FUNCTIONS
  useEffect(() => handleLoadData(), [slug]);

  useEffect(() => handleInitialActiveResource(), [payload]);

  useEffect(() => {
    dispatch(ACTION_ACTIVITY.showBottomNav(false));
    return () => {
      dispatch(ACTION_ACTIVITY.showBottomNav(true));
    };
  }, []);

  return (
    <div className="bg-gray-100 pt-8 pb-12">
      <Container>
        <div className="shadow-2xl">
          <div className="bg-white">
            <div>
              {isLoading && <CourseHeader.Skeleton />}

              {!isLoading && payload && (
                <CourseHeader
                  slug={slug}
                  title={payload.title}
                  lecturerName={payload.lecturer.name}
                  showCertificate={payload.show_certificate && payload.completed}
                  payload={payload}
                />
              )}
            </div>
            <div className="flex">
              <div className="lg:w-1/3 border-r-2 hidden lg:block">
                {isLoading && <CourseTopicList.Skeleton />}

                {!isLoading && payload.modules.length === 0 && (
                  <div className="p-6 text-center text-gray-400 h-full flex flex-col items-center justify-center space-y-5">
                    <CgDanger size={42} />
                    <span>{t('no_chapters_found')}</span>
                  </div>
                )}

                {!isLoading &&
                  payload.modules.length > 0 &&
                  activeResource &&
                  payload.modules.map((chapter, index) => (
                    <CourseTopicList
                      key={chapter.uuid}
                      title={chapter.title}
                      resources={chapter.topics}
                      activeResource={activeResource}
                      onChangeActiveResource={handleChangeActiveResource}
                      open={activeChapterIndex ? activeChapterIndex === index : index === 0}
                    />
                  ))}
              </div>
              <div className="w-full lg:w-2/3">
                {isLoading && <CourseContent.Skeleton />}
                {!isLoading && payload && (
                  <CourseContent
                    slug={slug}
                    courseUUID={payload.uuid}
                    enrolled={payload.enrolled}
                    thumbnail={payload.course_image}
                    activeResource={activeResource}
                    onChangeActiveResource={handleChangeActiveResource}
                  />
                )}
              </div>
            </div>
          </div>
          <ProductShare containerClassName="flex justify-center items-center bg-gray-100 py-3" />
        </div>
      </Container>

      {!isLoading && payload && !payload.enrolled && (
        <Container className="fixed z-20 bottom-0 left-1/2 transform -translate-x-1/2">
          <div
            className="flex items-center space-x-6 justify-end bg-white w-full p-4 md:p-6"
            style={{ boxShadow: '0 0 2rem rgba(0, 0, 0, 0.15)' }}
          >
            <div>
              <div className="text-sm">{t('price')}</div>
              <div className="text-primary font-semibold text-lg">
                {payload.price === 0 && `GRATIS`}
                {payload.price !== 0 && `Rp ${HELPERS.numberFormat(payload.price)}-`}
              </div>
            </div>

            <Button
              className="text-base font-semibold"
              onClick={handleBuyCourse}
              size={Button.SIZES.MEDIUM}
              variant={Button.VARIANTS.PRIMARY}
            >
              {t('buy_course')}
            </Button>
          </div>
        </Container>
      )}
    </div>
  );
};

export default CourseDetail;
