import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { MdClose } from 'react-icons/md';
import Backdrop from '../Backdrop/Backdrop';

const Modal = ({ title, children, open, onClose, onOpen }) => {
  useEffect(() => onOpen(), []);

  return open ? (
    <div className="fixed flex items-center justify-center left-0 top-0 w-full h-screen z-50 px-5">
      <Backdrop onClick={onClose} active={open} />
      <div className="relative z-10 bg-white rounded-md px-8 py-5 max-w-screen-sm w-full">
        <div className="flex items-center border-b justify-between pb-3 mb-3">
          <div className="text-gray-800 font-semibold text-lg">{title}</div>
          <button type="button" onClick={onClose}>
            <MdClose size={22} />
          </button>
        </div>
        <div>{children}</div>
      </div>
    </div>
  ) : (
    ''
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  title: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
};

Modal.defaultProps = {
  children: '',
  title: '',
  open: false,
  onClose: () => {},
  onOpen: () => {},
};

export default Modal;
