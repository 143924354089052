import i18n from 'i18next';
import moment from 'moment/min/moment-with-locales.min';
import { useEffect, useState } from 'react';
import { FiGlobe } from 'react-icons/fi';

const LanguageSwitcher = ({ containerClassName, className }) => {
  const [activeLanguage, setActiveLanguage] = useState(i18n.language);

  const handleSwitchLanguage = (lang) => {
    moment.locale(lang);
    i18n.changeLanguage(lang);
  };

  useEffect(() => setActiveLanguage(i18n.language), [i18n.language]);

  return (
    <label
      htmlFor="languageSwitcher"
      className={`${containerClassName} inline-flex items-center border rounded-md px-4 pr-2 hover:bg-gray-50 cursor-pointer`}
    >
      <FiGlobe size={24} />
      {activeLanguage && (
        <select
          id="languageSwitcher"
          className={`${className} rounded-md text-sm w-full border-0 focus:ring-0 bg-transparent cursor-pointer`}
          onChange={(event) => handleSwitchLanguage(event.target.value)}
          defaultValue={activeLanguage}
        >
          {LanguageSwitcher.languageOptions.map(({ value, label }) => (
            <option key={value} className="text-sm" value={value}>
              {label}
            </option>
          ))}
        </select>
      )}
    </label>
  );
};

LanguageSwitcher.languages = {
  en: {
    label: 'EN',
    value: 'en',
  },
  id: {
    label: 'ID',
    value: 'id',
  },
};

LanguageSwitcher.languageOptions = Object.values(LanguageSwitcher.languages);

LanguageSwitcher.defaultProps = {
  className: '',
  containerClassName: '',
};

export default LanguageSwitcher;
