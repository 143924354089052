import PropTypes from 'prop-types';
import './BackgroundImage.css';

const BackgroundImage = ({ className, src, opacity, alt, overlayColor, overlayOpacity }) => {
  const opacityStyle = { opacity };
  const overlayOpacityStyle = { opacity: overlayOpacity };
  const overlayColorStyle = overlayColor ? { background: overlayColor } : null;

  return (
    <div className="background-image absolute left-0 top-0 w-full h-full">
      <div
        className={`absolute left-0 top-0 w-full h-full z-10 ${className}`}
        style={{
          ...overlayOpacityStyle,
          ...overlayColorStyle,
        }}
      />
      <img className="w-full h-full object-cover" style={opacityStyle} src={src} alt={alt} />
    </div>
  );
};

BackgroundImage.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  opacity: PropTypes.number,
  overlayColor: PropTypes.string,
  overlayOpacity: PropTypes.number,
};

BackgroundImage.defaultProps = {
  className: '',
  src: null,
  opacity: 1,
  alt: null,
  overlayColor: '',
  overlayOpacity: 0.5,
};

export default BackgroundImage;
