import { FaFacebook, FaLink, FaTwitter, FaWhatsapp } from 'react-icons/fa';
import { TwitterShareButton, FacebookShareButton, WhatsappShareButton } from 'react-share';
import { notify } from 'react-notify-toast';
import { Button } from '@/components/atoms';
import { useTranslation } from 'react-i18next';

const ProductShare = ({ containerClassName, iconSize }) => {
  const { t } = useTranslation();
  const URL = window.location.href;

  const handleShare = () => {};

  const handleCopyLink = () => {
    const tempInput = document.createElement('input');
    const text = window.location.href;

    document.body.appendChild(tempInput);
    tempInput.value = text;
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);

    notify.show('Link berhasil dicopy!', 'success');
  };

  return (
    <div className={`${containerClassName}`}>
      <div className="mr-3">{t('share_to')} : </div>
      <div className="flex items-center space-x-3">
        <Button title="Copy Link" className="rounded-full" size={Button.SIZES.SQUARE} onClick={handleCopyLink}>
          <FaLink size={iconSize || 16} />
        </Button>
        <TwitterShareButton url={URL}>
          <Button className="rounded-full" size={Button.SIZES.SQUARE} onClick={handleShare}>
            <FaTwitter size={iconSize || 16} />
          </Button>
        </TwitterShareButton>
        <FacebookShareButton url={URL}>
          <Button className="rounded-full" size={Button.SIZES.SQUARE} onClick={handleShare}>
            <FaFacebook size={iconSize || 16} />
          </Button>
        </FacebookShareButton>
        <WhatsappShareButton url={URL}>
          <Button className="rounded-full" size={Button.SIZES.SQUARE} onClick={handleShare}>
            <FaWhatsapp size={iconSize || 16} />
          </Button>
        </WhatsappShareButton>
      </div>
    </div>
  );
};

export default ProductShare;
