import { TYPES_PROFILE } from '../profile/profile.types';
import { TYPES_AUTH } from './auth.types';

const INITIAL_STATES = {
  profile: null,
  token: null,
  loggedIn: false,
  forgotPassword: {
    isLoading: false,
  },
  resetPassword: {
    isLoading: false,
  },
  changePassword: {
    isLoading: false,
  },
};

const authReducer = (state = INITIAL_STATES, action) => {
  switch (action.type) {
    case TYPES_AUTH.LOGIN:
      return {
        ...state,
        profile: action.profile,
        token: action.token,
        loggedIn: action.token !== null,
      };

    case TYPES_AUTH.FORGOT_PASSWORD_LOADING:
      return {
        ...state,
        forgotPassword: { isLoading: action.isLoading },
      };

    case TYPES_AUTH.RESET_PASSWORD_LOADING:
      return {
        ...state,
        resetPassword: { isLoading: action.isLoading },
      };

    case TYPES_AUTH.CHANGE_PASSWORD_LOADING:
      return {
        ...state,
        changePassword: { isLoading: action.isLoading },
      };

    case TYPES_PROFILE.PROFILE_UPDATE:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.profile,
        },
      };
    case TYPES_AUTH.LOGOUT:
      return {
        ...state,
        profile: null,
        token: null,
        loggedIn: false,
      };
    default:
      return state;
  }
};

export default authReducer;
