import { SERVICE_PAYMENT } from '@/services';
import { HELPERS } from '@/utils';
import { ACTION_ACTIVITY } from '../activity/activity.action';
import { TYPES_PAYMENT } from './payment.types';

const submitPayment = (uuid, activeVoucher, productType, paymentMethod, itemData, quantity, callback) => {
  return (dispatch) => {
    dispatch(ACTION_ACTIVITY.showTopLoadingBar(true));
    dispatch({ type: TYPES_PAYMENT.SET_SUBMIT_PAYMENT_LOADING, isLoading: true });

    SERVICE_PAYMENT.submitPayment(uuid, activeVoucher, productType, paymentMethod, itemData, quantity, (res) => {
      dispatch(ACTION_ACTIVITY.showTopLoadingBar(false));
      dispatch({ type: TYPES_PAYMENT.SET_SUBMIT_PAYMENT_LOADING, isLoading: false });

      if (res.error) HELPERS.showNotificationServiceError(res.error);
      else if (res.payload && callback) callback(res.payload);

      dispatch({
        type: TYPES_PAYMENT.SUBMIT_PAYMENT,
        payload: res.payload,
        error: res.error,
      });
    });
  };
};

const getVoucherCode = (voucherCode) => {
  return (dispatch) => {
    dispatch({ type: TYPES_PAYMENT.SET_VOUCHER_CODE_LOADING, isLoading: true });
    dispatch(ACTION_ACTIVITY.showTopLoadingBar(true));

    SERVICE_PAYMENT.getVoucherCode(voucherCode, (res) => {
      dispatch(ACTION_ACTIVITY.showTopLoadingBar(false));
      if (res.error) HELPERS.showNotificationServiceError(res.error);

      dispatch({
        type: TYPES_PAYMENT.GET_VOUCHER_CODE,
        payload: res.payload,
        error: res.error,
      });
    });
  };
};

const getBankAccounts = () => {
  return (dispatch) => {
    dispatch({ type: TYPES_PAYMENT.SET_BANK_ACCOUNTS_LOADING, isLoading: true });
    dispatch(ACTION_ACTIVITY.showTopLoadingBar(true));

    SERVICE_PAYMENT.getBankAccounts((res) => {
      dispatch(ACTION_ACTIVITY.showTopLoadingBar(false));
      if (res.error) HELPERS.showNotificationServiceError(res.error);

      dispatch({
        type: TYPES_PAYMENT.GET_BANK_ACCOUNTS,
        payload: res.payload,
        error: res.error,
      });
    });
  };
};

const resetVoucherCode = () => {
  return (dispatch) => {
    dispatch({
      type: TYPES_PAYMENT.GET_VOUCHER_CODE,
      payload: null,
      error: null,
    });
  };
};

const confirmTransfer = (invoiceNumber, formData, callback) => {
  return (dispatch) => {
    dispatch(ACTION_ACTIVITY.showTopLoadingBar(true));
    dispatch({ type: TYPES_PAYMENT.SET_UPLOAD_RECEIPT_LOADING, isLoading: true });

    SERVICE_PAYMENT.confirmTransfer(invoiceNumber, formData, (res) => {
      if (res.error) HELPERS.showNotificationServiceError(res.error);

      dispatch(ACTION_ACTIVITY.showTopLoadingBar(false));

      if (res.error) HELPERS.showNotificationServiceError(res.error);

      if (callback) callback(res);

      dispatch({
        type: TYPES_PAYMENT.PUT_UPLOAD_RECEIPT,
        payload: res.payload,
        error: res.error,
      });
    });
  };
};

const cancelPayment = (invoiceNumber, callback) => {
  return (dispatch) => {
    dispatch({ type: TYPES_PAYMENT.SET_CANCEL_PAYMENT_LOADING, isLoading: true });

    SERVICE_PAYMENT.cancelPayment(invoiceNumber, (res) => {
      if (res.error) HELPERS.showNotificationServiceError(res.error);
      if (callback) callback(res);
      dispatch({ type: TYPES_PAYMENT.SET_CANCEL_PAYMENT_LOADING, isLoading: false });
    });
  };
};

export const ACTION_PAYMENT = {
  getBankAccounts,
  confirmTransfer,
  submitPayment,
  getVoucherCode,
  resetVoucherCode,
  cancelPayment,
};
