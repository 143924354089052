import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import parser from 'html-react-parser';
import { Button } from '@/components/atoms';
import { HELPERS } from '@/utils';
import { CLIENT } from '@/config';
import PATH from '@/routes/path';

const CourseTabKilasan = ({ className }) => {
  const { t } = useTranslation();
  const payload = useSelector((state) => state.course.detail.payload);
  const loading = useSelector((state) => state.course.detail.loading);

  const handleInstructorAvatarBroken = (e) => {
    e.target.src = require('@/images/avatar/avatar.png');
  };

  return (
    <div className={className}>
      <div className="space-y-6 pb-4 border-b">
        {loading && <Skeleton height={40} />}
        {!loading && <div className="text-lg font-semibold">{payload.title}</div>}

        {loading && <Skeleton width={120} height={22} />}
        {!loading && <div className="text-sm">{payload.total_resource} Resource</div>}

        {loading && <Skeleton height={35} width={150} className="mt-2" />}
        {!loading && payload && !payload.enrolled && (
          <div className="space-y-1">
            <div className="text-sm">{t('price')}</div>
            <div className="font-semibold text-2xl text-primary">
              {payload.price === 0 && `GRATIS`}
              {payload.price !== 0 && `Rp ${HELPERS.numberFormat(payload.price)}-`}
            </div>
          </div>
        )}
      </div>

      <div className="py-4 border-b">
        {loading && (
          <Skeleton
            count={5}
            height={36}
            inline
            containerClassName="grid grid-cols-2 md:grid-cols-3 md:grid-cols-4 gap-3"
          />
        )}
        {!loading && (
          <div className="flex flex-wrap gap-2">
            {payload?.categories?.map((category) => (
              <Button
                key={category.id}
                className="rounded-full"
                size={Button.SIZES.SMALL2X}
                variant={Button.VARIANTS.SECONDARY_OUTLINE}
                linkTo={`${PATH.CATALOG_COURSE}&category_id=${category.id}`}
              >
                {category.name}
              </Button>
            ))}
          </div>
        )}
      </div>

      <div className="py-4 border-b">
        {loading && <Skeleton count={3} />}
        {!loading && <div className="text-sm text-gray-500 leading-relaxed">{parser(payload.description)}</div>}
      </div>

      {loading && (
        <div className="flex space-x-3 items-center py-4">
          <Skeleton width={65} height={65} circle containerClassName="flex items-center" />
          <div>
            <Skeleton width={120} height={25} className="mb-2" />
          </div>
        </div>
      )}

      {!loading && payload.lecturers.length === 0 && payload.lecturer && (
        <div className="flex space-x-3 items-center py-4">
          <img
            className="w-16 mr-3"
            src={payload.lecturer.avatar}
            onError={handleInstructorAvatarBroken}
            alt={`${CLIENT.NAME_SECONDARY} Instructor ${payload.lecturer.name}`}
          />
          <div>
            <div>
              <div className="font-semibold text-primary">{payload.lecturer.name}</div>
              <div className="text-sm text-gray-500">{t('instructor')}</div>
            </div>
          </div>
        </div>
      )}

      {!loading && payload.lecturers.length > 0 && (
        <div className="grid sm:grid-cols-2 gap-3 py-4">
          {payload.lecturers.map((lecturer) => (
            <div className="flex space-x-3 items-center">
              <img
                className="w-16 mr-3"
                src={lecturer.avatar}
                onError={handleInstructorAvatarBroken}
                alt={`${CLIENT.NAME_SECONDARY} Instructor ${lecturer.name}`}
              />
              <div>
                <div>
                  <div className="font-semibold text-primary">{lecturer.name}</div>
                  <div className="text-sm text-gray-500">{t('instructor')}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

CourseTabKilasan.Hash = '#kilasan';
CourseTabKilasan.Title = {
  value: 'Kilasan',
  translationKey: 'overview',
};

export default CourseTabKilasan;
