import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@/components/atoms';
import { ACTION_QUIZ } from '@/redux/actions';
import './Quiz.css';
import Skeleton from 'react-loading-skeleton';

const Quiz = ({ topicUUID, completed, isLastResource, onClickGoToTheNextResource }) => {
  const dispatch = useDispatch();

  const quiz = useSelector((state) => state.quiz.data.payload);
  const isLoading = useSelector((state) => state.quiz.data.isLoading);
  const assessment = useSelector((state) => state.quiz.assessment.payload);
  const isAssessmentLoading = useSelector((state) => state.quiz.assessment.isLoading);

  const quizUUID = quiz?.uuid || null;
  const title = quiz?.title || '';
  const questions = quiz?.questions || [];

  const isPassedOrCompleted = assessment?.is_passed || completed;
  const correctQuestionCount = assessment?.correct_answer_count;
  const questionCount = assessment?.question_count;
  const score = Math.round((correctQuestionCount / questionCount) * 100);

  const [activeIndex, setActiveIndex] = useState(0);
  const [answers, setAnswers] = useState(questions && questions.length ? Array(questions.length) : []);
  const [error, setError] = useState('');

  const isSubmitted = questions && activeIndex + 1 > questions.length;

  const handleGetQuiz = () => dispatch(ACTION_QUIZ.getQuiz(topicUUID));

  const handleClickNext = () => {
    if (answers[activeIndex]) {
      setActiveIndex((prevState) => prevState + 1);
    } else setError('harap pilih jawaban');
  };

  const handleChangeAnswer = (event) => {
    const questionUUID = questions[activeIndex].uuid;
    const optionUUID = event.target.value;

    answers[activeIndex] = { [questionUUID]: optionUUID };
    setAnswers([...answers]);
    setError('');
  };

  const handleSubmit = () => {
    dispatch(
      ACTION_QUIZ.submitQuiz(quizUUID, answers, () => {
        onClickGoToTheNextResource();
      })
    );
    handleClickNext();
  };

  const handleReattemptQuiz = () => {
    setAnswers(Array(questions.length));
    setActiveIndex(0);
    dispatch(ACTION_QUIZ.resetAssessment());
  };

  useEffect(() => {
    handleGetQuiz();
    return () => handleReattemptQuiz();
  }, [topicUUID]);

  return (
    <div className="space-y-6 p-10">
      {!title && <Skeleton width={100} height={20} />}
      {title && <div className="font-semibold">{title}</div>}

      {isSubmitted && isAssessmentLoading && (
        <div className="flex flex-col items-center space-y-6 py-28">
          <img className="w-24" src={require('@/images/icons/assessment_process.png')} alt="" />
          <p className="text-sm text-gray-400">Waiting for assessment...</p>
        </div>
      )}

      {((isSubmitted && !isAssessmentLoading && assessment) || isPassedOrCompleted) && (
        <div className="flex flex-col items-center space-y-6 pt-3 pb-6">
          {isPassedOrCompleted && <img className="w-32" src={require('@/images/icons/quiz_success.png')} alt="" />}
          {!isPassedOrCompleted && <img className="w-32" src={require('@/images/icons/quiz_failed.png')} alt="" />}
          <div className="space-y-2 text-center flex flex-col items-center">
            {!Number.isNaN(score) && (
              <div className="space-y-2">
                <div className="font-semibold">Skor Kuis Anda :</div>
                <div className={`text-5xl font-semibold ${isPassedOrCompleted ? 'text-primary' : 'text-red-500'}`}>
                  {score}
                </div>
              </div>
            )}

            <div className="text-sm w-8/12">
              {isPassedOrCompleted && 'Selamat! Anda dinyatakan lulus dari Kuis ini.'}
              {!isPassedOrCompleted &&
                'Sayang sekali Anda belum dinyatakan lulus dari Kuis ini. Namun jangan khawatir, Anda bisa mengulang kembali materi dan kuisnya. Semangat!'}
            </div>
          </div>
          {isPassedOrCompleted && !isLastResource && (
            <div className="w-full border-t border-gray-200 mt-6 pt-6 flex justify-center items-center">
              <Button onClick={onClickGoToTheNextResource}>Lanjut Ke Topik Berikutnya</Button>
            </div>
          )}
          {!isPassedOrCompleted && (
            <div className="w-full border-t border-gray-200 mt-6 pt-6 flex justify-center items-center">
              <Button onClick={handleReattemptQuiz}>Ulang Kuis</Button>
            </div>
          )}
        </div>
      )}

      {!isPassedOrCompleted && isLoading && (
        <div className="flex flex-col items-center space-y-6 py-28">
          <img className="w-24" src={require('@/images/icons/assessment_process.png')} alt="" />
          <p className="text-sm text-gray-400">Loading...</p>
        </div>
      )}

      {!isPassedOrCompleted && !isLoading && questions.length === 0 && (
        <div className="text-center text-sm text-gray-400 italic py-32">Question is empty</div>
      )}

      {!isPassedOrCompleted && !isLoading && activeIndex + 1 <= questions.length && (
        <div>
          {questions.map((question, index) => (
            <div key={question.uuid}>
              {activeIndex === index && (
                <div className="space-y-6">
                  <div className="text-lg">
                    <span className="font-semibold">{index + 1}.</span> {question.content}
                  </div>
                  <div className="space-y-3">
                    {question.answers.map((option) => (
                      <label
                        key={option.uuid}
                        htmlFor={option.uuid}
                        className={`w-full cursor-pointer flex items-center justify-between px-6 py-3 border border-primary rounded-md text-sm ${
                          answers &&
                          answers.length &&
                          answers[activeIndex] &&
                          answers[activeIndex][question.uuid] === option.uuid.toString()
                            ? 'bg-primary text-white'
                            : 'bg-tertiary-100'
                        }`}
                      >
                        <span>{option.content}</span>
                        <input
                          id={option.uuid}
                          name={question.content}
                          value={option.uuid}
                          className="text-primary"
                          type="radio"
                          onChange={handleChangeAnswer}
                        />
                      </label>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}

          {error && <div className="text-sm text-red-500 py-6">{error}</div>}

          <div className="border-t border-gray-200 mt-6 pt-3">
            {questions && (
              <div className="flex justify-between items-center">
                <div className="text-sm">
                  <span className="font-semibold">{activeIndex + 1}</span> dari{' '}
                  <span className="font-semibold">{questions.length}</span> pertanyaan
                </div>
                <div className="space-x-3">
                  {activeIndex + 1 === questions.length && <Button onClick={handleSubmit}>Lihat Skor</Button>}
                  {activeIndex + 1 !== questions.length && <Button onClick={handleClickNext}>Selanjutnya</Button>}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Quiz;
