import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import ReactToPdf from 'react-to-pdf';
import { FaDownload } from 'react-icons/fa';
import { Button, CompanyLogo, Container } from '@/components/atoms';
import { ACTION_COURSE } from '@/redux/actions';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';

const Certificate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { uuid: slug } = useParams();

  const certificateRef = useRef();

  const reportData = useSelector((state) => state.course.report.payload);
  const reportLoading = useSelector((state) => state.course.report.loading);

  const CERTIFICATE_WIDTH = 1132;
  const CERTIFICATE_HEIGHT = 794;
  const CERTIFICATE_OPTIONS = {
    orientation: 'landscape',
  };

  const handleClickBack = () => {
    history.goBack();
  };

  useEffect(() => {
    if (slug) dispatch(ACTION_COURSE.getCourseReport(slug));
  }, [slug]);

  return (
    <div className="bg-gray-100 py-5">
      <Container className="space-y-6">
        <div className="bg-white shadow-md">
          <div className="p-6 border-b">
            {(reportLoading || !reportData) && <Skeleton height={50} />}
            {!reportLoading && reportData && <h1 className="text-xl font-bold">{reportData.title}</h1>}
          </div>
          <div className="xl:flex items-center justify-center p-6 overflow-scroll">
            <div
              ref={certificateRef}
              className="relative w-full flex flex-col items-center rounded-md justify-center text-center bg-gray-100 p-20 space-y-10"
              style={{
                width: CERTIFICATE_WIDTH,
                height: CERTIFICATE_HEIGHT,
                minWidth: CERTIFICATE_WIDTH,
                minHeight: CERTIFICATE_HEIGHT,
              }}
            >
              <div>
                <CompanyLogo width={180} />
              </div>
              <div>
                {(reportLoading || !reportData) && <Skeleton height={70} />}
                {!reportLoading && reportData && <h1 className="text-6xl font-bold">{reportData.student_name}</h1>}
              </div>
              <div className="space-y-1">
                <div>Telah menyelesaikan kursus berjudul</div>
                {(reportLoading || !reportData) && <Skeleton height={50} />}
                {!reportLoading && reportData && (
                  <div className="text-3xl text-primary font-semibold">{reportData.title}</div>
                )}
              </div>
              <div className="space-y-1 text-lg">
                {(reportLoading || !reportData) && <Skeleton height={30} />}
                {!reportLoading && reportData && (
                  <div>
                    Dengan Nilai : <strong>{reportData.final_grade}</strong>
                  </div>
                )}
                <div>Pada Modul yang resmi terdaftar dalam aplikasi</div>
                {reportData?.principal_name && reportData?.principal_handsign && (
                  <div className="text-xs text-center">
                    {(reportLoading || !reportData) && <Skeleton height={15} />}
                    {!reportLoading &&
                      reportData &&
                      moment(reportData.completed_at).locale('id').format('DD MMMM YYYY')}
                    <br />
                    {(reportLoading || !reportData) && <Skeleton height={15} />}
                    {!reportLoading && reportData && reportData.certificate_number}
                  </div>
                )}
              </div>
              <div className="absolute left-1/2 transform -translate-x-1/2 bottom-12">
                {reportData?.principal_name && reportData?.principal_handsign && (
                  <div className="flex flex-col items-center justify-center">
                    <img src={reportData?.principal_handsign} alt="Principal Handsign" />
                    <div className="text-xs text-center ">{reportData?.principal_name}</div>
                  </div>
                )}

                {!reportData?.principal_name && !reportData?.principal_handsign && (
                  <div className="text-xs text-center">
                    {(reportLoading || !reportData) && <Skeleton height={15} />}
                    {!reportLoading &&
                      reportData &&
                      moment(reportData.completed_at).locale('id').format('DD MMMM YYYY')}
                    <br />
                    {(reportLoading || !reportData) && <Skeleton height={15} />}
                    {!reportLoading && reportData && reportData.certificate_number}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="px-6 py-3 flex items-center border-t justify-center space-x-3">
            <Button variant={Button.VARIANTS.PRIMARY_OUTLINE} onClick={handleClickBack}>
              Kembali
            </Button>
            {reportData && (
              <ReactToPdf
                targetRef={certificateRef}
                filename={`Certificate_${reportData.title}_${reportData.student_name}_${moment(
                  reportData.completed_at
                )
                  .locale('id')
                  .format('DD MMMM YYYY')}.pdf`}
                options={CERTIFICATE_OPTIONS}
              >
                {({ toPdf }) => (
                  <Button className="space-x-3 border-t" onClick={toPdf}>
                    <span>
                      <FaDownload />
                    </span>
                    <span>Unduh Sertifikat</span>
                  </Button>
                )}
              </ReactToPdf>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Certificate;
