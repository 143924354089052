import { SERVICE_FAQ } from '@/services/faq.service';
import { TYPES_FAQ } from '@/redux/faq/faq.types';

const getQuestionData = () => {
  return (dispatch) => {
    dispatch({ type: TYPES_FAQ.GET_FAQ_DATA_LOADING, loading: true });

    SERVICE_FAQ.getQuestionData((res) => {
      dispatch({ type: TYPES_FAQ.GET_FAQ_DATA_LOADING, loading: false });

      dispatch({
        type: TYPES_FAQ.GET_FAQ_LIST_DATA,
        payload: res.payload,
        error: res.error,
      });
    });
  };
};

export const ACTION_FAQ = { getQuestionData };
