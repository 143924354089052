import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Button, InputTextArea, Modal } from '@/components/atoms';
import { VALIDATION_SCHEMA } from '@/utils';

const INIITAL_VALUES = {
  message: '',
};

function QnaModalFormAsk({ onClose, onOpen, open, uuid, onSubmit, isLoading }) {
  const { t } = useTranslation();

  const handleSubmitQna = (values) => {
    onSubmit(values.message, uuid);
  };

  return (
    <Modal title={t('tanyakan_sesuatu')} onClose={onClose} onOpen={onOpen} open={open}>
      <Formik
        initialValues={INIITAL_VALUES}
        onSubmit={handleSubmitQna}
        validationSchema={VALIDATION_SCHEMA.qnaCommentSchema}
      >
        {({ values, handleSubmit, handleChange, getFieldMeta }) => (
          <Form className="mt-10">
            <div className="flex flex-col space-y-3">
              <InputTextArea
                name="message"
                value={values.message}
                disabled={isLoading}
                label={t('masukkan_pertanyaan_anda')}
                meta={getFieldMeta('message')}
                onChange={handleChange}
              />
              <Button onClick={handleSubmit} isLoading={isLoading}>
                {t('ask')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default QnaModalFormAsk;
