const CLIENT_WEB = 'web';
const CLIENT_TOKEN = 'aa6d8694eea3d1b52aa6e4169b0a822665b56fc4';
const CLIENT_TOKEN_GOOGLE = 'ef82ef838e94ff1f3bfe43cadb7eb569b954d196';
const GOOGLE_CLIENT_ID = '630400928712-afbcvk16v23f7snia092fe30kmvoirgv.apps.googleusercontent.com';
const GOOGLE_CLIENT_SECRET = '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';

export const KEY = {
  CLIENT_WEB,
  CLIENT_TOKEN,
  CLIENT_TOKEN_GOOGLE,
  GOOGLE_CLIENT_ID,
  GOOGLE_CLIENT_SECRET,
};
