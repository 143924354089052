import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BiLoaderAlt, BiZoomIn } from 'react-icons/bi';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import { notify } from 'react-notify-toast';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Container, Button, Modal, TablePriceList, Backdrop } from '@/components/atoms';
import PATH from '@/routes/path';
import { ACTION_ACTIVITY, ACTION_PAYMENT, ACTION_TRANSACTION } from '@/redux/actions';
import { CONSTANTS, HELPERS } from '@/utils';
import { CLIENT } from '@/config';
import TransactionPipe from './components/TransactionPipe';
import ModalUploadReceipt from './components/ModalUploadReceipt';
import './TransactionDetail.css';

const TransactionDetail = () => {
  const { invoiceNumber } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [totalAmount, setTotalAmount] = useState(0);
  const [priceListData, setPriceListData] = useState([]);
  const [isOpenUploadModal, setIsOpenUploadModal] = useState(false);
  const [isOpenCancellationModal, setIsOpenCancellationModal] = useState(false);
  const [isPreviewBuktiTransferImage, setIsPreviewBuktiTransferImage] = useState(false);

  // const bankAccountListPayload = useSelector((state) => state.payment.bankAccountList.payload);
  // const bankAccountListLoading = useSelector((state) => state.payment.bankAccountList.isLoading);
  const cancelPaymentLoading = useSelector((state) => state.payment.cancel.isLoading);
  const { payload, isLoading } = useSelector((state) => state.transaction.detail);

  // const handleCopyAccount = (accountNumber) => {
  //   const value = accountNumber.split('-').join('');
  //   HELPERS.copyText(value, 'Bank account tersalin');
  // };

  const handleSnapMidtrans = () => {
    window.snap.pay(payload.snap_token, {
      // Optional
      onSuccess(result) {
        window.location.reload();
        console.info(result, 'Result Snap Midtrans');
      },
      // Optional
      onPending(result) {
        console.info(result, 'Result Snap Midtrans');
      },
      // Optional
      onError(result) {
        console.info(result, 'Result Snap Midtrans');
      },
    });
  };
  const handleCancelPayment = () => {
    dispatch(
      ACTION_PAYMENT.cancelPayment(invoiceNumber, (res) => {
        notify.show(res.payload, 'success');
        setIsOpenCancellationModal(false);
        dispatch(ACTION_TRANSACTION.getTransactionDetail(invoiceNumber));
      })
    );
  };

  useEffect(() => {
    dispatch(ACTION_PAYMENT.getBankAccounts());
    dispatch(ACTION_TRANSACTION.getTransactionDetail(invoiceNumber));
    dispatch(ACTION_ACTIVITY.showBottomNav(false));
    return () => dispatch(ACTION_ACTIVITY.showBottomNav(true));
  }, []);

  useEffect(() => {
    if (payload) {
      const deductions = payload.deductions.map((deduction) => ({
        title: deduction.label,
        value: -deduction.amount,
      }));

      const priceList = [{ title: payload.purchasable_title, value: payload.payment_amount }, ...deductions];

      if (priceList.length > 0) setTotalAmount(priceList.reduce((acc, item) => acc + item.value, 0));

      setPriceListData(priceList);
    }
  }, [payload]);

  return (
    <div className="bg-gray-100 py-10">
      <Helmet>
        <script
          src={
            process.env.REACT_APP_MIDTRANS_ENVIRONMENT === 'production'
              ? 'https://app.midtrans.com/snap/snap.js'
              : 'https://app.sandbox.midtrans.com/snap/snap.js'
          }
          data-client-key={process.env.REACT_APP_MIDTRANS_CLIENTKEY}
        />
      </Helmet>
      <Container className="flex items-start md:space-x-5">
        <div className="bg-white w-1/3 rounded-md text-sm hidden md:block">
          <div className="p-5 border-b space-y-3">
            <div>
              <div className="font-semibold mb-1">Invoice Number</div>
              {isLoading && <Skeleton className="w-full h-5" />}
              {!isLoading && payload && <div className="text-gray-500">{invoiceNumber}</div>}
            </div>
            <div>
              <div className="font-semibold mb-1">Tanggal Pembelian</div>
              {isLoading && <Skeleton className="w-full h-5" />}
              {!isLoading && payload && (
                <div className="text-gray-500">{moment(payload.created_at).format('DD MMMM YYYY')}</div>
              )}
            </div>
          </div>
          <div className="p-5">
            {isLoading && <Skeleton className="w-full h-5" />}
            {!isLoading && payload && (
              <div>
                <div className="font-semibold mb-1">
                  {CONSTANTS.PRODUCT_TYPE_BY_ID[payload.purchasable_type].title}
                </div>
                <div className="text-gray-500">{payload.purchasable_title}</div>
              </div>
            )}
          </div>
        </div>

        {isLoading && (
          <div className="w-full bg-white px-8 py-20 rounded-md flex flex-col items-center justify-center space-y-3">
            <BiLoaderAlt className="animate-spin text-primary" size={24} />
            <div>
              Loading <span className="text-primary">{invoiceNumber}</span> data...
            </div>
          </div>
        )}

        {!isLoading && payload && (
          <div className="w-full space-y-3">
            {(payload.status === CONSTANTS.PAYMENT_STATUS_TYPE.MENUNGGU_KONFIRMASI.value ||
              payload.status === CONSTANTS.PAYMENT_STATUS_TYPE.MENUNGGU_PEMBAYARAN.value ||
              payload.status === CONSTANTS.PAYMENT_STATUS_TYPE.VERIFIED.value) && (
              <div className="w-full bg-white p-8 rounded-md">
                <TransactionPipe currentStatus={payload.status} />
              </div>
            )}

            {payload.status === CONSTANTS.PAYMENT_STATUS_TYPE.MENUNGGU_PEMBAYARAN.value && (
              <>
                <div className="w-full bg-white p-8 rounded-md">
                  <div className="text-center mb-6 text-gray-500 text-sm">Segera Lakukan Pembayaran</div>
                  <div className="relative text-center mb-6">
                    <span className="font-semibold text-xl">
                      <span>Rp {HELPERS.numberFormat(totalAmount).slice(0, -3)}</span>
                      <span className="text-red-500">{HELPERS.numberFormat(totalAmount).slice(-3)}</span>
                    </span>
                    <Button
                      className="absolute right-0 top-0"
                      style={{ marginTop: -7 }}
                      size={Button.SIZES.SMALL}
                      variant={Button.VARIANTS.PRIMARY_OUTLINE}
                      onClick={() => HELPERS.copyText(HELPERS.numberFormat(totalAmount))}
                    >
                      Salin
                    </Button>
                  </div>
                  <div className="flex items-center bg-gray-100 rounded-md px-5 py-3 mb-10">
                    <img className="w-6 mr-3" src={require('@/images/icons/info.png')} alt="" />
                    <div className="text-sm">
                      <span className="text-red-500 font-semibold">Penting!</span> Mohon transfer tepat sampai 3
                      angka terakhir
                    </div>
                  </div>
                  <div className="space-y-6 text-center">
                    <div className="text-sm text-gray-500">Sebelum Tanggal</div>
                    <div className="text-xl font-semibold">
                      {moment(payload.created_at).add(1, 'day').format('DD MMMM YYYY HH:mm:ss')}
                    </div>
                  </div>
                </div>
                {/* List Rank Rekening */}
                {/* <div className="w-full bg-white p-8 rounded-md">
                  <div className="text-center text-sm mb-6">Transfer ke salah satu rekening kami</div>
                  {bankAccountListLoading && (
                    <div className="space-y-2">
                      <Skeleton width={100} height={30} />
                      <div>
                        <Skeleton width={150} height={20} />
                        <Skeleton width={150} height={20} />
                      </div>
                    </div>
                  )}
                  {!bankAccountListLoading && bankAccountListPayload && (
                    <div className="space-y-3">
                      {bankAccountListPayload.map((bankAccount) => (
                        <div key={bankAccount.account_number} className="flex items-center justify-between">
                          <div className="space-y-2">
                            <img
                              className="w-20"
                              src={bankAccount.logo_url || 'https://ptsse.co.id/assets/gambar_galeri/default.png'}
                              alt=""
                            />
                            <div>
                              <div className="font-semibold text-sm">{bankAccount.account_number}</div>
                              <div className="text-sm">(a/n {bankAccount.account_owner})</div>
                            </div>
                          </div>
                          <Button
                            size={Button.SIZES.SMALL}
                            variant={Button.VARIANTS.PRIMARY_OUTLINE}
                            onClick={() => handleCopyAccount(bankAccount.account_number)}
                          >
                            Salin
                          </Button>
                        </div>
                      ))}
                    </div>
                  )}
                </div> */}
              </>
            )}

            {payload.status === CONSTANTS.PAYMENT_STATUS_TYPE.VERIFIED.value && (
              <div className="w-full bg-white rounded-md flex flex-col items-center p-8">
                <img
                  className="mb-6 w-32"
                  src={require('@/images/icons/popup_berhasil.svg').default}
                  alt="Menunggu konfirmasi sistem"
                />
                <div className="mb-3 font-semibold">Pembayaran Berhasil</div>
                <span className="text-sm text-gray-500">Paket berlangganan Anda telah diaktifkan.</span>
              </div>
            )}

            {payload.status === CONSTANTS.PAYMENT_STATUS_TYPE.MENUNGGU_KONFIRMASI.value && (
              <div className="w-full bg-white rounded-md flex flex-col items-center p-8">
                <img
                  className="mb-6"
                  src={require('@/images/icons/asset_time.svg').default}
                  alt="Menunggu konfirmasi sistem"
                />
                <div className="mb-3 font-semibold">Pembayaran Sedang Diproses</div>
                <span className="text-sm text-gray-500">
                  Mohon tunggu, kami akan melakukan pengecekan paling lambat 2x24 jam.
                </span>
              </div>
            )}

            {(payload.status === CONSTANTS.PAYMENT_STATUS_TYPE.EXPIRED.value ||
              payload.status === CONSTANTS.PAYMENT_STATUS_TYPE.REJECTED.value ||
              payload.status === CONSTANTS.PAYMENT_STATUS_TYPE.CANCELED.value) && (
              <div className="w-full bg-white rounded-md flex flex-col items-center px-24 py-8">
                <img
                  className="mb-6 w-32"
                  src={require('@/images/icons/popup_error.svg').default}
                  alt="Menunggu konfirmasi sistem"
                />
                <div className="mb-3 font-semibold">
                  {payload.status === CONSTANTS.PAYMENT_STATUS_TYPE.CANCELED.value &&
                    'Pembayaran telah dibatalkan'}
                  {payload.status === CONSTANTS.PAYMENT_STATUS_TYPE.EXPIRED.value &&
                    'Mohon Maaf, Waktu Pembayaran Anda Sudah Habis'}
                  {payload.status === CONSTANTS.PAYMENT_STATUS_TYPE.REJECTED.value &&
                    'Mohon maaf, kami tidak dapat memverifikasi pembayaran Anda'}
                </div>
                {payload.status !== CONSTANTS.PAYMENT_STATUS_TYPE.CANCELED.value && (
                  <span className="text-sm text-gray-500 text-center">
                    Jika Anda merasa sudah melakukan transfer, harap mengirim email ke{' '}
                    <a className="text-primary font-semibold" href={`mailto:${CLIENT.EMAIL}`}>
                      {CLIENT.EMAIL}
                    </a>{' '}
                    dengan menyertakan bukti transfer Anda
                  </span>
                )}
              </div>
            )}

            <div className="w-full bg-white p-8 rounded-md">
              <div className="font-semibold text-lg mb-3">Detail Pemesanan</div>

              <table id="transactionDetailTable" className="w-full text-sm mb-3">
                <tbody>
                  <tr>
                    <td>Pemesan</td>
                    <td>{payload.purchasable_instructor}</td>
                  </tr>
                  <tr>
                    <td>Nomor Tagihan</td>
                    <td>{payload.invoice_number}</td>
                  </tr>
                  <tr>
                    <td>Metode Pembayaran</td>
                    <td>{CONSTANTS.PAYMENT_METHOD_BY_VALUE[payload.payment_method]}</td>
                  </tr>
                  {payload.bank_account && (
                    <tr>
                      <td>Transfer ke Bank</td>
                      <td>{payload.bank_account}</td>
                    </tr>
                  )}
                  <tr>
                    <td>Tanggal Pembelian</td>
                    <td>{moment(payload.created_at).format('DD MMMM YYYY')}</td>
                  </tr>
                  {payload.payment_amount > 0 &&
                    (payload.status === CONSTANTS.PAYMENT_STATUS_TYPE.MENUNGGU_KONFIRMASI.value ||
                      payload.status === CONSTANTS.PAYMENT_STATUS_TYPE.VERIFIED.value) && (
                      <tr>
                        <td>Bukti Transfer</td>
                        <td className="space-x-3">
                          <div
                            className={`${
                              isPreviewBuktiTransferImage
                                ? 'fixed top-0 left-0 w-full h-full px-4 py-20 z-50 overflow-y-scroll'
                                : 'relative w-10 h-10'
                            }`}
                          >
                            <Backdrop
                              active={isPreviewBuktiTransferImage}
                              onClick={() => setIsPreviewBuktiTransferImage(false)}
                            />
                            <img
                              className={`relative w-full ${
                                isPreviewBuktiTransferImage ? '' : 'h-full'
                              } object-cover`}
                              src={payload.receipt}
                              alt=""
                            />
                          </div>
                          <Button
                            variant={Button.VARIANTS.PRIMARY_OUTLINE}
                            size={Button.SIZES.SQUARE}
                            onClick={() => setIsPreviewBuktiTransferImage(!isPreviewBuktiTransferImage)}
                          >
                            <BiZoomIn />
                          </Button>
                        </td>
                      </tr>
                    )}
                </tbody>
              </table>

              <TablePriceList items={priceListData} />

              <div className="mt-6 space-y-3">
                {payload.status === CONSTANTS.PAYMENT_STATUS_TYPE.CANCELED.value && (
                  <Button className="w-full" variant={Button.VARIANTS.SECONDARY} linkTo={`${PATH.CATALOG}`}>
                    {t('explore_module')}
                  </Button>
                )}

                {payload.status === CONSTANTS.PAYMENT_STATUS_TYPE.VERIFIED.value &&
                  payload.purchasable_type === CONSTANTS.PRODUCT_TYPE.COURSE.id && (
                    <Button
                      className="w-full"
                      variant={Button.VARIANTS.SECONDARY}
                      linkTo={`${PATH.COURSE}/${payload.purchasable_slug}`}
                    >
                      Mulai Belajar!
                    </Button>
                  )}

                {payload.status === CONSTANTS.PAYMENT_STATUS_TYPE.VERIFIED.value &&
                  payload.purchasable_type === CONSTANTS.PRODUCT_TYPE.EVENT.id && (
                    <Button
                      className="w-full"
                      variant={Button.VARIANTS.SECONDARY}
                      linkTo={`${PATH.PROFILE}#my-ticket`}
                    >
                      Lihat Tiket Saya
                    </Button>
                  )}

                {payload.status === CONSTANTS.PAYMENT_STATUS_TYPE.MENUNGGU_PEMBAYARAN.value && (
                  <Button
                    className="w-full"
                    variant={Button.VARIANTS.PRIMARY_OUTLINE}
                    onClick={() => setIsOpenCancellationModal(true)}
                  >
                    Batalkan Transaksi
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}

        {!isLoading && payload && payload.status === CONSTANTS.PAYMENT_STATUS_TYPE.MENUNGGU_PEMBAYARAN.value && (
          <div
            className="fixed bottom-0 left-1/2 transform -translate-x-1/2 max-w-screen-md w-full bg-white p-5"
            style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.15)' }}
          >
            <div className="text-sm text-center mb-3">
              Selesaikan pembayaran sebelum{' '}
              <span className="text-red-500">
                {moment(payload.created_at).add(1, 'day').format('DD MMMM YYYY HH:mm:ss')}
              </span>
            </div>
            <Button className="w-full" onClick={() => handleSnapMidtrans(true)}>
              Selesaikan Pembayaran
            </Button>
          </div>
        )}
      </Container>

      <Modal title="Konfirmasi" open={isOpenCancellationModal} onClose={() => setIsOpenCancellationModal(false)}>
        <div className="text-center mb-6">Apakah Anda yakin ingin membatalkan transaksi?</div>
        <div className="flex items-center justify-center space-x-3">
          <Button
            variant={Button.VARIANTS.PRIMARY}
            onClick={() => !cancelPaymentLoading && setIsOpenCancellationModal(false)}
          >
            Tidak
          </Button>
          <Button
            onClick={handleCancelPayment}
            isLoading={cancelPaymentLoading}
            variant={Button.VARIANTS.SECONDARY_OUTLINE}
          >
            Ya, Batalkan
          </Button>
        </div>
      </Modal>

      <ModalUploadReceipt open={isOpenUploadModal} onClose={() => setIsOpenUploadModal(false)} />
    </div>
  );
};

export default TransactionDetail;
