import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';
import PATH from '@/routes/path';
import Logo from '@/images/logo/ipb-training-logo.png';
import HomeBanner from '@/images/home-banner.png';
import AboutImage from '@/images/mascot.jpg';
import AdsImage from '@/images/ipb-training/ads.png';
import { CONSTANTS } from '@/utils';

const NAME = 'IPB Training Online Platform';
const NAME_SECONDARY = 'IPB Training';
const DESCRIPTION = 'Excellence hub for professional and vocational skill based on applied life science.';
const ADDRESS = 'Jalan Taman Kencana Nomor 3, Bogor-Jawa Barat';
const EMAIL = 'ipbtraining@apps.ipb.ac.id';
const PHONE = '+62 251 838 2223/ 837 2400';
const LOGO = Logo;
const BANNER = HomeBanner;
const CAMPAIGN_VIDEO =
  'https://www.youtube.com/embed/Wz3jF2eJtMU?autoplay=0&mute=0&controls=1&origin=https%3A%2F%2Fonline.ipbtraining.com&playsinline=1&showinfo=0&rel=0&iv_load_policy=3&modestbranding=1&enablejsapi=1&widgetid=1';
const ADS_BANNER = {
  image: AdsImage,
  url: PATH.AUTH_LOGIN,
};
const MAIN_SITE = {
  title: 'ipbtraining.com',
  url: 'https://ipbtraining.com/',
};

const BUSINESS_TYPE = CONSTANTS.BUSINESS_TYPE.TYPE_RETAIL;

const SOCIAL_MEDIA = {
  FACEBOOK: {
    title: 'Facebook',
    icon: <FaFacebook />,
    url: 'https://facebook.com/',
  },
  TWITTER: {
    title: 'Twitter',
    icon: <FaTwitter />,
    url: 'https://twitter.com/',
  },
  INSTAGRAM: {
    title: 'Instagram',
    icon: <FaInstagram />,
    url: 'https://instagram.com/',
  },
};

const ABOUT_US = {
  title: {
    value: 'Selamat Datang',
    transactionKey: 'welcome',
  },
  description: (
    <div>
      <p className="inline bg-zinc-100 leading-8">
        IPB Training - PT Global Scholarship Services Indonesia - merupakan perusahaan milik IPB University yang
        juga bagian dari PT Bogor Life Science and Technology (grup bisnis IPB University).
      </p>
      <br />
      <br />
      <p className="inline bg-zinc-100 leading-8">
        IPB Training menawarkan solusi bagi pelanggan melalui penyelenggaraan kegiatan bisnis berbasis kepakaran
        IPB University, khususnya dalam bidang pembelajaran dan pengembangan keterampilan sumberdaya manusia
      </p>
    </div>
  ),
  image: AboutImage,
};

const FEATURES = [
  {
    title: 'Fitur Satu',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum neque quod dolore omnis, quas animi ipsa quia delectus?',
    image: 'https://image.flaticon.com/icons/png/512/1998/1998575.png',
  },
  {
    title: 'Fitur Dua',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum neque quod dolore omnis, quas animi ipsa quia delectus?',
    image: 'https://image.flaticon.com/icons/png/512/1998/1998575.png',
  },
  {
    title: 'Fitur Tiga',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum neque quod dolore omnis, quas animi ipsa quia delectus?',
    image: 'https://image.flaticon.com/icons/png/512/1998/1998575.png',
  },
];

const TESTIMONIALS = {
  sliders: [
    {
      name: 'Ahmed Rayhan Primadedas',
      role: 'Frontend Developer',
      institution: 'Founderplus.id',
      avatar: 'https://miro.medium.com/max/3360/1*gBQxShAkxBp_YPb14CN0Nw.jpeg',
      testimonial:
        'Ut dicta et. Dignissimos in ad dolorem. Nobis architecto accusantium commodi aut quaerat provident iusto. Praesentium autem recusandae adipisci dolores ea deserunt eos. Asperiores voluptatem suscipit at est.',
    },
    {
      name: 'Budi Prambudi',
      role: 'Backend Developer',
      institution: 'Institution.id',
      avatar:
        'https://images.unsplash.com/photo-1552058544-f2b08422138a?ixid=MnwxMjA3fDB8MHxzZWFyY2h8N3x8cGVyc29ufGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80',
      testimonial:
        'Voluptatum necessitatibus recusandae quia culpa quo quia aspernatur eum asperiores. Aut est sed ullam facere voluptatum est cumque qui ut. Sunt quos qui qui qui id quo sunt. Dolores rerum voluptatum et. Culpa non aut eos distinctio et et.',
    },
    {
      name: 'John Doe',
      role: 'DevOps Engineer',
      institution: 'Institution.id',
      avatar: 'https://qodebrisbane.com/wp-content/uploads/2019/07/This-is-not-a-person-2-1.jpeg',
      testimonial:
        'Voluptatum necessitatibus recusandae quia culpa quo quia aspernatur eum asperiores. Aut est sed ullam facere voluptatum est cumque qui ut. Sunt quos qui qui qui id quo sunt. Dolores rerum voluptatum et. Culpa non aut eos distinctio et et.',
    },
  ],
  grid: [
    {
      author: 'Ahmed Rayhan P',
      role: 'Frontend Developer',
      institution: 'Founderplus.id',
      testimonial:
        'Nihil est fugiat dolorem aut tempora reiciendis ipsum. Cum ratione iusto modi magni ut. Perspiciatis est facere labore ut magnam non rerum rem. Deleniti vel nihil id ut. Et et qui quas possimus illum aut eos. Enim tempore et deserunt.',
      backgroundSrc:
        'https://image.freepik.com/free-vector/beautiful-mountain-blue-sky-with-sea-view-background_105940-540.jpg',
    },
    {
      author: 'John Doe',
      role: 'Backend Developer',
      institution: 'Institution.id',
      testimonial:
        'Porro error alias aut consequatur ut. Occaecati provident nihil voluptatem quia quibusdam et ut. Perspiciatis cumque sed cumque. Dolor provident corrupti.',
      backgroundSrc:
        'https://images.unsplash.com/photo-1453728013993-6d66e9c9123a?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dmlld3xlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&w=1000&q=80',
    },
  ],
};

export const CLIENT = {
  NAME,
  NAME_SECONDARY,
  DESCRIPTION,
  ADDRESS,
  BANNER,
  BUSINESS_TYPE,
  ADS_BANNER,
  EMAIL,
  PHONE,
  LOGO,
  SOCIAL_MEDIA,
  CAMPAIGN_VIDEO,
  MAIN_SITE,
  ABOUT_US,
  FEATURES,
  TESTIMONIALS,
};
