import moment from 'moment';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ConditionalWrapper from '../ConditionalWrapper/ConditionalWrapper';
import ProgressBar from '../ProgressBar/ProgressBar';

const ProductCard = ({ title, progress, image, bookingCode, heldAt, category, instructor, linkTo, className }) => {
  const handleImageError = (e) => {
    e.target.src = require('@/images/default-image.png');
  };

  return (
    <div
      className={`h-full bg-white rounded overflow-hidden transition-all shadow-md hover:shadow-lg ${className}`}
      title={title}
    >
      <ConditionalWrapper condition={!!linkTo} wrapper={(childs) => <Link to={linkTo}>{childs}</Link>}>
        <div className="h-48">
          <img
            className="object-cover object-center w-full h-full"
            onError={handleImageError}
            src={image}
            alt={title}
          />
        </div>
        <div className="p-3 h-32 flex flex-col justify-between">
          <h3 className="line-clamp-2 mb-3">{title}</h3>
          <div>
            {bookingCode && <p className="text-gray-500 text-xs">{bookingCode}</p>}
            {category && <p className="text-gray-500 text-xs">{category}</p>}
            {instructor && <p className="text-xs">{instructor}</p>}
            {heldAt && <p className="text-xs">{moment(heldAt).format('HH MMMM YYYY')}</p>}
            {progress !== undefined && !Number.isNaN(progress) && (
              <ProgressBar progress={progress} size={ProgressBar.SIZE.SMALL} />
            )}
          </div>
        </div>
      </ConditionalWrapper>
    </div>
  );
};

ProductCard.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  category: PropTypes.string,
  instructor: PropTypes.string,
  linkTo: PropTypes.string,
  className: PropTypes.string,
};

ProductCard.defaultProps = {
  title: '',
  image: null,
  category: '',
  instructor: '',
  linkTo: null,
  className: '',
};

export default ProductCard;
