import { ConditionalWrapper } from '@/components/atoms';

export const AdsBanner = ({ image, url, className }) => {
  return (
    <div className={className}>
      <ConditionalWrapper
        condition={!!url}
        wrapper={(children) => (
          <a href={url} target="_blank" rel="noreferrer">
            {children}
          </a>
        )}
      >
        <img className="h-60 lg:h-full object-cover object-left" src={image} alt="" />
      </ConditionalWrapper>
    </div>
  );
};
