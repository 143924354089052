import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { BiLoaderAlt } from 'react-icons/bi';

const Button = ({
  variant,
  className,
  linkTo,
  size,
  fontSize,
  children,
  onClick,
  submit,
  disabled,
  isLoading,
  ...props
}) => {
  const history = useHistory();
  const mainClass = 'transition-all rounded flex justify-center items-center';
  const sizeClass = classNames({
    'py-2 px-4': size === Button.SIZES.SMALL2X,
    'py-2 px-5': size === Button.SIZES.SMALL,
    'py-3 px-7': size === Button.SIZES.MEDIUM,
    'py-3 px-3': size === Button.SIZES.SQUARE,
    'py-4 px-8': size === Button.SIZES.LARGE,
    'py-4 px-9': size === Button.SIZES.LARGE2X,
  });

  const fontSizeClass = classNames({
    'text-xs': fontSize === Button.FONT_SIZES.SMALL2X,
    'text-sm': fontSize === Button.FONT_SIZES.SMALL,
    'text-base': fontSize === Button.FONT_SIZES.MEDIUM,
    'text-lg': fontSize === Button.FONT_SIZES.LARGE,
    'text-xl': fontSize === Button.FONT_SIZES.LARGE2X,
  });

  const variantClass = classNames({
    'bg-primary hover:bg-primary-400 text-white': variant === Button.VARIANTS.PRIMARY,
    'bg-primary-400 text-white': variant === Button.VARIANTS.PRIMARY && (disabled || isLoading),
    'border-primary ring-1 ring-primary ring-inset hover:bg-primary text-primary hover:text-white':
      variant === Button.VARIANTS.PRIMARY_OUTLINE,
    'bg-secondary hover:bg-secondary-400 text-white': variant === Button.VARIANTS.SECONDARY,
    'border-secondary ring-1 ring-secondary ring-inset hover:bg-secondary text-secondary hover:text-white':
      variant === Button.VARIANTS.SECONDARY_OUTLINE,
    'bg-tertiary hover:bg-tertiary-400 text-white': variant === Button.VARIANTS.TERTIARY,
    'border-tertiary ring-1 ring-tertiary ring-inset hover:bg-tertiary text-tertiary hover:text-white':
      variant === Button.VARIANTS.TERTIARY_OUTLINE,
    'bg-gray-100 hover:bg-gray-200 text-white': variant === Button.VARIANTS.GRAY,
    'border-gray-400 ring-1 ring-gray-400 ring-inset hover:bg-gray-400 text-gray-400 hover:text-white':
      variant === Button.VARIANTS.GRAY_OUTLINE,
    'bg-white hover:bg-gray-100 text-primary': variant === Button.VARIANTS.WHITE,
    'border-white ring-1 ring-white ring-inset hover:bg-white text-white hover:text-primary':
      variant === Button.VARIANTS.WHITE_OUTLINE,
    'bg-red-500 hover:bg-red-400 text-white': variant === Button.VARIANTS.DANGER,
    'border-red ring-1 ring-red ring-inset hover:bg-red text-red hover:text-white':
      variant === Button.VARIANTS.DANGER_OUTLINE,
  });

  return (
    <button
      className={`${mainClass} ${variantClass} ${fontSizeClass} ${sizeClass} ${className} flex`}
      onClick={() => (linkTo ? history.push(linkTo) : onClick())}
      type={submit ? 'submit' : 'button'}
      disabled={isLoading || disabled}
      {...props}
    >
      {isLoading && <BiLoaderAlt className="animate-spin mr-3" />}
      {children}
    </button>
  );
};

Button.SIZES = {
  SMALL2X: 'SMALL2X',
  SMALL: 'SMALL',
  SQUARE: 'SQUARE',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE',
  LARGE2X: 'LARGE2X',
};

Button.VARIANTS = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
  TERTIARY: 'TERTIARY',
  PRIMARY_OUTLINE: 'PRIMARY_OUTLINE',
  SECONDARY_OUTLINE: 'SECONDARY_OUTLINE',
  TERTIARY_OUTLINE: 'TERTIARY_OUTLINE',
  GRAY: 'GRAY',
  WHITE: 'WHITE',
  WHITE_OUTLINE: 'WHITE_OUTLINE',
  DANGER: 'DANGER',
  DANGER_OUTLINE: 'DANGER_OUTLINE',
};

Button.FONT_SIZES = { ...Button.SIZES };
delete Button.FONT_SIZES.SQUARE;

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  size: PropTypes.oneOf(Object.values(Button.SIZES)),
  fontSize: PropTypes.oneOf(Object.values(Button.FONT_SIZES)),
  variant: PropTypes.oneOf(Object.values(Button.VARIANTS)),
  submit: PropTypes.bool,
  onClick: PropTypes.func,
  linkTo: PropTypes.string,
};

Button.defaultProps = {
  className: '',
  children: '',
  size: Button.SIZES.MEDIUM,
  fontSize: Button.FONT_SIZES.SMALL,
  variant: Button.VARIANTS.PRIMARY,
  onClick: () => {},
  submit: false,
  linkTo: null,
};

export default Button;
