import classNames from 'classnames';
import { MdErrorOutline, MdWarning, MdCheck } from 'react-icons/md';

const InputFeedback = ({ type, message }) => {
  const feedbackClasses = classNames({
    'text-red-500': type === InputFeedback.TYPES.ERROR,
    'text-green-500': type === InputFeedback.TYPES.SUCCESS,
    'text-yellow-500': type === InputFeedback.TYPES.WARNING,
  });

  return (
    <div className={`${feedbackClasses} text-xs mt-2 flex items-center space-x-1`}>
      {type === InputFeedback.TYPES.SUCCESS && <MdCheck size={16} />}
      {type === InputFeedback.TYPES.ERROR && <MdErrorOutline size={16} />}
      {type === InputFeedback.TYPES.WARNING && <MdWarning size={16} />}
      <span>{message}</span>
    </div>
  );
};

InputFeedback.TYPES = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  WARNING: 'WARNING',
};

export default InputFeedback;
