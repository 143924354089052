import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { BackgroundImage, Container, ConditionalWrapper } from '@/components/atoms';
import { useTranslation } from 'react-i18next';

const HomeSection = ({
  className,
  containerMode,
  title,
  subtitle,
  children,
  bgColor,
  bgImage,
  linkTo,
  linkText,
}) => {
  const { t } = useTranslation();
  const bgColorClass = classNames({ [`bg-${bgColor}`]: bgColor });

  return (
    <div className={`relative py-20 ${bgColorClass} ${className}`}>
      {bgImage && <BackgroundImage src={bgImage} />}
      <ConditionalWrapper
        condition={!!containerMode}
        wrapper={(childs) => <Container mode={containerMode}>{childs}</Container>}
      >
        {(title || subtitle) && (
          <div className={`flex items-center ${linkTo ? 'justify-between' : 'justify-center'} mb-14`}>
            <div className={`flex flex-col w-full ${linkTo ? 'items-stretch' : 'items-center'}`}>
              <h1
                className={`leading-snug text-primary capitalize font-medium ${
                  linkTo ? 'text-2xl text-left' : 'text-3xl text-center md:w-1/2'
                }`}
              >
                {title}
              </h1>
              {subtitle && <span className={`text-gray-700 ${title && 'mt-5'}`}>{subtitle}</span>}
            </div>

            {linkTo && (
              <span className="text-secondary w-1/2 text-right">
                <Link to={linkTo}>{linkText || t('see_all')}</Link>
              </span>
            )}
          </div>
        )}
        {children}
      </ConditionalWrapper>
    </div>
  );
};

HomeSection.propTypes = {
  bgImage: BackgroundImage.propTypes.src,
  containerMode: Container.propTypes.mode,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  bgColor: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  linkTo: PropTypes.string,
  linkText: PropTypes.string,
  className: PropTypes.string,
};

HomeSection.defaultProps = {
  bgImage: BackgroundImage.defaultProps.src,
  containerMode: Container.MODE.MEDIUM,
  bgColor: null,
  title: null,
  subtitle: null,
  linkTo: null,
  linkText: null,
  className: '',
};

export default HomeSection;
