import { SERVICE_PROFILE } from '@/services';
import { HELPERS } from '@/utils';
import { notify } from 'react-notify-toast';
import { TYPES_PROFILE } from './profile.types';

const updateProfile = (userUUID, formData) => {
  return (dispatch) => {
    dispatch({ type: TYPES_PROFILE.PROFILE_UPDATE_LOADING, isLoading: true });

    SERVICE_PROFILE.updateProfile(userUUID, formData, (res) => {
      dispatch({ type: TYPES_PROFILE.PROFILE_UPDATE_LOADING, isLoading: false });

      if (res.error) HELPERS.showNotificationServiceError(res.error);
      else {
        dispatch({
          type: TYPES_PROFILE.PROFILE_UPDATE,
          profile: { name: res.payload.name, phone: res.payload.phone_number },
        });
        notify.show('Profil berhasil disimpan', 'success');
      }
    });
  };
};

const getProfileEventTicket = () => {
  return (dispatch) => {
    dispatch({ type: TYPES_PROFILE.GET_EVENT_TICKETS_LOADING, isLoading: true });

    SERVICE_PROFILE.getProfileEventTicket((res) => {
      if (res.error) HELPERS.showNotificationServiceError(res.error);
      else {
        dispatch({
          type: TYPES_PROFILE.GET_EVENT_TICKETS_DATA,
          payload: res.payload,
        });
      }
    });
  };
};

const getProfileModule = () => {
  return (dispatch) => {
    dispatch({ type: TYPES_PROFILE.GET_MODULE_LOADING, isLoading: true });

    SERVICE_PROFILE.getProfileModule((res) => {
      if (res.error) HELPERS.showNotificationServiceError(res.error);
      else {
        dispatch({
          type: TYPES_PROFILE.GET_MODULE_DATA,
          payload: res.payload,
        });
      }
    });
  };
};

const getReportMonthly = (startDate, endDate) => {
  return (dispatch) => {
    dispatch({ type: TYPES_PROFILE.GET_REPORT_MONTHLY_LOADING, isLoading: true });

    SERVICE_PROFILE.getReportMonthly(startDate, endDate, (res) => {
      if (res.error) HELPERS.showNotificationServiceError(res.error);
      else {
        dispatch({
          type: TYPES_PROFILE.GET_REPORT_MONTHLY_DATA,
          payload: res.payload,
        });
      }
    });
  };
};

const getReportSummary = () => {
  return (dispatch) => {
    dispatch({ type: TYPES_PROFILE.GET_REPORT_SUMMARY_LOADING, isLoading: true });

    SERVICE_PROFILE.getReportSummary((res) => {
      if (res.error) HELPERS.showNotificationServiceError(res.error);
      else {
        dispatch({
          type: TYPES_PROFILE.GET_REPORT_SUMMARY_DATA,
          payload: res.payload,
        });
      }
    });
  };
};

export const ACTION_PROFILE = {
  updateProfile,
  getProfileEventTicket,
  getProfileModule,
  getReportMonthly,
  getReportSummary,
};
