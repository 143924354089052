const LOGIN = 'LOGIN';
const LOGOUT = 'LOGOUT';
const FORGOT_PASSWORD_LOADING = 'FORGOT_PASSWORD_LOADING';
const RESET_PASSWORD_LOADING = 'RESET_PASSWORD_LOADING';
const CHANGE_PASSWORD_LOADING = 'CHANGE_PASSWORD_LOADING';

export const TYPES_AUTH = {
  LOGIN,
  LOGOUT,
  FORGOT_PASSWORD_LOADING,
  RESET_PASSWORD_LOADING,
  CHANGE_PASSWORD_LOADING,
};
