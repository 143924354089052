import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { HELPERS } from '@/utils';
import PATH from './path';
import Home from './Home/Home';
import OTP from './Auth/OTP';
import Login from './Auth/Login';
import Register from './Auth/Register';
import ForgotPassword from './Auth/ForgotPassword';
import ResetPassword from './Auth/ResetPassword';
import CreateNewPassword from './Auth/CreateNewPassword';
import CourseDetail from './Course/CourseDetail';
import EventDetail from './Event/EventDetail';
import Catalog from './Catalog/Catalog';
import Profile from './Profile/Profile';
import Ticket from './Ticket/Ticket';
import ProfileUpdate from './Profile/ProfileUpdate';
import ProfileChangePassword from './Profile/ProfileChangePassword';
import Payment from './Payment/Payment';
import TransactionList from './Transaction/TransactionList';
import TransactionDetail from './Transaction/TransactionDetail';
import TransactionCancelled from './Transaction/TransactionCancelled';
import Certificate from './Certificate/Certificate';
import FAQ from './FAQ/FAQ';

function ProtectedRoute(props) {
  const { path } = props;
  const { loggedIn } = useSelector((state) => state.auth);
  const queryParam = `redirect=${path}`;
  return loggedIn ? <Route {...props} /> : <Redirect to={`${PATH.AUTH_LOGIN}?${queryParam}`} />;
}

function AuthenticationRoute(props) {
  let redirectPath = PATH.HOME;
  const { location } = props;
  const { loggedIn } = useSelector((state) => state.auth);

  if (location.search) {
    const { redirect } = HELPERS.queryStringToObject(location.search);
    redirectPath = redirect;
  }

  return loggedIn ? <Redirect to={redirectPath} /> : <Route {...props} />;
}

function Routes() {
  return (
    <Switch>
      <Route exact path={PATH.HOME} render={() => <Home />} />
      <Route path={`${PATH.COURSE_CERITIFICATE}/:uuid`} render={() => <Certificate />} />
      <Route path={`${PATH.COURSE}/:uid`} render={() => <CourseDetail />} />
      <Route path={`${PATH.EVENT}/:uid`} render={() => <EventDetail />} />
      <Route path={PATH.CATALOG} render={() => <Catalog />} />
      <Route path={PATH.FAQ} render={() => <FAQ />} />
      <AuthenticationRoute path={PATH.AUTH_LOGIN} render={() => <Login />} />
      <AuthenticationRoute path={PATH.AUTH_REGISTER} render={() => <Register />} />
      <AuthenticationRoute path={PATH.AUTH_FORGOT_PASSWORD} render={() => <ForgotPassword />} />
      <AuthenticationRoute path={PATH.AUTH_RESET_PASSWORD} render={() => <ResetPassword />} />
      <AuthenticationRoute path={PATH.AUTH_OTP} render={() => <OTP />} />
      <AuthenticationRoute path={PATH.AUTH_CREATE_NEW_PASSWORD} render={() => <CreateNewPassword />} />
      <ProtectedRoute path={PATH.PROFILE_CHANGE_PASSWORD} render={() => <ProfileChangePassword />} />
      <ProtectedRoute path={PATH.PROFILE_UPDATE} render={() => <ProfileUpdate />} />
      <ProtectedRoute path={PATH.PROFILE} render={() => <Profile />} />
      <ProtectedRoute path={PATH.PAYMENT} render={() => <Payment />} />
      <ProtectedRoute path={PATH.TRANSACTION_CANCELLED} render={() => <TransactionCancelled />} />
      <ProtectedRoute path={`${PATH.TRANSACTION}/:invoiceNumber`} render={() => <TransactionDetail />} />
      <ProtectedRoute path={PATH.TRANSACTION} render={() => <TransactionList />} />
      <ProtectedRoute path={`${PATH.TICKET}/:uuid`} render={() => <Ticket />} />
    </Switch>
  );
}

export default Routes;
