import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactPlayer from 'react-player';
import { ACTION_COURSE } from '@/redux/actions';
import { HELPERS, CONSTANTS } from '@/utils';
import Quiz from './CourseContentResources/Quiz';
import Video from './CourseContentResources/Video';
import { PDF } from './CourseContentResources/PDF';

const CourseContentResource = ({
  slug,
  enrolled,
  isLocked,
  topicUUID,
  title,
  completed,
  thumbnail,
  type,
  url,
  isLastResource,
}) => {
  const dispatch = useDispatch();

  const isLoggedIn = useSelector((state) => state.auth.loggedIn);

  const handleGoToTheNextResource = () => {
    dispatch(ACTION_COURSE.getCourseDetailData(slug));
  };

  const handleSaveResourceProgress = (progressAction) => {
    if (isLoggedIn && enrolled) {
      dispatch(
        ACTION_COURSE.sendCourseProgress(slug, topicUUID, progressAction, () => {
          handleGoToTheNextResource();
        })
      );
    }
  };

  return (
    <div>
      {(type === CONSTANTS.LEARNING_RESOURCE_TYPE.EMPTY || isLocked) && (
        <img
          className="w-full h-80 md:h-96 lg:h-128 object-cover"
          src={thumbnail}
          onError={(event) => HELPERS.handleBrokenImage(event)}
          alt=""
        />
      )}

      {!isLocked && (
        <>
          {type === CONSTANTS.LEARNING_RESOURCE_TYPE.QUIZ && (
            <Quiz
              topicUUID={topicUUID}
              completed={completed}
              isLastResource={isLastResource}
              onClickGoToTheNextResource={handleGoToTheNextResource}
            />
          )}

          {(type === CONSTANTS.LEARNING_RESOURCE_TYPE.VIDEO ||
            type === CONSTANTS.LEARNING_RESOURCE_TYPE.YOUTUBE) && (
            <Video
              url={url}
              onStart={() => handleSaveResourceProgress(CONSTANTS.COURSE_PROGRESS_ACTIONS.STARTED)}
              onPause={() => handleSaveResourceProgress(CONSTANTS.COURSE_PROGRESS_ACTIONS.PAUSED)}
              onEnded={() => handleSaveResourceProgress(CONSTANTS.COURSE_PROGRESS_ACTIONS.FINISHED)}
            />
          )}

          {type === CONSTANTS.LEARNING_RESOURCE_TYPE.AUDIO && (
            <div className="relative flex items-end h-80 md:h-96 lg:h-128">
              <div className="relative z-20 m-6 shadow-md w-full p-3 bg-white rounded-full">
                <ReactPlayer
                  controls
                  width="100%"
                  onStart={() => handleSaveResourceProgress(CONSTANTS.COURSE_PROGRESS_ACTIONS.STARTED)}
                  onPause={() => handleSaveResourceProgress(CONSTANTS.COURSE_PROGRESS_ACTIONS.PAUSED)}
                  onEnded={() => handleSaveResourceProgress(CONSTANTS.COURSE_PROGRESS_ACTIONS.FINISHED)}
                  height={50}
                  url={url}
                  config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                />
              </div>
              <img
                className="absolute left-0 top-0 w-full h-full object-cover z-10"
                src={thumbnail}
                onError={(event) => HELPERS.handleBrokenImage(event)}
                alt=""
              />
            </div>
          )}

          {type === CONSTANTS.LEARNING_RESOURCE_TYPE.PDF && (
            <PDF
              title={title}
              url={url}
              onGoToTheNextTopic={() => handleSaveResourceProgress(CONSTANTS.COURSE_PROGRESS_ACTIONS.FINISHED)}
            />
          )}
        </>
      )}
    </div>
  );
};

export default CourseContentResource;
