import React from 'react';
import { AiFillHome, AiOutlineSolution, AiOutlineSmile } from 'react-icons/ai';
import { NavLink } from 'react-router-dom';
import { COLOR } from '@/config';
import { useTranslation } from 'react-i18next';

function BottomNav() {
  const { t } = useTranslation();

  const NAVLINKS = [
    {
      to: '/',
      exact: true,
      icon: <AiFillHome />,
      text: t('home'),
    },
    {
      to: '/transaction',
      exact: true,
      icon: <AiOutlineSolution />,
      text: t('my_transaction'),
    },
    {
      to: '/profile',
      exact: true,
      icon: <AiOutlineSmile />,
      text: t('profile'),
    },
  ];

  return (
    <div
      id="bottomNav"
      style={{ boxShadow: '0 1px 13px 2px rgba(0, 0, 0, 0.1)' }}
      className="block lg:hidden fixed bottom-0 left-0 w-full z-10 bg-white"
    >
      <div className="flex">
        {NAVLINKS.map(({ to, exact, icon, text }) => (
          <NavLink
            key={to}
            to={to}
            exact={exact}
            activeStyle={{ borderBottom: `3px solid ${COLOR.PRIMARY[500]}`, color: COLOR.PRIMARY[500] }}
            className="flex flex-1 flex-col space-y-1 items-center justify-center p-3 sm:p-4 hover:bg-gray-100 transition-all text-gray-400 text-center"
          >
            <div className="text-xl sm:text-2xl">{icon}</div>
            <div className="text-xs sm:text-sm">{text}</div>
          </NavLink>
        ))}
      </div>
    </div>
  );
}

export default BottomNav;
