import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FiSearch } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { InputText, Button, ProductCard, ContentLoader } from '@/components/atoms';
import EmptyModuleIcon from '@/images/icons/profile-course-empty.png';
import { ACTION_PROFILE } from '@/redux/actions';
import PATH from '@/routes/path';

const MyModule = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const data = useSelector((state) => state.profile.module.payload);
  const isLoading = useSelector((state) => state.profile.module.isLoading);

  const [displayedModule, setDisplayedModule] = useState([]);

  const handleChangeSearch = (event) => {
    const pattern = new RegExp(event.target.value.toUpperCase());
    const filteredModule = data.filter((course) => pattern.test(course.title.toUpperCase()));
    setDisplayedModule(filteredModule);
  };

  useEffect(() => {
    dispatch(ACTION_PROFILE.getProfileModule());
  }, []);

  useEffect(() => {
    setDisplayedModule(data);
  }, [data]);

  return (
    <div>
      {isLoading && <ContentLoader className="py-24" />}
      {!isLoading && displayedModule && (
        <div className="space-y-8">
          <div>
            <InputText
              required
              icon={<FiSearch size={20} />}
              type="search"
              placeholder={t('search_module')}
              onChange={handleChangeSearch}
              className="bg-white w-full mb-3 py-3"
            />
          </div>
          <div className="flex items-center space-x-3">
            <span className="text-sm">{t('modul_grup')} :</span>
            <Button variant={Button.VARIANTS.PRIMARY_OUTLINE} size={Button.SIZES.SMALL2X}>
              {t('all')} ({displayedModule.length})
            </Button>
          </div>
          {displayedModule.length === 0 && (
            <div className="flex flex-col items-center text-center space-y-8">
              <img src={EmptyModuleIcon} alt="Empty Module" />
              <div className="text-gray-400">{t('meja_belajar_anda_masih_kosong')}</div>
              <Button variant={Button.VARIANTS.SECONDARY}>{t('explore_module')}</Button>
            </div>
          )}

          {displayedModule.length > 0 && (
            <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 lg:gap-5">
              {displayedModule.map((course) => (
                <ProductCard
                  key={course.uuid}
                  linkTo={`${PATH.COURSE}/${course.slug}`}
                  title={course.title}
                  progress={course.progress}
                  instructor={course.instructor}
                  image={course.thumbnail}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

MyModule.Title = {
  value: 'My Module',
  key: 'my_module',
};

MyModule.Hash = '#my-module';

export default MyModule;
