import { Container } from '@/components/atoms';
import Skeleton from 'react-loading-skeleton';

const HomeModuleSkeleton = () => {
  const renderCardSkeleton = () => (
    <div className="md:flex-1 rounded-md bg-white p-3 md:last:hidden lg:last:block">
      <Skeleton className="mb-2" height={150} />
      <Skeleton className="mb-2" height={20} />
      <div className="w-10/12">
        <Skeleton count={3} height={15} />
      </div>
    </div>
  );

  const renderSectionSkeleton = () => (
    <div>
      <div className="flex justify-between mb-3">
        <Skeleton width={200} height={30} />
        <Skeleton width={120} height={30} />
      </div>
      <div className="grid grid-cols-2 gap-6 md:flex justify-between md:space-x-6">
        {renderCardSkeleton()}
        {renderCardSkeleton()}
        {renderCardSkeleton()}
        {renderCardSkeleton()}
      </div>
    </div>
  );

  return (
    <Container>
      <div className="space-y-12">
        {renderSectionSkeleton()}
        {renderSectionSkeleton()}
      </div>
    </Container>
  );
};

export default HomeModuleSkeleton;
