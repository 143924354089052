import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Container } from '@/components/atoms';
import { ACTION_TRANSACTION } from '@/redux/actions';
import { CONSTANTS } from '@/utils';
import TransactionListItem from './TransactionListItem';
import TransactionListFilter from './TransactionListFilter';

const TransactionList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [params, setParams] = useState({ status: CONSTANTS.PAYMENT_STATUS_TYPE.ALL.value, per_page: 10 });

  const isLoading = useSelector((state) => state.transaction.list.isLoading);
  const transactionList = useSelector((state) => state.transaction.list.payload);

  const handleLoadMore = () => setParams({ ...params, per_page: params.per_page + 10 });

  useEffect(() => dispatch(ACTION_TRANSACTION.getTransactionList(params)), [params]);

  return (
    <>
      <div className="bg-primary">
        <Container>
          <div className="text-white py-16 text-2xl font-semibold">{t('riwayat_transaksi_anda')}</div>
        </Container>
      </div>
      <div className="bg-gray-100 py-8">
        <Container className="flex items-stretch lg:space-x-6">
          <TransactionListFilter params={params} setParams={setParams} />

          <div className="w-full">
            {isLoading && !transactionList && <TransactionList.Skeleton />}

            {transactionList?.total === 0 && (
              <div className="rounded-md shadow-md bg-white flex flex-col items-center justify-center h-full p-8">
                <img
                  className="w-28 mb-6"
                  src={require('@/images/icons/ic-riwayat.png')}
                  alt="Riwayat Transaksi Empty"
                />
                <div className="text-sm text-gray-500">Belum ada riwayat transaksi</div>
              </div>
            )}

            {transactionList?.total > 0 && (
              <div>
                <div className="space-y-4">
                  {transactionList.data.map((transaction) => (
                    <TransactionListItem
                      title={transaction.purchasable.title}
                      date={transaction.created_at}
                      invoiceNumber={transaction.invoice_number}
                      amount={transaction.payment_amount}
                      status={transaction.status}
                      paymentMethod={transaction.payment_method}
                    />
                  ))}

                  {transactionList.data.length < transactionList.total && (
                    <div className="flex justify-center">
                      <Button onClick={handleLoadMore}>{t('load_more')}</Button>
                    </div>
                  )}
                </div>

                {isLoading && <TransactionList.Skeleton />}
              </div>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

TransactionList.Skeleton = () => {
  return (
    <div className="space-y-4">
      <TransactionListItem.Skeleton />
      <TransactionListItem.Skeleton />
      <TransactionListItem.Skeleton />
    </div>
  );
};

export default TransactionList;
