import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import moment from 'moment';

const useQuery = (params) => {
  const location = useLocation();
  return new URLSearchParams(params || location.search);
};

const useCountdown = () => {
  const [timeLeft, setTimeLeft] = useState(null);
  const [endTime, setEndTime] = useState(null);

  useEffect(() => {
    let interval;
    if (endTime) {
      interval = setInterval(() => {
        const now = moment().format('X');
        const then = moment(endTime).format('X');
        const diffTime = then - now;
        const duration = moment.duration(diffTime * 1000, 'milliseconds');

        const years = duration.years().toString();
        const months = duration.months().toString();
        const days = duration.days().toString();
        const hours = duration.hours().toString();
        const minutes = duration.minutes().toString();
        const seconds = duration.seconds().toString();
        const result = {
          years: years.length === 1 ? `0${years}` : years,
          months: months.length === 1 ? `0${months}` : months,
          days: days.length === 1 ? `0${days}` : days,
          hours: hours.length === 1 ? `0${hours}` : hours,
          minutes: minutes.length === 1 ? `0${minutes}` : minutes,
          seconds: seconds.length === 1 ? `0${seconds}` : seconds,
        };

        setTimeLeft(result);

        if (
          Number(years) <= 0 &&
          Number(months) <= 0 &&
          Number(days) <= 0 &&
          Number(hours) <= 0 &&
          Number(minutes) <= 0 &&
          Number(seconds) <= 0
        ) {
          clearInterval(interval);
          setTimeLeft(null);
        }
      }, 1000);
    }
  }, [endTime]);

  return [timeLeft, setEndTime];
};

export const HOOKS = { useQuery, useCountdown };
