import { TYPES_COURSE } from './course.types';

const INITIAL_STATE = {
  list: {
    payload: null,
    loading: true,
  },
  detail: {
    payload: null,
    loading: true,
  },
  report: {
    payload: null,
  },
  comments: {
    payload: null,
    loading: true,
    submitting: false,
  },
  commentReport: {
    submitting: false,
  },
};

const courseReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES_COURSE.SET_COURSE_LIST_LOADING:
      return { ...state, list: { loading: action.loading || true } };
    case TYPES_COURSE.GET_COURSE_LIST_DATA:
      return { ...state, list: { payload: action.payload, loading: false } };

    case TYPES_COURSE.SET_COURSE_DETAIL_LOADING:
      return { ...state, detail: { loading: action.loading || true } };
    case TYPES_COURSE.GET_COURSE_DETAIL_DATA:
      return {
        ...state,
        detail: { payload: action.payload, loading: false },
      };

    case TYPES_COURSE.SET_COURSE_REPORT_LOADING:
      return { ...state, report: { loading: action.loading || true } };
    case TYPES_COURSE.GET_COURSE_REPORT_DATA:
      return {
        ...state,
        report: { payload: action.payload, loading: false },
      };

    case TYPES_COURSE.SET_COURSE_COMMENT_LOADING:
      return { ...state, comments: { ...state.comments, loading: action.loading || true } };
    case TYPES_COURSE.SET_COURSE_COMMENT_SUBMITTING:
      return { ...state, comments: { ...state.comments, submitting: action.submitting || true } };
    case TYPES_COURSE.GET_COURSE_COMMENT_DATA:
      return {
        ...state,
        comments: { ...state.comments, payload: action.payload, loading: false, submitting: false },
      };

    case TYPES_COURSE.SET_COURSE_COMMENT_REPORT_SUBMITTING:
      return { ...state, commentReport: { ...state.commentReport, submitting: action.submitting } };

    default:
      return state;
  }
};

export default courseReducer;
