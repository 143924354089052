import { HELPERS } from '@/utils';
import { useTranslation } from 'react-i18next';
import { AiFillCheckCircle } from 'react-icons/ai';
import { Link } from 'react-router-dom';

const CatalogItem = ({ title, category, instructor, thumbnail, price, slug, enrolled, completed, type }) => {
  const { t } = useTranslation();

  return (
    <Link
      to={`${type}/${slug}`}
      className="relative bg-white shadow-sm hover:shadow-md border border-transparent hover:border-primary transition-all sm:space-x-3 sm:space-y-0 rounded-md flex flex-col sm:flex-row items-stretch overflow-hidden"
    >
      <div className="w-full sm:w-1/4 sm:border-r" style={{ minWidth: 180, minHeight: 32 }}>
        <img
          className="w-full h-40 object-cover"
          onError={(e) => HELPERS.handleBrokenImage(e)}
          src={thumbnail}
          alt={title}
        />
      </div>
      <div className="w-full flex flex-col justify-between space-y-3 p-3">
        <div className="flex items-start justify-between">
          <div>
            <h1 className="sm:text-lg font-semibold text-gray-800">{title}</h1>
          </div>
          <div className="absolute top-2 right-3 sm:relative sm:top-0 sm:right-0 flex flex-wrap gap-1 items-center justify-end">
            {completed && (
              <div className="flex items-center space-x-1 px-2 py-1 rounded-md bg-green-500 text-white text-xs">
                <AiFillCheckCircle />
                <span>Completed</span>
              </div>
            )}
            {enrolled && (
              <div className="flex items-center space-x-1 px-2 py-1 rounded-md bg-primary text-white text-xs">
                <span>Enrolled</span>
              </div>
            )}
          </div>
        </div>
        <div className="flex gap-3 flex-col sm:flex-row sm:items-end sm:justify-between">
          <div className="w-full space-y-1">
            {category && <div className="text-xs text-gray-400">{category.name}</div>}
            {instructor && (
              <div>
                <div className="text-sm text-gray-500">{instructor}</div>
              </div>
            )}
          </div>
          <div className="sm:w-36 md:w-40 md:text-lg text-primary font-semibold sm:text-right">
            {price > 0 ? `Rp ${HELPERS.numberFormat(price)}` : t('free')}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CatalogItem;
