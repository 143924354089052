import { Button } from '@/components/atoms';
import PATH from '@/routes/path';

const TransactionCancelled = () => {
  return (
    <div className="bg-gray-100 p-8" style={{ minHeight: 350 }}>
      <div className="bg-white shadow-xl space-y-5 p-8 max-w-screen-sm mx-auto">
        <div className="text-center text-xl font-semibold">Anda telah membatalkan transaksi!</div>
        <p className="text-sm text-gray-500 text-center">
          Jika Anda ingin mengakses modul ini, maka Anda harus melakukan pembelian modul atau subscription kembali.
        </p>
        <Button className="w-full" linkTo={PATH.HOME}>
          Kembali Ke Halaman Depan
        </Button>
      </div>
    </div>
  );
};

export default TransactionCancelled;
