import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ACTION_PAYMENT } from '@/redux/actions';
import { Button, InputText } from '@/components/atoms';

function PaymentVoucher({ onSuccess, onCancel }) {
  const dispatch = useDispatch();

  const { payload, error, isLoading } = useSelector((state) => state.payment.voucher);

  const [voucherCode, setVoucherCode] = useState('');

  const handleChangeVoucherCode = (e) => {
    setVoucherCode(e.target.value.toUpperCase());
    if (payload || error) dispatch(ACTION_PAYMENT.resetVoucherCode());
  };

  const handleSubmitVoucher = (event) => {
    if (voucherCode) dispatch(ACTION_PAYMENT.getVoucherCode(voucherCode));
    event.preventDefault();
  };

  const handleCancelVoucher = (event) => {
    onCancel();
    setVoucherCode('');
    dispatch(ACTION_PAYMENT.resetVoucherCode());
    if (event) event.preventDefault();
  };

  useEffect(() => {
    if (!error && payload) onSuccess(payload);
  }, [error, payload]);

  useEffect(() => {
    return () => handleCancelVoucher();
  }, []);

  return (
    <form
      onSubmit={!error && payload ? handleCancelVoucher : handleSubmitVoucher}
      className="flex items-start space-x-3"
    >
      <InputText
        value={voucherCode}
        size={InputText.SIZES.SMALL}
        containerClassName="w-full h-full"
        className="bg-gray-200 w-full px-3 py-3"
        placeholder="Kode Voucher"
        disabled={!error && !!payload}
        onChange={handleChangeVoucherCode}
        feedback={payload?.code ? `Voucher ${payload.code} dapat digunakan` : payload?.message}
        feedbackType={error ? InputText.FEEDBACK_TYPE.ERROR : InputText.FEEDBACK_TYPE.SUCCESS}
      />
      <Button
        variant={!error && payload ? Button.VARIANTS.DANGER : Button.VARIANTS.PRIMARY_OUTLINE}
        isLoading={isLoading}
        type="submit"
      >
        {!error && payload ? 'Cancel' : 'Gunakan'}
      </Button>
    </form>
  );
}

export default PaymentVoucher;
