import { Link } from 'react-router-dom';
import parser from 'html-react-parser';
import PATH from '@/routes/path';
import { useTranslation } from 'react-i18next';
import HomeSection from '../components/HomeSection/HomeSection';

function HomeContinueModule({ data }) {
  const { t } = useTranslation();

  return (
    <HomeSection
      className="bg-gray-100 pb-5"
      title={t('continue_module')}
      linkText={t('to_my_module')}
      linkTo={PATH.PROFILE}
    >
      <Link
        to={`${PATH.COURSE}/${data.slug}`}
        className="flex max-w-screen-xl mx-auto flex-col md:flex-row bg-white rounded-md transition-all shadow-md hover:shadow-lg overflow-hidden"
      >
        <div className="w-full md:w-60">
          <img className="w-full h-48 object-cover" src={data.thumbnail} alt={data.title} />
        </div>
        <div className="flex flex-col justify-between w-full px-6 py-4 space-y-5">
          <div className="space-y-2">
            <div className="font-semibold text-primary-600 text-lg">{data.title}</div>
            <div className="text-sm text-gray-500 line-clamp-3">{parser(data.description)}</div>
          </div>
          <div className="text-sm">
            <div className="font-semibold">{data.category.name}</div>
            <div className="text-gray-500">{data.instructor}</div>
          </div>
        </div>
      </Link>
    </HomeSection>
  );
}

export default HomeContinueModule;
