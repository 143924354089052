import { GET_HOME_DATA, GET_HOME_LOADING } from './home.types';

const INITIAL_STATE = {
  payload: null,
  error: null,
  isLoading: false,
};

const homeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_HOME_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_HOME_DATA:
      return {
        ...state,
        payload: action.payload,
        error: action.error,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default homeReducer;
