import { Formik, Form } from 'formik';
import { useHistory } from 'react-router-dom';
import { notify } from 'react-notify-toast';
import { FaEnvelope } from 'react-icons/fa';
import { Button, InputText } from '@/components/atoms';
import { useSelector, useDispatch } from 'react-redux';
import PATH from '@/routes/path';
import { VALIDATION_SCHEMA } from '@/utils';
import { ACTION_AUTH } from '@/redux/actions';
import AuthContainer from './components/AuthContainer';

const INITIAL_VALUES = { email: '', phone: '' };

const ForgotPassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const FORGOT_PASSWORD_TYPE = ForgotPassword.TYPE.EMAIL;
  const FORGOT_PASSWORD_SCHEMA = VALIDATION_SCHEMA.emailSchema;

  const isLoading = useSelector((state) => state.auth.forgotPassword.isLoading);

  const handleSubmit = (values) => {
    if (FORGOT_PASSWORD_TYPE === ForgotPassword.TYPE.PHONE) {
      notify.show(`value : ${values.email}`, 'success');
      history.push(PATH.AUTH_OTP);
    } else if (FORGOT_PASSWORD_TYPE === ForgotPassword.TYPE.EMAIL) {
      const data = { email: values.email };

      dispatch(
        ACTION_AUTH.forgotPassword(data, (payload) => {
          notify.show(`${payload}`, 'success');
          history.push(PATH.AUTH_LOGIN);
        })
      );
    }
  };

  return (
    <Formik initialValues={INITIAL_VALUES} onSubmit={handleSubmit} validationSchema={FORGOT_PASSWORD_SCHEMA}>
      {({ values, setFieldValue, getFieldMeta, handleChange }) => (
        <Form>
          <AuthContainer>
            <div className="mb-12">
              <h1 className="text-2xl font-semibold mb-3 text-primary">Lupa Password</h1>
              <p className="text-gray-500 text-sm">
                {FORGOT_PASSWORD_TYPE === ForgotPassword.TYPE.PHONE && 'Kami akan mereset ulang password kamu.'}
                {FORGOT_PASSWORD_TYPE === ForgotPassword.TYPE.EMAIL &&
                  'Kami akan mengirim link untuk mereset ulang password kamu.'}
              </p>
            </div>
            <div className="mb-6">
              {FORGOT_PASSWORD_TYPE === ForgotPassword.TYPE.PHONE && (
                <InputText
                  variant={InputText.VARIANTS.MATERIAL}
                  className="mb-3"
                  name="phone"
                  icon={<span className="text-sm text-gray-400">+62</span>}
                  placeholder="Nomor Handphone"
                  onChange={(event) => {
                    const { value } = event.target;
                    setFieldValue('phone', value[0] === '0' ? value.substr(1, value.length) : value, true);
                  }}
                  value={values.phone}
                  meta={getFieldMeta('phone')}
                />
              )}
              {FORGOT_PASSWORD_TYPE === ForgotPassword.TYPE.EMAIL && (
                <InputText
                  variant={InputText.VARIANTS.MATERIAL}
                  className="mb-3"
                  name="email"
                  placeholder="Email"
                  icon={<FaEnvelope />}
                  onChange={handleChange}
                  value={values.email}
                  meta={getFieldMeta('email')}
                />
              )}
            </div>
            <Button type="submit" isLoading={isLoading}>
              Reset Password
            </Button>
          </AuthContainer>
        </Form>
      )}
    </Formik>
  );
};

ForgotPassword.TYPE = {
  EMAIL: 'EMAIL',
  PHONE: 'PHONE',
};

export default ForgotPassword;
