import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import { RiFilePaper2Fill } from 'react-icons/ri';
import { Button } from '@/components/atoms';
import PATH from '@/routes/path';

const CourseHeader = ({ slug, title, lecturerName, showCertificate }) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-between px-6 py-6 border-b-2">
      <div>
        <h1 className="text-2xl text-gray-700 font-semibold mb-1">{title}</h1>
        <p className="text-gray-500 text-sm">{lecturerName}</p>
      </div>

      {showCertificate && (
        <Button className="space-x-3" linkTo={`${PATH.COURSE_CERITIFICATE}/${slug}`}>
          <RiFilePaper2Fill />
          <span>{t('lihat_sertifikat')}</span>
        </Button>
      )}
    </div>
  );
};

CourseHeader.Skeleton = () => {
  return (
    <div className="px-6 py-6 border-b-2 flex flex-col space-y-2">
      <div className="md:w-3/4 lg:w-1/2">
        <Skeleton height={30} />
      </div>
      <Skeleton width={100} />
    </div>
  );
};

export default CourseHeader;
