import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import faqReducer from './faq/faq.reducer';
import authReducer from './auth/auth.reducer';
import activityReducer from './activity/activity.reducer';
import homeReducer from './home/home.reducer';
import categoryReducer from './category/category.reducer';
import courseReducer from './course/course.reducer';
import eventReducer from './event/event.reducer';
import transactionReducer from './transaction/transaction.reducer';
import quizReducer from './quiz/quiz.reducer';
import paymentReducer from './payment/payment.reducer';
import profileReducer from './profile/profile.reducer';
import catalogReducer from './catalog/catalog.reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'faq'],
};

const rootReducer = combineReducers({
  auth: authReducer,
  activity: activityReducer,
  home: homeReducer,
  category: categoryReducer,
  course: courseReducer,
  event: eventReducer,
  transaction: transactionReducer,
  quiz: quizReducer,
  payment: paymentReducer,
  profile: profileReducer,
  catalog: catalogReducer,
  faq: faqReducer,
});

export default persistReducer(persistConfig, rootReducer);
