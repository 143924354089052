import axios from 'axios';
import { store } from '@/redux/store';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const http = axios.create({
  baseURL: API_BASE_URL,
});

const getAuthConfig = () => {
  const { token } = store.getState().auth;
  return token ? { headers: { Authorization: `Bearer ${token}` } } : {};
};

export default http;
export { API_BASE_URL, getAuthConfig };
