import { Container, Button } from '@/components/atoms';
import OfflineIcon from '@/images/icons/offline.svg';

const OfflineContent = () => {
  const handleReload = () => window.location.reload();

  return (
    <div className="py-40">
      <Container className="flex flex-col items-center space-y-6">
        <img src={OfflineIcon} alt="Offline Icon" />
        <div className="text-center text-gray-500 text-sm space-y-1">
          <div>Maaf, saat ini anda sedang offline.</div>
          <div>Silakan tekan tombol dibawah jika koneksi anda sudah kembali.</div>
        </div>
        <Button className="text-lg" size={Button.SIZES.MEDIUM} onClick={handleReload}>
          Reload
        </Button>
      </Container>
    </div>
  );
};

export default OfflineContent;
