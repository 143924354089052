import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import { Button, Container, InputFeedback, InputLabel, InputText } from '@/components/atoms';
import { notify } from 'react-notify-toast';
import { ACTION_PROFILE } from '@/redux/actions';
import { useTranslation } from 'react-i18next';

function ProfileUpdate() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [profilePictureFile, setProfilePictureFile] = useState(null);
  const [profilePictureError, setProfilePictureFileError] = useState(null);

  const profile = useSelector((state) => state.auth.profile);
  const isLoading = useSelector((state) => state.profile.updateProfile.isLoading);

  const MAX_FILE_SIZE = 1024 * 1000;

  const [initialValues, setInitialValues] = useState({
    name: '',
    phone_number: '',
    occupation: '',
    birth_date: '',
  });

  const handleChangeProfilePicture = (e) => {
    const file = e.target.files[0];

    if (file && file.type.indexOf('image') === -1) {
      notify.show('File harus berupa gambar', 'warning');
      e.target.value = '';
      return;
    }

    if (file && file.size > MAX_FILE_SIZE) {
      setProfilePictureFileError(`Ukuran file tidak boleh lebih dari ${MAX_FILE_SIZE / 1000} kilobytes.`);
    } else setProfilePictureFileError(null);

    setProfilePictureFile(file);
  };

  const handleUpdateProfile = (values) => {
    const formData = new FormData();

    formData.append('name', values.name);

    if (values.phone_number) formData.append('phone_number', values.phone_number);
    if (values.occupation) formData.append('occupation', values.occupation);
    if (values.birth_date) formData.append('birth_date', values.birth_date);
    if (profilePictureFile) formData.append('profile_picture', profilePictureFile);

    if (!profilePictureFile || (profilePictureFile && !profilePictureError)) {
      dispatch(ACTION_PROFILE.updateProfile(profile.uuid, formData));
    } else notify.show(`Ukuran file tidak boleh lebih dari ${MAX_FILE_SIZE / 1000} kilobytes.`, 'warning');
  };

  useEffect(() => {
    if (profile) {
      setInitialValues({
        ...initialValues,
        name: profile.name,
        phone_number: profile.phone,
      });
    }
  }, [profile]);

  return (
    <div>
      <div className="bg-primary py-12">
        <Container>
          <div className="flex justify-between items-center">
            <div className="space-y-3">
              <div className="text-white font-semibold text-2xl">{t('informasi_profil_anda')}</div>
              <div className="text-white text-sm">
                {t('anda_dapat_melakukan_perubahan_informasi_profil_di_sini')}
              </div>
            </div>
          </div>
        </Container>
      </div>

      <div className="py-14">
        <Formik enableReinitialize initialValues={initialValues} onSubmit={handleUpdateProfile}>
          {({ values, getFieldMeta, handleChange }) => (
            <Form>
              <Container className="space-y-6">
                <div className="grid lg:grid-cols-2 gap-12 lg:gap-6">
                  <div>
                    <div className="text-lg font-semibold text-gray-600 mb-5">Basic Info</div>
                    <div className="space-y-5">
                      <InputText
                        name="name"
                        label={t('form_input.name')}
                        value={values.name}
                        meta={getFieldMeta('name')}
                        onChange={handleChange}
                        disabled={isLoading}
                      />
                      <InputText label="Email" type="email" value={profile?.email} disabled />
                      <InputText
                        name="phone_number"
                        label={t('form_input.phone')}
                        type="tel"
                        value={values.phone_number}
                        meta={getFieldMeta('phone_number')}
                        onChange={handleChange}
                        disabled={isLoading}
                      />
                    </div>
                  </div>

                  <div>
                    <div className="text-lg font-semibold text-gray-600 mb-5">Biodata</div>
                    <div className="space-y-5">
                      <InputText
                        name="occupation"
                        label={t('form_input.occupation')}
                        value={values.occupation}
                        meta={getFieldMeta('occupation')}
                        onChange={handleChange}
                        disabled={isLoading}
                      />
                      <InputText
                        name="birth_date"
                        label={t('form_input.birth_date')}
                        value={values.birth_date}
                        meta={getFieldMeta('birth_date')}
                        type="date"
                        onChange={handleChange}
                        disabled={isLoading}
                      />
                      <div>
                        <InputLabel label="Foto Profil" />
                        <div className="flex items-center space-x-3">
                          {(profilePictureFile || profile.avatar_url) && (
                            <img
                              className="w-20 h-20 object-cover rounded-md"
                              src={
                                profilePictureFile ? URL.createObjectURL(profilePictureFile) : profile.avatar_url
                              }
                              alt="edit profile"
                            />
                          )}
                          <label htmlFor="profilePicture">
                            <input
                              className="w-full text-sm hidden"
                              name="profile_picture"
                              id="profilePicture"
                              type="file"
                              accept="image/*"
                              onChange={handleChangeProfilePicture}
                              disabled={isLoading}
                            />
                            <div
                              className={`px-6 py-3 text-sm text-white transition-all inline-block rounded-sm ${
                                !isLoading ? 'bg-primary hover:bg-primary-400 cursor-pointer' : 'bg-primary-400'
                              }`}
                            >
                              {profilePictureFile ? t('change') : t('upload')} {t('photo')}
                            </div>
                          </label>
                        </div>
                        {profilePictureError && (
                          <InputFeedback type={InputFeedback.TYPES.ERROR} message={profilePictureError} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <hr />

                <div className="flex justify-end">
                  <Button type="submit" isLoading={isLoading}>
                    {t('update_profile')}
                  </Button>
                </div>
              </Container>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default ProfileUpdate;
