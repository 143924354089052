import React, { useState } from 'react';
import { MdComment, MdSend } from 'react-icons/md';
import moment from 'moment/min/moment-with-locales.min';
import { useSelector } from 'react-redux';
import { BiLoaderAlt } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { InputText } from '@/components/atoms';
import QnaLikeButton from './QnaLikeButton';
import QnaDotsDropdownButton from './QnaDotsDropdownButton';

const QnaItem = ({ className, content, replies, uuid, onReply, author, hasLiked, createdAt, likesCount }) => {
  const { t } = useTranslation();

  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const isSubmitting = useSelector((state) => state.course.comments.submitting);

  const handleReply = (e) => {
    if (!isSubmitting) {
      if (message) {
        onReply(message, uuid);
        setMessage('');
        setError('');
      } else {
        setError(t('required'));
      }
    }

    e.preventDefault();
  };

  const handleChangeMessage = (e) => {
    if (e.target.value) setError('');
    setMessage(e.target.value);
  };

  return (
    <div className={`flex space-x-3 ${className}`}>
      <div className="w-12 h-12" style={{ minWidth: '48px', minHeight: '48px' }}>
        <img
          className="w-full h-full rounded-full object-cover"
          src={author.avatar_url || require('@/images/avatar/avatar.png')}
          alt=""
        />
      </div>
      <div className="w-full space-y-6">
        <div className="space-y-3">
          <div className="space-y-2">
            <div className="flex justify-between items-center">
              <div className="flex flex-col md:flex-row md:items-center md:space-x-3">
                <h1 className="text-sm md:text-base font-semibold">{author.name}</h1>
                <div className="text-gray-400 text-xs md:text-sm">{moment(createdAt).fromNow()}</div>
              </div>
              <div>
                <QnaDotsDropdownButton uuid={uuid} />
              </div>
            </div>
            <div>
              <p className="text-xs md:text-sm">{content}</p>
            </div>
          </div>
          <div className="flex items-center space-x-6">
            <button
              className="flex items-center space-x-2 text-gray-400 hover:text-primary transition-all text-xs md:text-sm"
              type="button"
              onClick={() => setIsOpen(!isOpen)}
            >
              <MdComment size={16} />
              <span>{t('answer')}</span>
            </button>
            <QnaLikeButton uuid={uuid} hasLiked={hasLiked} count={likesCount} />
          </div>

          {isOpen && (
            <form onSubmit={handleReply} className="space-y-1">
              <InputText
                inputClassName="text-xs md:text-sm"
                placeholder={t('tulis_jawabanmu_di_sini')}
                value={message}
                onChange={handleChangeMessage}
                disabled={isSubmitting}
                iconSuffix={
                  <button type="submit" onClick={handleReply}>
                    {isSubmitting ? <BiLoaderAlt className="animate-spin" size={18} /> : <MdSend size={18} />}
                  </button>
                }
              />
              <div className="text-red-500 text-xs">{error}</div>
            </form>
          )}
        </div>

        {replies?.length > 0 &&
          replies.map((reply) => (
            <div key={reply.uuid} className="space-y-6">
              <div className="flex space-x-3">
                <div className="w-10 h-10" style={{ minWidth: '40px', minHeight: '40px' }}>
                  <img
                    className="w-full h-full rounded-full object-cover"
                    src={reply.author.avatar_url || require('@/images/avatar/avatar.png')}
                    alt=""
                  />
                </div>
                <div className="w-full space-y-3">
                  <div>
                    <div className="space-y-2">
                      <div className="flex justify-between items-center">
                        <div className="flex flex-col md:flex-row md:items-center md:space-x-3">
                          <h1 className="text-sm md:text-base font-semibold">{reply.author.name}</h1>
                          <div className="text-gray-400 text-xs md:text-sm">
                            {moment(reply.created_at).fromNow()}
                          </div>
                        </div>
                        <div>
                          <QnaDotsDropdownButton uuid={uuid} />
                        </div>
                      </div>
                      <div>
                        <p className="text-xs md:text-sm">{reply.content}</p>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center space-x-6">
                    <QnaLikeButton uuid={reply.uuid} hasLiked={reply.has_liked} count={reply.likes_count} />
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default QnaItem;
