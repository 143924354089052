import { MdApps } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { ACTION_ACTIVITY } from '@/redux/actions';
import { useTranslation } from 'react-i18next';

const HeaderButtonCatalog = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleShowCatalogMenu = () => dispatch(ACTION_ACTIVITY.showCatalogMenu(true));

  return (
    <button
      className="flex flex-col items-center justify-center hover:bg-gray-100 transition-all px-5 h-full"
      onClick={() => handleShowCatalogMenu()}
      type="button"
    >
      <MdApps className="text-gray-400" size="26" />
      <span className="text-xs text-gray-400">{t('catalog')}</span>
    </button>
  );
};

export default HeaderButtonCatalog;
