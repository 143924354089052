import { useTranslation } from 'react-i18next';
import { CLIENT } from '@/config';
import HomeSection from '../components/HomeSection/HomeSection';

const HomeAbout = () => {
  const { t } = useTranslation();

  return (
    <HomeSection>
      <div>
        <div className="flex md:flex-row items-center flex-col justify-between">
          <div className="hidden md:block md:w-1/2">
            <img src={CLIENT.ABOUT_US.image} alt={t(CLIENT.ABOUT_US.title.transactionKey)} />
          </div>
          <div className="md:w-1/2">
            <h1 className="text-4xl font-bold text-secondary mb-8">{t(CLIENT.ABOUT_US.title.transactionKey)}</h1>
            <div className="text-lg text-gray-500 font-light font-secondary">{CLIENT.ABOUT_US.description}</div>
          </div>
        </div>
      </div>
    </HomeSection>
  );
};

export default HomeAbout;
