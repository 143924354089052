import * as yup from 'yup';
import { REGEX_PATTERN } from './regex-pattern';

const registerSchema = yup.object().shape({
  name: yup.string().required('form_validation.fullname_required'),
  email: yup.string().email('form_validation.email_invalid').required('form_validation.email_required'),
  phone: yup
    .string()
    .matches(REGEX_PATTERN.number, 'form_validation.phone_invalid')
    .min(10, 'form_validation.phone_invalid')
    .required('form_validation.phone_required'),
  password: yup.string().min(8, 'form_validation.password_short').required('form_validation.password_required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'form_validation.password_mismatch')
    .required('form_validation.confirm_password_required'),
});

const changePassword = yup.object().shape({
  old_password: yup
    .string()
    .min(8, 'form_validation.password_short')
    .required('form_validation.password_required'),
  new_password: yup
    .string()
    .min(8, 'form_validation.password_short')
    .required('form_validation.password_required'),
  confirm_new_password: yup
    .string()
    .oneOf([yup.ref('new_password'), null], 'form_validation.password_mismatch')
    .required('form_validation.confirm_password_required'),
});

const loginSchema = yup.object().shape({
  email: yup.string().email('form_validation.email_invalid').required('form_validation.email_required'),
  password: yup.string().min(8, 'form_validation.password_short').required('form_validation.password_required'),
});

const phoneNumberSchema = yup.object().shape({
  phone: yup
    .string()
    .matches(REGEX_PATTERN.number, 'Nomor Handphone tidak valid')
    .min(10, 'Nomor Handphone tidak valid')
    .required('Nomor tidak boleh kosong'),
});

const emailSchema = yup.object().shape({
  email: yup.string().email('Email tidak valid').required('Email tidak boleh kosong'),
});

const createNewPasswordSchema = yup.object().shape({
  password: yup.string().min(8, 'Minimal 8 karakter').required('Kata sandi tidak boleh kosong'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Kata sandi tidak sesuai')
    .required('Konfirmasi kata sandi tidak boleh kosong'),
});

const paymentSchema = yup.object().shape({
  // paymentMethod: yup.mixed().required('Pilih metode pembayaran'),
});

const qnaCommentSchema = yup.object().shape({
  message: yup.string().required('Pesan tidak boleh kosong'),
});

export const VALIDATION_SCHEMA = {
  loginSchema,
  registerSchema,
  phoneNumberSchema,
  emailSchema,
  changePassword,
  createNewPasswordSchema,
  paymentSchema,
  qnaCommentSchema,
};
