const LOGIN_TYPE = {
  GOOGLE: 'google',
  EMAIL: 'email',
};

const AUTH_TOKEN = 'AUTH_TOKEN';

const BUSINESS_TYPE = {
  TYPE_RETAIL: 1,
  TYPE_SUBSCRIPTION: 2,
};

const PRODUCT_TYPE = {
  COURSE: {
    id: 0,
    value: 'course',
    path: 'course',
    title: 'Course',
  },
  EVENT: {
    id: 1,
    path: 'event',
    value: 'event',
    title: 'Event',
  },
};

const COURSE_PROGRESS_ACTIONS = {
  STARTED: 1,
  PAUSED: 2,
  FINISHED: 3,
};

const PRODUCT_TYPE_OPTIONS = Object.values(PRODUCT_TYPE);
const PRODUCT_TYPE_OPTIONS_VALUE = PRODUCT_TYPE_OPTIONS.map((option) => option.value);
const PRODUCT_TYPE_BY_ID = PRODUCT_TYPE_OPTIONS.reduce((acc, value) => {
  return {
    ...acc,
    [value.id]: value,
  };
}, {});

const LEARNING_RESOURCE_TYPE = {
  EMPTY: 0,
  VIDEO: 1,
  AUDIO: 2,
  PDF: 3,
  QUIZ: 4,
  YOUTUBE: 5,
};

const PROMO_TYPE = {
  VOUCHER: 'VOUCHER',
};

const LANGUAGES = {
  INDONESIAN: 'id',
  ENGLISH: 'en',
};

const PAYMENT_STATUS_TYPE = {
  ALL: {
    translationKey: 'payment_status_type.semua',
    label: 'Semua',
    value: '',
  },
  MENUNGGU_PEMBAYARAN: {
    translationKey: 'payment_status_type.menunggu_pembayaran',
    label: 'Menunggu Pembayaran',
    value: -5,
  },
  MENUNGGU_KONFIRMASI: {
    translationKey: 'payment_status_type.menunggu_konfirmasi',
    label: 'Menunggu Konfirmasi',
    value: -4,
  },
  EXPIRED: {
    translationKey: 'payment_status_type.kadaluwarsa',
    label: 'Kedaluwarsa',
    value: -3,
  },
  REJECTED: {
    translationKey: 'payment_status_type.ditolak',
    label: 'Ditolak',
    value: -2,
  },
  CANCELED: {
    translationKey: 'payment_status_type.dibatalkan',
    label: 'Dibatalkan',
    value: -1,
  },
  VERIFIED: {
    translationKey: 'payment_status_type.berhasil',
    label: 'Berhasil',
    value: 0,
  },
};

const PAYMENT_STATUS_TYPE_OPTIONS = Object.values(PAYMENT_STATUS_TYPE);

const PAYMENT_METHODS = {
  BANK_TRANSFER: {
    value: 98,
    label: 'Bank Transfer',
  },
  DEPOSIT: {
    value: 20,
    label: 'Deposit',
  },
  VOUCHER: {
    value: 1,
    label: 'Voucher',
  },
  COUPON: {
    value: 2,
    label: 'Coupon',
  },
  PUT: {
    value: 23,
    label: 'Put',
  },
  COMMUNITY: {
    value: 4,
    label: 'Community',
  },
  MAIN_VOUCHER: {
    value: 11,
    label: 'Main Voucher',
  },
  MIDTRANS: {
    value: 88,
    label: 'Midtrans',
  },
};

const PAYMENT_METHOD_OPTIONS = Object.values(PAYMENT_METHODS);
const PAYMENT_METHOD_BY_VALUE = PAYMENT_METHOD_OPTIONS.reduce((acc, method) => {
  return { ...acc, [method.value]: method.label };
}, {});
const AVAILABLE_PAYMENT_METHODS = [PAYMENT_METHODS.BANK_TRANSFER, PAYMENT_METHODS.MIDTRANS];
const AVAILABLE_PAYMENT_METHODS_BY_VALUE = AVAILABLE_PAYMENT_METHODS.reduce((acc, method) => {
  return { ...acc, [method.value]: method.label };
}, {});

const MONTHS = [
  'Januari',
  'Februari',
  'Maret',
  'April',
  'Mei',
  'Juni',
  'Juli',
  'Agustus',
  'September',
  'Oktober',
  'November',
  'Desember',
];

export const CONSTANTS = {
  AUTH_TOKEN,
  LOGIN_TYPE,
  BUSINESS_TYPE,
  COURSE_PROGRESS_ACTIONS,
  PRODUCT_TYPE,
  PRODUCT_TYPE_OPTIONS,
  PRODUCT_TYPE_OPTIONS_VALUE,
  PRODUCT_TYPE_BY_ID,
  LEARNING_RESOURCE_TYPE,
  LANGUAGES,
  PROMO_TYPE,
  PAYMENT_STATUS_TYPE,
  PAYMENT_STATUS_TYPE_OPTIONS,
  PAYMENT_METHODS,
  PAYMENT_METHOD_BY_VALUE,
  AVAILABLE_PAYMENT_METHODS,
  AVAILABLE_PAYMENT_METHODS_BY_VALUE,
  MONTHS,
};
