import http, { getAuthConfig } from './http';

const BASE_URL = '/quiz';

const getQuiz = (quizUUID, callback) => {
  http
    .get(`${BASE_URL}/${quizUUID}`, getAuthConfig())
    .then((res) => callback({ payload: res.data.payload, error: null }))
    .catch((error) => callback({ payload: null, error: error.response }));
};

const submitQuiz = (quizUUID, quizAnswers, callback) => {
  const data = { answers: JSON.stringify(Object.assign({}, ...quizAnswers)) };

  http
    .post(`${BASE_URL}/${quizUUID}`, data, getAuthConfig())
    .then((res) => callback({ payload: res.data.payload, error: null }))
    .catch((error) => callback({ payload: null, error: error.response }));
};

export const SERVICE_QUIZ = { getQuiz, submitQuiz };
