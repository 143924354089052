import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@/components/atoms';

export const PDF = ({ title, url, onGoToTheNextTopic }) => {
  const isLoggedIn = useSelector((state) => state.auth.loggedIn);

  return (
    <div
      id="pdfViewer"
      className="overflow-y-scroll bg-gray-100 flex flex-col items-center"
      style={{ height: '50rem' }}
    >
      <iframe title={title} className="w-full h-full" src={`${url}#toolbar=0`} />
      {isLoggedIn && (
        <Button className="my-6" variant={Button.VARIANTS.PRIMARY} onClick={onGoToTheNextTopic}>
          Go to the next topic
        </Button>
      )}
    </div>
  );
};
