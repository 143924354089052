import { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { VALIDATION_SCHEMA } from '@/utils';
import { Button, Container, InputText } from '@/components/atoms';
import { ACTION_AUTH } from '@/redux/actions';
import { notify } from 'react-notify-toast';

const INITIAL_VALUES = {
  old_password: '',
  new_password: '',
  confirm_new_password: '',
};

function ProfileChangePassword() {
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.auth.changePassword?.isLoading);

  const [isShowOldPassword, setIsShowOldPassword] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);

  const handleChangePassword = (values, actions) => {
    dispatch(
      ACTION_AUTH.changePassword(values.old_password, values.new_password, (message) => {
        notify.show(message, 'success');
        actions.resetForm();
      })
    );
  };

  return (
    <div>
      <div className="bg-primary py-12">
        <Container>
          <div className="flex justify-between items-center">
            <div className="space-y-3">
              <div className="text-white font-semibold text-2xl">Ubah Password</div>
              <div className="text-white text-sm">Anda dapat melakukan perubahan password di sini</div>
            </div>
          </div>
        </Container>
      </div>

      <div className="py-14">
        <Container>
          <Formik
            initialValues={INITIAL_VALUES}
            onSubmit={handleChangePassword}
            validationSchema={VALIDATION_SCHEMA.changePassword}
          >
            {({ handleSubmit, handleChange, values, getFieldMeta }) => (
              <Form>
                <div className="grid lg:grid-cols-2 gap-6">
                  <div>
                    <InputText
                      label="Password Lama"
                      value={values.old_password}
                      onChange={handleChange}
                      iconSuffix={
                        <button
                          className="hover:text-gray-400 py-1"
                          type="button"
                          onClick={() => setIsShowOldPassword(!isShowOldPassword)}
                        >
                          {isShowOldPassword ? <FaEye size={20} /> : <FaEyeSlash size={20} />}
                        </button>
                      }
                      type={isShowOldPassword ? 'text' : 'password'}
                      name="old_password"
                      meta={getFieldMeta('old_password')}
                      placeholder="Password"
                    />
                  </div>
                  <div />
                  <div>
                    <InputText
                      label="Password Baru"
                      value={values.new_password}
                      iconSuffix={
                        <button
                          className="hover:text-gray-400 py-1"
                          type="button"
                          onClick={() => setIsShowPassword(!isShowPassword)}
                        >
                          {isShowPassword ? <FaEye size={20} /> : <FaEyeSlash size={20} />}
                        </button>
                      }
                      type={isShowPassword ? 'text' : 'password'}
                      onChange={handleChange}
                      meta={getFieldMeta('new_password')}
                      name="new_password"
                      placeholder="Password"
                    />
                  </div>

                  <div className="space-y-3 flex flex-col items-end">
                    <InputText
                      label="Konfirmasi Password Baru"
                      value={values.confirm_new_password}
                      containerClassName="w-full"
                      iconSuffix={
                        <button
                          className="hover:text-gray-400 py-1"
                          type="button"
                          onClick={() => setIsShowConfirmPassword(!isShowConfirmPassword)}
                        >
                          {isShowConfirmPassword ? <FaEye size={20} /> : <FaEyeSlash size={20} />}
                        </button>
                      }
                      type={isShowConfirmPassword ? 'text' : 'password'}
                      onChange={handleChange}
                      meta={getFieldMeta('confirm_new_password')}
                      name="confirm_new_password"
                      placeholder="Password"
                    />
                    <Button isLoading={isLoading} onClick={handleSubmit}>
                      Ubah Password
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Container>
      </div>
    </div>
  );
}

export default ProfileChangePassword;
