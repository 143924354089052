import { useRef, useState } from 'react';
import ReactPlayer from 'react-player';

const CourseContentVideo = ({ url, onStart, onPause, onEnded }) => {
  const playerRef = useRef();

  const [watchedTime, setWatchedTime] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  const handleSeek = () => {
    const delta = playerRef.current.getCurrentTime() - currentTime;
    // console.table({ watchedTime, getCurrentTime: playerRef.current.getCurrentTime(), currentTime, delta });
    if (delta > 0.01) {
      playerRef.current.seekTo(currentTime);
    }
  };

  const handleProgress = (progress) => {
    if (progress.playedSeconds > watchedTime) setWatchedTime(progress.playedSeconds);
    setCurrentTime(progress.playedSeconds);
  };

  return (
    <div className="relative min-h-full h-80 md:h-96 lg:h-128">
      <ReactPlayer
        ref={playerRef}
        controls
        width="100%"
        height="100%"
        url={url}
        onStart={onStart}
        onPause={onPause}
        onEnded={onEnded}
        onSeek={handleSeek}
        onProgress={handleProgress}
        config={{ file: { attributes: { controlsList: 'nodownload' } } }}
      />
    </div>
  );
};

export default CourseContentVideo;
