import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, ContentLoader, ProductCard } from '@/components/atoms';
import EmptyTicketIcon from '@/images/icons/img_ticket_empty.png';
import { ACTION_PROFILE } from '@/redux/actions';
import PATH from '@/routes/path';

const MyTicket = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const data = useSelector((state) => state.profile.events.payload);
  const isLoading = useSelector((state) => state.profile.events.isLoading);

  useEffect(() => {
    dispatch(ACTION_PROFILE.getProfileEventTicket());
  }, []);

  return (
    <div>
      {isLoading && <ContentLoader className="py-24" />}
      {!isLoading && data && data.length === 0 && (
        <div className="py-20 flex flex-col items-center text-center space-y-8">
          <img className="w-24" src={EmptyTicketIcon} alt="empty idp" />
          <div className="text-gray-400">{t('belum_ada_event_yang_diikuti')}</div>
          <Button linkTo={PATH.CATALOG_EVENT} variant={Button.VARIANTS.SECONDARY}>
            {t('jelajahi_event')}
          </Button>
        </div>
      )}
      {!isLoading && data && data.length > 0 && (
        <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 lg:gap-5">
          {data.map((event) => (
            <ProductCard
              title={event.title}
              image={event.image_url}
              bookingCode={event.booking_code}
              heldAt={event.held_at}
              linkTo={`${PATH.TICKET}/${event.uuid}`}
            />
          ))}
        </div>
      )}
    </div>
  );
};

MyTicket.Title = {
  value: 'My Ticket',
  key: 'my_ticket',
};

MyTicket.Hash = '#my-ticket';

export default MyTicket;
