import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { useHistory, useParams } from 'react-router-dom';
import { MdAccountCircle, MdRoom, MdTimer } from 'react-icons/md';
import parser from 'html-react-parser';
import { Button, Container } from '@/components/atoms';
import { ProductShare } from '@/components/molecules';
import Avatar from '@/images/avatar/avatar.png';
import { ACTION_EVENT } from '@/redux/actions';
import { CONSTANTS, HELPERS } from '@/utils';
import { AiFillInfoCircle } from 'react-icons/ai';
import PATH from '../path';

function EventDetail() {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();

  const slug = params.uid;
  const productType = CONSTANTS.PRODUCT_TYPE.EVENT;

  const profile = useSelector((state) => state.auth.profile);
  const payload = useSelector((state) => state.event.detail.payload);
  const isLoading = useSelector((state) => state.event.detail.loading);

  const handleBuyEvent = () => {
    const { title, ticket_price: price, uuid } = payload;

    const data = profile && {
      contact_person: {
        name: profile.name,
        phone: profile.phone,
        email: profile.email,
      },
      participants: [
        {
          name: profile.name,
          phone: profile.phone,
          email: profile.email,
        },
      ],
    };

    history.push(`${PATH.PAYMENT}`, { title, productType, price, slug, uuid, item_data: data });
  };

  useEffect(() => {
    dispatch(ACTION_EVENT.getEventDetail(slug));
  }, []);

  return (
    <div className="bg-gray-100 pt-8 pb-12">
      <Container>
        {isLoading && <EventDetail.Skeleton />}
        {!isLoading && payload && (
          <div className="shadow-2xl">
            <div className="bg-white flex flex-col md:flex-row flex-start">
              <div className="w-full lg:w-1/2 xl:w-2/3">
                <img className="w-full h-96 object-cover" src={payload.image_url} alt={payload.title} />
              </div>
              <div className="w-full flex flex-col flex-1 lg:w-1/2 xl:w-1/3 p-6 bg-primary-100 bg-opacity-50 space-y-10">
                <div className="flex-1 space-y-3">
                  <div>
                    <h1 className="text-2xl font-semibold">{payload.title}</h1>
                  </div>

                  <div className="flex flex-wrap gap-2">
                    <Button
                      key={payload.category.id}
                      className="rounded-full"
                      size={Button.SIZES.SMALL2X}
                      variant={Button.VARIANTS.SECONDARY_OUTLINE}
                      linkTo={`${PATH.CATALOG_EVENT}&category_id=${payload.category.id}`}
                    >
                      {payload.category.title}
                    </Button>
                  </div>
                </div>

                <div className="space-y-3">
                  <div className="flex items-start space-x-3">
                    <span>
                      <MdAccountCircle size={18} />
                    </span>
                    <span className="text-sm">{payload.organizer}</span>
                  </div>
                  <div className="flex items-start space-x-3">
                    <span>
                      <MdTimer size={18} />
                    </span>
                    <span className="text-sm">{payload.ticket_time}</span>
                  </div>
                  <div className="flex items-start space-x-3">
                    <span>
                      <MdRoom size={18} />
                    </span>
                    <span className="text-sm">{payload.location_detail}</span>
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <div className="bg-white px-6 py-3">
              <div className="flex flex-col-reverse md:flex-row items-center md:justify-between">
                <ProductShare
                  containerClassName="md:w-full flex justify-start items-center text-sm mt-6 md:mt-0"
                  iconSize={12}
                />

                <div className="w-full flex flex-col md:flex-row items-center space-y-3 md:space-y-0 md:space-x-5">
                  <div className="w-full flex items-center justify-center md:justify-end text-primary font-semibold text-2xl">
                    {payload.ticket_price === 0 && `GRATIS`}
                    {payload.ticket_price !== 0 && `Rp ${HELPERS.numberFormat(payload.ticket_price)}-`}
                  </div>
                  <Button className="w-full md:w-1/2 px-20" onClick={handleBuyEvent}>
                    Beli Tiket
                  </Button>
                </div>
              </div>
            </div>

            <hr />

            <div className="flex flex-col md:flex-row p-6 space-y-6 md:space-y-0 md:space-x-6 bg-white">
              <div className="w-full md:w-2/3 space-y-6">
                <div>
                  <div className="text-lg font-semibold mb-2">Tentang Acara</div>
                  <div className="text-sm">{parser(payload.description)}</div>
                </div>
                <div>
                  <div className="text-lg font-semibold mb-2">Catatan Penting</div>
                  <div className="flex items-center space-x-3 text-sm bg-primary-100 p-3 rounded-md">
                    <span className="text-primary-700">
                      <AiFillInfoCircle size={18} />
                    </span>
                    <span>{payload.sop_detail}</span>
                  </div>
                </div>
                <div>
                  <div className="text-lg font-semibold mb-2">Speakers</div>
                  <div className="grid lg:grid-cols-2 gap-3">
                    {payload.speakers.map((speaker) => (
                      <div key={speaker.name} className="flex items-center space-x-3">
                        <div className="w-16 h-16">
                          <img
                            className="w-full h-full rounded-full object-cover"
                            src={speaker.avatar_url || Avatar}
                            alt={speaker.name}
                          />
                        </div>
                        <div>
                          <div className="font-semibold">{speaker.name}</div>
                          <div className="text-sm">{speaker.title}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="w-full md:w-1/3 space-y-6">
                <div>
                  <div className="text-lg font-semibold mb-2">Kontak</div>
                  <div className="text-sm space-y-2">
                    <div>{payload.organizer}</div>
                    <div>
                      {payload.contact_person} - {payload.cp_phone}
                    </div>
                    <div>{payload.cp_email}</div>
                  </div>
                </div>
                {payload.location_lat && payload.location_long && (
                  <div>
                    <div className="text-lg font-semibold mb-2">Lokasi</div>
                    <div className="space-y-2">
                      <div className="text-sm">{payload.location_detail}</div>
                      <iframe
                        className="w-full"
                        height={300}
                        src={`https://maps.google.com/maps?hl=en&q=${payload.location_lat},${payload.location_long}&t=&z=16&ie=UTF8&iwloc=&output=embed`}
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        title={payload.title}
                        referrerPolicy="no-referrer-when-downgrade"
                      />
                      <div className="flex justify-end">
                        <a
                          className="text-primary text-sm font-semibold hover:underline"
                          href={`https://maps.google.com/maps?hl=en&q=${payload.location_lat},${payload.location_long}&t=&z=16&ie=UTF8&iwloc=`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Lihat Peta
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Container>
    </div>
  );
}

EventDetail.Skeleton = () => {
  return (
    <>
      <div className="bg-white flex flex-col md:flex-row flex-start">
        <div className="w-full lg:w-1/2 xl:w-2/3 p-3">
          <Skeleton className="h-96 p-1" />
        </div>
        <div className="w-full flex flex-col flex-1 lg:w-1/2 xl:w-1/3 p-6 bg-primary-100 bg-opacity-50 space-y-10">
          <div className="flex-1 space-y-6">
            <div className="flex items-center justify-between">
              <div className="inline-flex flex-col items-center justify-center">
                <Skeleton className="w-14 h-10" />
              </div>
              <div className="flex items-center space-x-3">
                <Skeleton className="w-20 h-6" />
                <Skeleton className="w-10 h-10" circle />
                <Skeleton className="w-10 h-10" circle />
                <Skeleton className="w-10 h-10" circle />
              </div>
            </div>
            <div className="flex flex-col space-y-1">
              <Skeleton className="h-6" />
              <Skeleton className="w-1/2 h-6" />
            </div>
          </div>
          <div className="flex flex-col space-y-3">
            <Skeleton className="w-48 h-7" />
            <Skeleton className="h-10" />
          </div>
        </div>
      </div>

      <hr />

      <div className="flex flex-col md:flex-row p-6 space-y-6 md:space-y-0 md:space-x-6 bg-white">
        <div className="w-full md:w-2/3 space-y-6">
          <div className="flex flex-col space-y-3">
            <Skeleton className="w-32 h-7" />
            <Skeleton className="h-4" count={3} />
          </div>
          <div className="flex flex-col space-y-3">
            <Skeleton className="w-32 h-7" />
            <div className="flex items-center space-x-3">
              <div>
                <Skeleton className="w-16 h-16" circle />
              </div>
              <div>
                <Skeleton className="w-32 h-5" />
                <Skeleton className="w-32 h-5" />
              </div>
            </div>
          </div>
        </div>

        <div className="w-full md:w-1/3 space-y-6">
          <div>
            <Skeleton className="w-32 h-7" />
            <div className="text-sm">
              <Skeleton className="h-5" />
              <Skeleton className="h-5" />
            </div>
          </div>
          <div>
            <Skeleton className="w-32 h-7" />
            <Skeleton height={250} />
          </div>
          <div className="flex flex-col space-y-3">
            <Skeleton className="w-32 h-7" />
            <Skeleton className="h-5" count={3} />
          </div>
        </div>
      </div>
    </>
  );
};

export default EventDetail;
