import { CONSTANTS } from '@/utils';
import http, { getAuthConfig } from './http';

const VOUCHER_BASE_URL = '/vouchers';
const PAYMENT_BASE_URL = '/payment';

const getVoucherCode = (voucherCode, callback) => {
  http
    .get(`${VOUCHER_BASE_URL}/${voucherCode}`, getAuthConfig())
    .then((res) => {
      callback({ payload: res.data.payload, error: null });
    })
    .catch((error) => callback({ payload: error.response.data, error }));
};

const getBankAccounts = (callback) => {
  http
    .get(`${PAYMENT_BASE_URL}/bank_accounts`, getAuthConfig())
    .then((res) => {
      callback({ payload: res.data.payload, error: null });
    })
    .catch((error) => callback({ payload: error.response.data, error }));
};

const submitPayment = (uuid, activeVoucher, productType, paymentMethod, itemData, quantity, callback) => {
  const data = {
    item_type: productType.value,
    purchasable_type: productType.id,
    method: paymentMethod,
    item_uuid: uuid,
    purchasable_uuid: uuid,
    item_quantity: quantity,
  };

  if (activeVoucher) data.voucher_code = activeVoucher;
  if (productType.value === CONSTANTS.PRODUCT_TYPE.EVENT.value) data.item_data = JSON.stringify(itemData);

  http
    .post(`${PAYMENT_BASE_URL}`, data, getAuthConfig())
    .then((res) => {
      callback({ payload: res.data.payload, error: null });
    })
    .catch((error) => console.info(error));
  // callback({ payload: error.response.data, error }))
};

const confirmTransfer = (invoiceNumber, formData, callback) => {
  http
    .post(`${PAYMENT_BASE_URL}/${invoiceNumber}/confirm`, formData, getAuthConfig())
    .then((res) => {
      callback({ payload: res.data.payload, error: null });
    })
    .catch((error) => callback({ payload: null, error }));
};

const cancelPayment = (invoiceNumber, callback) => {
  http
    .post(`${PAYMENT_BASE_URL}/${invoiceNumber}/cancel`, null, getAuthConfig())
    .then((res) => {
      callback({ payload: res.data.payload, error: null });
    })
    .catch((error) => callback({ payload: null, error }));
};

export const SERVICE_PAYMENT = { getVoucherCode, submitPayment, getBankAccounts, confirmTransfer, cancelPayment };
