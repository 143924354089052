import { Accordion, Container } from '@/components/atoms';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ACTION_FAQ } from '@/redux/faq/faq.action';

const FAQ = () => {
  const dispatch = useDispatch();
  const sectionData = useSelector((state) => state.faq.sections);

  useEffect(() => {
    dispatch(ACTION_FAQ.getQuestionData());
  }, []);

  // const questions = sectionData.reduce((prev, val) => prev.concat(val.contents), []);

  return (
    <>
      <div className="bg-primary">
        <Container>
          <div className="text-white py-16 text-2xl font-semibold">Frequently Asked Question</div>
        </Container>
      </div>
      <div className="bg-gray-100 py-8">
        <Container className="space-y-3 flex flex-col w-full items-center">
          {sectionData.map((section, sectionIndex) => (
            <div key={`section-${sectionIndex}`} className="w-full max-w-screen-lg">
              <h1 className="mb-3 text-xl">{section.title}</h1>
              {section.contents.map((faq, faqIndex) => (
                <div className="mb-3" key={`faq-${sectionIndex}-${faqIndex}`}>
                  <Accordion title={faq.question} content={faq.answer} insertRawHtml />
                </div>
              ))}
            </div>
          ))}
        </Container>
      </div>
    </>
  );
};

export default FAQ;
