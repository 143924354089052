import { useEffect, useState } from 'react';
import { FaEnvelope, FaKey, FaEye, FaEyeSlash } from 'react-icons/fa';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { notify } from 'react-notify-toast';
import { useTranslation } from 'react-i18next';
import { GoogleLogin } from '@react-oauth/google';
import PATH from '@/routes/path';
import { ACTION_AUTH } from '@/redux/actions';
import { KEY } from '@/config';
import { CONSTANTS, HELPERS, VALIDATION_SCHEMA } from '@/utils';
import { CompanyLogo, InputText, Button } from '@/components/atoms';
import AuthContainer from './components/AuthContainer';

const INITIAL_VALUES = {
  email: '',
  password: '',
};

const Login = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const [isShowPassword, setIsShowPassword] = useState(false);

  const handleLogin = (values) => {
    const data = {
      username: values.email,
      password: values.password,
      login_type: CONSTANTS.LOGIN_TYPE.EMAIL,
      client_token: KEY.CLIENT_TOKEN,
    };

    dispatch(ACTION_AUTH.login(data));
  };

  const handleGoogleLogin = (response) => {
    const idToken = response.credential;

    if (idToken) {
      const tokenData = JSON.parse(atob(idToken.split('.')[1]));

      const data = {
        username: tokenData.email,
        password: idToken,
        login_type: CONSTANTS.LOGIN_TYPE.GOOGLE,
        client_token: KEY.CLIENT_TOKEN,
      };

      dispatch(ACTION_AUTH.login(data));
    }
  };

  useEffect(() => {
    if (location.search?.indexOf('email') !== -1) {
      const params = HELPERS.queryStringToObject(location.search);

      dispatch(
        ACTION_AUTH.emailActivation(params.email, params.token, () => {
          notify.show('Aktivasi email berhasil', 'success');
          history.push(PATH.AUTH_LOGIN);
        })
      );
    }
  }, []);

  return (
    <AuthContainer>
      <div className="text-center mb-10">
        <CompanyLogo width={160} link />
      </div>
      <Formik
        initialValues={INITIAL_VALUES}
        onSubmit={handleLogin}
        validationSchema={VALIDATION_SCHEMA.loginSchema}
      >
        {({ values, handleChange, getFieldMeta }) => (
          <Form>
            <div className="w-full mb-10">
              <div className="mb-3">
                <InputText
                  variant={InputText.VARIANTS.MATERIAL}
                  containerClassName="mb-5"
                  icon={<FaEnvelope />}
                  type="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  meta={getFieldMeta('email')}
                  placeholder="Email"
                />
                <InputText
                  variant={InputText.VARIANTS.MATERIAL}
                  icon={<FaKey />}
                  iconSuffix={
                    <button
                      className="hover:text-gray-400 py-1"
                      type="button"
                      onClick={() => setIsShowPassword(!isShowPassword)}
                    >
                      {isShowPassword ? <FaEye size={20} /> : <FaEyeSlash size={20} />}
                    </button>
                  }
                  type={isShowPassword ? 'text' : 'password'}
                  value={values.password}
                  onChange={handleChange}
                  name="password"
                  meta={getFieldMeta('password')}
                  placeholder="Password"
                />
              </div>
              <div className="text-right">
                <Link className="link" to={PATH.AUTH_FORGOT_PASSWORD}>
                  {t('auth_forgot_password')}?
                </Link>
              </div>
            </div>

            <div className="space-y-3 mb-6">
              <Button type="submit" className="w-full">
                {t('auth_login')}
              </Button>
              <div className="text-center text-sm relative">
                <div className="relative z-10 inline bg-white px-6 text-gray-400">{t('auth_or_login_with')}</div>
                <div className="absolute left-0 top-1/2 transform -translate-y-1/2 w-full border-b bg-gray-200" />
              </div>
              <div className="flex items-center justify-center">
                <GoogleLogin
                  onSuccess={handleGoogleLogin}
                  onError={() => {
                    console.log('Login Failed');
                  }}
                />
                <div />
              </div>
            </div>

            <div className="text-sm text-center text-gray-400">
              {t('auth_dont_have_an_account')}{' '}
              <Link to={PATH.AUTH_REGISTER} className="link">
                {t('here')}
              </Link>
            </div>
          </Form>
        )}
      </Formik>
    </AuthContainer>
  );
};

export default Login;
