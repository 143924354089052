import classNames from 'classnames';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import Select from 'react-select';
import InputFeedback from '../InputFeedback/InputFeedback';
import InputLabel from '../InputLabel/InputLabel';
import './InputSelect.css';

const InputSelect = ({
  label,
  options,
  className,
  containerClassName,
  multiple,
  variant,
  disabled,
  meta,
  feedback,
  feedbackType,
  required,
  onChange,
  loading,
  ...props
}) => {
  const defaultClasses = 'text-sm';

  const labelClasses = classNames({
    'border-red-500':
      ((meta && meta.touched && meta.error) || feedback) && feedbackType === InputSelect.FEEDBACK_TYPE.ERROR,
    'border-green-500':
      ((meta && meta.touched && meta.error) || feedback) && feedbackType === InputSelect.FEEDBACK_TYPE.SUCCESS,
    'border-yellow-500':
      ((meta && meta.touched && meta.error) || feedback) && feedbackType === InputSelect.FEEDBACK_TYPE.WARNING,
  });

  const variantClasses = classNames({
    'border bg-white rounded-md': !disabled && variant === InputSelect.VARIANTS.DEFAULT,
    'border bg-gray-200 rounded-md text-gray-100': disabled && variant === InputSelect.VARIANTS.DEFAULT,
    'border-b-2 focus-within:border-secondary': !disabled && variant === InputSelect.VARIANTS.MATERIAL,
  });

  const customStyles = {
    control: (base) => ({
      ...base,
      padding: 3,
      boxShadow: 'none',
      border: 0,
      '&:hover': {
        borderColor: '#ccc',
      },
    }),
    input: (base) => ({
      ...base,
      outline: 0,
      border: 0,
    }),
    menu: (base) => ({
      ...base,
      boxShadow: '0 0 3px rgba(0,0,0,0.5)',
    }),
    indicatorsContainer: (base) => ({
      ...base,
      cursor: 'pointer',
    }),
  };

  return (
    <div className={containerClassName}>
      {label && <InputLabel label={label} required={required} />}
      {loading && <Skeleton height={45} />}
      {!loading && (
        <Select
          className={`input-select ${defaultClasses} ${variantClasses} ${labelClasses} ${className}`}
          styles={customStyles}
          options={options}
          isClearable
          onChange={onChange}
          backspaceRemovesValue
          isDisabled={disabled}
          isMulti={multiple}
          {...props}
        />
      )}
      {((!loading && meta && meta.touched && meta.error) || feedback) && (
        <InputFeedback type={feedbackType} message={meta.error || feedback} />
      )}
    </div>
  );
};

InputSelect.VARIANTS = {
  DEFAULT: 'DEFAULT',
  MATERIAL: 'MATERIAL',
};

InputSelect.FEEDBACK_TYPE = { ...InputFeedback.TYPES };

InputSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
  variant: PropTypes.oneOf(Object.values(InputSelect.VARIANTS)),
  feedbackType: PropTypes.oneOf(Object.values(InputSelect.FEEDBACK_TYPE)),
  multiple: PropTypes.bool,
};

InputSelect.defaultProps = {
  options: [],
  variant: InputSelect.VARIANTS.DEFAULT,
  feedbackType: InputFeedback.TYPES.ERROR,
  multiple: false,
};

export default InputSelect;
