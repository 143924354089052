import { HELPERS } from '@/utils';
import http, { getAuthConfig } from './http';

const BASE_URL = '/transactions';

const getTransactionList = (params, callback) => {
  const queryParams = params.item_type !== null ? HELPERS.objectToQueryString(params) : '';
  http
    .get(`${BASE_URL}${queryParams}`, getAuthConfig())
    .then((res) => {
      callback({ payload: res.data.payload, error: null });
    })
    .catch((error) => callback({ payload: null, error }));
};

const getTransactionDetail = (invoiceNumber, callback) => {
  http
    .get(`${BASE_URL}/${invoiceNumber}`, getAuthConfig())
    .then((res) => {
      callback({ payload: res.data.payload, error: null });
    })
    .catch((error) => callback({ payload: null, error }));
};

export const SERVICE_TRANSACTION = { getTransactionList, getTransactionDetail };
