export const TOGGLE_SIDENAV = 'TOGGLE_SIDENAV';
export const SHOW_CATALOG_MENU = 'SHOW_CATALOG_MENU';
export const HIDE_CATALOG_MENU = 'HIDE_CATALOG_MENU';
export const SHOW_TOP_LOADING_BAR = 'SHOW_TOP_LOADING_BAR';
export const HIDE_TOP_LOADING_BAR = 'HIDE_TOP_LOADING_BAR';
export const SHOW_FULLPAGE_LOADER = 'SHOW_FULLPAGE_LOADER';
export const HIDE_FULLPAGE_LOADER = 'HIDE_FULLPAGE_LOADER';
export const SHOW_HEADER = 'SHOW_HEADER';
export const HIDE_HEADER = 'HIDE_HEADER';
export const SHOW_FOOTER = 'SHOW_FOOTER';
export const HIDE_FOOTER = 'HIDE_FOOTER';
export const SHOW_BOTTOM_NAV = 'SHOW_BOTTOM_NAV';
export const HIDE_BOTTOM_NAV = 'HIDE_BOTTOM_NAV';
