import { TYPES_PAYMENT } from './payment.types';

const INITIAL_STATE = {
  voucher: {
    payload: null,
    error: null,
    isLoading: false,
  },
  bankAccountList: {
    payload: null,
    error: null,
    isLoading: false,
  },
  submit: {
    payload: null,
    error: null,
    isLoading: false,
  },
  receipt: {
    payload: null,
    error: null,
    isLoading: false,
  },
  cancel: {
    isLoading: false,
  },
};

const paymentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES_PAYMENT.SET_VOUCHER_CODE_LOADING:
      return {
        ...state,
        voucher: {
          isLoading: action.isLoading,
        },
      };
    case TYPES_PAYMENT.GET_VOUCHER_CODE:
      return {
        ...state,
        voucher: {
          payload: action.payload,
          error: action.error,
          isLoading: false,
        },
      };

    case TYPES_PAYMENT.SET_SUBMIT_PAYMENT_LOADING:
      return {
        ...state,
        submit: {
          isLoading: action.isLoading,
        },
      };
    case TYPES_PAYMENT.SUBMIT_PAYMENT:
      return {
        ...state,
        submit: {
          payload: action.payload,
          error: action.error,
          isLoading: false,
        },
      };

    case TYPES_PAYMENT.SET_BANK_ACCOUNTS_LOADING:
      return {
        ...state,
        bankAccountList: {
          isLoading: action.isLoading,
        },
      };
    case TYPES_PAYMENT.GET_BANK_ACCOUNTS:
      return {
        ...state,
        bankAccountList: {
          payload: action.payload,
          error: action.error,
          isLoading: false,
        },
      };

    case TYPES_PAYMENT.SET_UPLOAD_RECEIPT_LOADING:
      return {
        ...state,
        receipt: {
          isLoading: action.isLoading,
        },
      };
    case TYPES_PAYMENT.PUT_UPLOAD_RECEIPT:
      return {
        ...state,
        receipt: {
          payload: action.payload,
          error: action.error,
          isLoading: false,
        },
      };

    case TYPES_PAYMENT.SET_CANCEL_PAYMENT_LOADING:
      return {
        ...state,
        cancel: {
          isLoading: action.isLoading,
        },
      };

    default:
      return state;
  }
};

export default paymentReducer;
