import { useState, useEffect, useRef } from 'react';
import { notify } from 'react-notify-toast';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RiErrorWarningFill } from 'react-icons/ri';
import { ACTION_TRANSACTION, ACTION_PAYMENT } from '@/redux/actions';
import { Button, Modal } from '@/components/atoms';

function ModalUploadReceipt({ open, onClose }) {
  const params = useParams();
  const dispatch = useDispatch();
  const uploadReceiptRef = useRef();

  const { invoiceNumber } = params;

  const MAX_FILE_SIZE = 1024 * 1000; // 1 MB

  const isReceiptLoading = useSelector((state) => state.payment.receipt.isLoading);

  const [buktiTransferFile, setBuktiTransferFile] = useState(null);
  const [buktiTransferError, setBuktiTransferError] = useState(null);
  const [buktiTransferFormData, setBuktiTransferFormData] = useState(null);

  const handleSubmitUpload = () => {
    if (!buktiTransferFormData) notify.show('Upload bukti transfer Anda', 'warning');
    else if (buktiTransferError) notify.show(buktiTransferError, 'warning');
    else {
      dispatch(
        ACTION_PAYMENT.confirmTransfer(invoiceNumber, buktiTransferFormData, () => {
          dispatch(ACTION_TRANSACTION.getTransactionDetail(invoiceNumber));
          onClose();
        })
      );
    }
  };

  const handleChangeBuktiTransfer = (e) => {
    const formEl = uploadReceiptRef.current;
    const formData = new FormData(formEl);
    const file = e.target.files[0];

    if (file && file.type.indexOf('image') === -1) {
      notify.show('File harus berupa gambar', 'warning');
      e.target.value = '';
      return;
    }

    setBuktiTransferFile(file);
    setBuktiTransferFormData(formData);
  };

  const handleValidateBuktiTransfer = () => {
    if (buktiTransferFile) {
      if (buktiTransferFile.size > MAX_FILE_SIZE) {
        setBuktiTransferError(`File tidak boleh lebih dari ${MAX_FILE_SIZE / 1000} kilobytes.`);
      } else setBuktiTransferError(null);
    }
  };

  useEffect(() => {
    handleValidateBuktiTransfer();
  }, [buktiTransferFile]);

  return (
    <Modal title="Bukti Pembayaran" open={open} onClose={onClose}>
      <div className="space-y-6">
        {buktiTransferFile && buktiTransferFormData && (
          <div>
            {buktiTransferError && (
              <div className="text-red-500 text-sm text-center mb-3 flex items-center space-x-3 justify-center bg-red-50 p-2 rounded-sm">
                <RiErrorWarningFill size={18} />
                <span>{buktiTransferError}</span>
              </div>
            )}
            <figure className="space-y-3 text-center">
              <div className="overflow-y-scroll max-h-80 w-full">
                <img src={URL.createObjectURL(buktiTransferFile)} alt="bukti transfer" />
              </div>
              <figcaption className="w-full text-primary text-sm">
                {buktiTransferFormData.get('receipt').name}
              </figcaption>
            </figure>
          </div>
        )}

        <form ref={uploadReceiptRef} id="uploadReceiptForm" className="mt-5">
          <label className="flex w-full justify-center pb-5 mb-5 border-b" htmlFor="uploadPayment">
            <div className="inline-block border border-primary py-2 px-4 rounded-md text-sm text-primary cursor-pointer hover:bg-primary-100">
              {buktiTransferFile && buktiTransferFormData ? 'Ubah' : 'Pilih'} Bukti Transfer
            </div>
            <input
              className="w-full text-sm hidden"
              name="receipt"
              id="uploadPayment"
              type="file"
              accept="image/*"
              onChange={handleChangeBuktiTransfer}
            />
          </label>
          <Button
            className="w-full"
            onClick={handleSubmitUpload}
            disabled={isReceiptLoading}
            isLoading={isReceiptLoading}
          >
            Saya Sudah Transfer
          </Button>
        </form>
      </div>
    </Modal>
  );
}

export default ModalUploadReceipt;
