import { BiCartAlt } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Container, Button } from '@/components/atoms';
import Avatar from '@/images/avatar/avatar.png';
import PATH from '@/routes/path';

const ProfileHeader = () => {
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth.profile);

  return (
    <div className="bg-primary py-12">
      <Container>
        <div className="md:flex justify-between items-center space-y-8 md:space-y-0">
          <div className="flex items-center space-x-5">
            <img className="rounded-full w-28 h-28 object-cover" src={auth?.avatar_url || Avatar} alt="Avatar" />
            <div className="w-full md:w-auto flex flex-col justify-between space-y-3">
              <div>
                <div className="text-lg text-white">{auth.name}</div>
                <div className="text-sm text-white opacity-80">{auth.email}</div>
              </div>
              <div className="sm:flex space-y-2 sm:space-y-0 sm:space-x-3">
                <Button
                  className="w-full sm:w-auto"
                  linkTo={PATH.PROFILE_UPDATE}
                  variant={Button.VARIANTS.SECONDARY_OUTLINE}
                >
                  {t('update_profile')}
                </Button>
                <Button
                  className="w-full sm:w-auto"
                  linkTo={PATH.PROFILE_CHANGE_PASSWORD}
                  variant={Button.VARIANTS.SECONDARY_OUTLINE}
                >
                  {t('change_password')}
                </Button>
              </div>
            </div>
          </div>

          <div>
            <Button
              className="w-full md:w-auto"
              linkTo={PATH.TRANSACTION}
              variant={Button.VARIANTS.WHITE_OUTLINE}
              size={Button.SIZES.LARGE}
            >
              <BiCartAlt className="mr-3" size={18} />
              <span>{t('my_transaction')}</span>
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ProfileHeader;
