import Slider from 'react-slick';
import { GrFormPrevious, GrFormNext } from 'react-icons/gr';
import React from 'react';
import './HomeAdsSlider.css';

export const HomeAdsSlider = ({ children }) => {
  const PrevArrow = ({ currentSlide, slideCount, ...props }) => <GrFormPrevious {...props} />;
  const NextArrow = ({ currentSlide, slideCount, ...props }) => <GrFormNext {...props} />;

  const sliderSettings = {
    className: 'h-full',
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  return (
    <div>
      <Slider {...sliderSettings}>{children}</Slider>
    </div>
  );
};
