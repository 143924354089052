import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { ACTION_EVENT } from '@/redux/actions';
import { Button, Container, ContentLoader } from '@/components/atoms';
import { HiChevronLeft, HiDownload } from 'react-icons/hi';
import { API_BASE_URL } from '@/services/http';
import PATH from '../path';

const Ticket = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();

  const payload = useSelector((state) => state.event.ticket.payload);
  const isLoading = useSelector((state) => state.event.ticket.loading);

  const handleDownloadTicket = () => {
    window.open(`${API_BASE_URL}/ticket/${params.uuid}/download`, '_blank');
  };

  useEffect(() => {
    moment.locale('id');
    dispatch(ACTION_EVENT.getTicket(params.uuid));
  }, []);

  return (
    <div className="bg-gray-100 pt-8 pb-12">
      <Container mode={Container.MODE.NARROW}>
        {isLoading && <Ticket.Skeleton />}
        {!isLoading && payload && (
          <div className="shadow-2xl">
            <div className="bg-white">
              <div className="px-8 py-5">
                <Button
                  onClick={() => history.push(`${PATH.PROFILE_MY_TICKET}`)}
                  variant={Button.VARIANTS.PRIMARY_OUTLINE}
                  className="flex items-center space-x-3"
                >
                  <HiChevronLeft size={18} />
                  <span>Kembali ke tiket saya</span>
                </Button>
              </div>

              <hr />

              <div className="p-8 flex flex-col md:flex-row md:items-center md:justify-between space-y-6 md:space-y-0">
                <div className="space-y-3">
                  <h1 className="text-3xl font-semibold">
                    Detail Tiket <span className="text-primary">{payload.title}</span>
                  </h1>
                  <div className="text-gray-400">
                    Booking Code <span className="font-semibold text-primary">{payload.booking_code}</span> pada{' '}
                    {moment(payload.purchased_at).format('DD MMMM YYYY')}
                  </div>
                </div>
                <div>
                  <Button
                    onClick={handleDownloadTicket}
                    size={Button.SIZES.LARGE2X}
                    className="text-lg font-semibold w-full md:w-auto space-x-3"
                  >
                    <HiDownload size={22} />
                    <span>UNDUH TIKET</span>
                  </Button>
                </div>
              </div>

              <hr />

              <div className="p-8 flex flex-col md:flex-row md:space-x-8 space-y-8 md:space-y-0">
                <div className="w-full space-y-4">
                  <div className="font-semibold text-gray-700 p-3 bg-gray-100">Pemesan</div>
                  <div className="space-y-5">
                    <div className="text-sm space-y-1">
                      <div className="font-semibold">Nama</div>
                      <div>{payload.cp_name}</div>
                    </div>
                    <div className="text-sm space-y-1">
                      <div className="font-semibold">Email</div>
                      <div>{payload.cp_email}</div>
                    </div>
                    <div className="text-sm space-y-1">
                      <div className="font-semibold">No Telepon</div>
                      <div>{payload.cp_phone}</div>
                    </div>
                  </div>
                </div>

                <div className="md:w-full lg:w-2/5 space-y-4">
                  <div className="font-semibold text-gray-700 p-3 bg-gray-100">Tentang Event</div>
                  <div className="text-sm space-y-1">
                    <div className="font-semibold">Waktu</div>
                    <div className="flex items-start space-x-2">
                      <div className="w-14">Start : </div>
                      <div>
                        {moment(payload.held_at).locale('id').format('dddd, MMMM YYYY')}, pukul{' '}
                        {moment(payload.held_at).format('HH:mm')}
                      </div>
                    </div>
                    <div className="flex items-start space-x-2">
                      <div className="w-14">End : </div>
                      <div>
                        {moment(payload.held_to).locale('id').format('dddd, MMMM YYYY')}, pukul{' '}
                        {moment(payload.held_to).format('HH:mm')}
                      </div>
                    </div>
                  </div>
                  <div className="text-sm space-y-1">
                    <div className="font-semibold">Lokasi</div>
                    <div className="space-y-2">
                      <div className="text-sm">{payload.location}</div>
                      <iframe
                        className="w-full"
                        height={300}
                        src={`https://maps.google.com/maps?hl=en&q=${payload.location_lat},${payload.location_long}&t=&z=16&ie=UTF8&iwloc=&output=embed`}
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        title={payload.title}
                        referrerPolicy="no-referrer-when-downgrade"
                      />
                      <div className="flex justify-end">
                        <a
                          className="text-white transition-all bg-primary text-center p-2 rounded-sm w-full text-sm hover:bg-primary-400"
                          href={`https://maps.google.com/maps?hl=en&q=${payload.location_lat},${payload.location_long}&t=&z=16&ie=UTF8&iwloc=`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Lihat Peta
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Container>
    </div>
  );
};

Ticket.Skeleton = () => {
  return (
    <div>
      <ContentLoader />
    </div>
  );
};

export default Ticket;
