import { useState } from 'react';
import { CgChevronDown } from 'react-icons/cg';

const Accordion = ({ title, content, insertRawHtml = false }) => {
  const [opened, setOpened] = useState(false);

  return (
    <div className="w-full max-w-screen-lg shadow-md rounded-md overflow-hidden">
      <button
        type="button"
        className="bg-primary w-full p-6 text-white font-semibold flex items-center justify-between hover:bg-primary-400 cursor-pointer transition-all"
        onClick={() => setOpened(!opened)}
      >
        <div className="w-full text-left">{title}</div>
        <div className="flex items-center justify-center">
          <CgChevronDown className={`transition-all transform ${!opened && '-rotate-90'}`} size={22} />
        </div>
      </button>
      {opened && (
        <div className="bg-white p-6 text-gray-500 text-sm">
          {insertRawHtml ? <div dangerouslySetInnerHTML={{ __html: content }} /> : content}
        </div>
      )}
    </div>
  );
};

export default Accordion;
