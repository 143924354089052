import http from './http';

const BASE_URL = '/faq';

const getQuestionData = (callback) => {
  http
    .get(`${BASE_URL}`)
    .then((res) => callback({ payload: res.data.payload, error: null, success: true }))
    .catch((error) => callback({ payload: null, error: error.response, success: false }));
};

export const SERVICE_FAQ = { getQuestionData };
