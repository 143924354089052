import Skeleton from 'react-loading-skeleton';

const CatalogItemSkeletons = () => {
  return (
    <div className="space-y-3">
      <div className="bg-white flex p-3 rounded-md shadow-sm space-x-3">
        <Skeleton width={150} height={100} />
        <div className="flex flex-col w-full justify-between">
          <Skeleton className="w-full" height={15} />
          <div className="flex flex-col">
            <Skeleton width={150} height={15} />
            <Skeleton width={100} height={15} />
          </div>
        </div>
      </div>

      <div className="bg-white flex p-3 rounded-md shadow-sm space-x-3">
        <Skeleton width={150} height={100} />
        <div className="flex flex-col w-full justify-between">
          <Skeleton className="w-full" height={15} />
          <div className="flex flex-col">
            <Skeleton width={150} height={15} />
            <Skeleton width={100} height={15} />
          </div>
        </div>
      </div>

      <div className="bg-white flex p-3 rounded-md shadow-sm space-x-3">
        <Skeleton width={150} height={100} />
        <div className="flex flex-col w-full justify-between">
          <Skeleton className="w-full" height={15} />
          <div className="flex flex-col">
            <Skeleton width={150} height={15} />
            <Skeleton width={100} height={15} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CatalogItemSkeletons;
