import { useState } from 'react';
import moment from 'moment';
import { notify } from 'react-notify-toast';
import { InputPIN, Button } from '@/components/atoms';
import { HOOKS } from '@/utils';
import OTP_SVG from '@/images/icons/otp.svg';
import AuthContainer from './components/AuthContainer';

const OTP = () => {
  const OTP_LENGTH = 6;
  const RESEND_OTP_TIME = 3;
  const RESEND_OTP_TIME_UNIT = 'seconds';
  const RESEND_OTP_TIME_FORMAT = 'dddd, DD MMMM YYYY, HH:mm:ss';

  const [OTPCode, setOTPCode] = useState('');
  const [OTPFeedbackMessage, setOTPFeedbackMessage] = useState(null);
  const [OTPResendCountdown, setOTPResendCountdown] = HOOKS.useCountdown();

  const handleSubmit = (e) => {
    if (OTPCode.length <= 0) setOTPFeedbackMessage('PIN tidak boleh kosong');
    else if (OTPCode.length < OTP_LENGTH) setOTPFeedbackMessage('PIN tidak valid');
    else {
      setOTPFeedbackMessage(null);
      notify.show(`PIN : ${OTPCode}`, 'success');
    }
    e.preventDefault();
  };

  const handleResendOTP = () => {
    setOTPCode('');
    setOTPResendCountdown(moment().add(RESEND_OTP_TIME, RESEND_OTP_TIME_UNIT).format(RESEND_OTP_TIME_FORMAT));
    notify.show(
      'kami telah mengirimkan kode OTP ke nomor handphone anda, silahkan cek kotak masuk untuk mendapatkan kode OTP',
      'warning'
    );
  };

  return (
    <div>
      <AuthContainer>
        <div className="flex flex-col items-center space-y-12">
          <div>
            <h1 className="text-2xl font-semibold mb-3 text-primary">OTP</h1>
            <p className="text-gray-500 text-sm">
              Kami mengirim nomor OTP ke Nomor Handphone Kamu. Periksa handphone Kamu dan masukkan nomor OTP yang
              kami kirim.
            </p>
          </div>
          <div>
            <img className="text-center" src={OTP_SVG} alt="" />
          </div>
          <form className="space-y-6 w-full" onSubmit={handleSubmit}>
            <div>
              <div className="text-sm text-gray-400 mb-3">Masukkan Kode OTP</div>
              {OTPCode}
              <InputPIN
                value={OTPCode}
                type="number"
                inputMode="numeric"
                feedback={OTPFeedbackMessage}
                onChange={(value) => setOTPCode(value)}
                fields={OTP_LENGTH}
              />
            </div>
            <div className="text-center">
              <div className="text-sm text-gray-400 mb-1">Masukkan Kode OTP</div>
              <button
                type="button"
                disabled={OTPResendCountdown}
                className={`text-sm ${OTPResendCountdown ? 'text-gray-400' : 'text-primary'} font-semibold mb-3`}
                onClick={handleResendOTP}
              >
                Kirim Ulang
              </button>
              <span className="text-sm text-gray-400">
                {OTPResendCountdown && ' - '}
                {OTPResendCountdown && `${OTPResendCountdown.minutes}:${OTPResendCountdown.seconds}`}
              </span>
            </div>
            <Button type="submit" className="w-full">
              Verifikasi
            </Button>
          </form>
        </div>
      </AuthContainer>
    </div>
  );
};

export default OTP;
