const ProgressBar = ({ progress }) => {
  return (
    <div>
      <div className="flex items-center space-x-3">
        <div className="w-full h-1 bg-gray-200 rounded-full overflow-hidden">
          <div style={{ width: `${progress}%` }} className="h-full bg-primary" />
        </div>
        <div className="text-xs">{progress}%</div>
      </div>
    </div>
  );
};

ProgressBar.SIZE = {
  SMALL: 'sm',
  MEDIUM: 'md',
  LARGE: 'lg',
};

export default ProgressBar;
