import http, { getAuthConfig } from './http';

const BASE_URL = '/home';

const getHomeData = (callback) => {
  http
    .get(BASE_URL, getAuthConfig())
    .then((res) => {
      callback({ payload: res.data.payload, error: null });
    })
    .catch((error) => callback({ payload: null, error }));
};

export const SERVICE_HOME = { getHomeData };
