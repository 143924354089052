import { notify } from 'react-notify-toast';

const disablePageScroll = (isDisable = true) => {
  const elHTML = document.querySelector('html');

  if (isDisable) elHTML.style.overflowY = 'hidden';
  else elHTML.style.removeProperty('overflow-y');
};

const discountAmount = (discount, maxAmount, total) => {
  const amount = total * (discount / 100);
  const fixedAmount = amount > maxAmount ? maxAmount : amount;
  return fixedAmount;
};

const copyText = (text, successMessage = 'Berhasil disalin') => {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand('Copy');
  textArea.remove();
  notify.show(successMessage, 'success', 3000);
};

const truncateText = (text, maxLength = 10) => {
  let result = text.substr(0, maxLength);
  if (text.length > maxLength) result += '...';
  return result;
};

const handleBrokenImage = (e) => {
  e.target.src = require('@/images/default-image.png');
};

const queryStringToObject = (queryString) => {
  const pairs = queryString.substring(1).split('&');

  const array = pairs.map((el) => {
    const parts = el.split('=');
    return parts;
  });

  return Object.fromEntries(array);
};

const numberFormat = (input) => {
  const number = input.toString();
  const split = number.split('').reverse();
  const addDot = split.map((digit, index) => (index % 3 === 0 && index !== 0 ? `${digit}.` : digit));
  const output = addDot.reverse().join('');

  return output;
};

const objectToQueryString = (obj, isEncodeURI = false) => {
  return Object.keys(obj).reduce((str, value, i) => {
    const key = isEncodeURI ? encodeURIComponent(value) : value;
    const val = isEncodeURI ? encodeURIComponent(obj[key]) : obj[key];
    const delimiter = i === 0 ? '?' : '&';
    return [str, delimiter, key, '=', val].join('');
  }, '');
};

const removeQueryParams = (url, params) => {
  const object = queryStringToObject(url);
  if (params in object) delete object[params];
  const result = objectToQueryString(object);
  return result;
};

const showNotificationServiceError = (error) => {
  let notifyMessage = 'Terjadi Kesalahan';
  let notifyType = 'error';

  if (error) {
    if (error.response?.status === 500 || error.response?.status === 404) {
      notifyType = 'error';
      notifyMessage = `${error.response?.status} : ${error.response?.statusText}`;
    } else if (error.response?.data) {
      if (error.response?.data.errors) {
        notifyType = 'warning';
        notifyMessage = Object.values(error.response?.data.errors).map((message) => <div>{message}</div>);
      } else if (error.response?.data.message) {
        notifyType = 'warning';
        notifyMessage = error.response?.data.message;
      } else notifyType = 'error';
    }
  }

  return notify.createShowQueue()(notifyMessage, notifyType);
};

const removeDuplicateObjectFromArray = (array) => {
  const objects = array.map(JSON.stringify);
  const uniqueSet = new Set(objects);
  const result = Array.from(uniqueSet).map(JSON.parse);

  return result;
};

export const HELPERS = {
  disablePageScroll,
  copyText,
  numberFormat,
  discountAmount,
  handleBrokenImage,
  truncateText,
  queryStringToObject,
  objectToQueryString,
  removeQueryParams,
  showNotificationServiceError,
  removeDuplicateObjectFromArray,
};
