import PropTypes from 'prop-types';
import { FiSearch } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PATH from '@/routes/path';
import { InputText } from '@/components/atoms';
import { ACTION_CATALOG } from '@/redux/actions';

const HeaderSearch = ({ desktop }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const query = useSelector((state) => state.catalog.query);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (location.pathname !== PATH.CATALOG) {
      history.push(query ? `${PATH.CATALOG}?query=${query}` : PATH.CATALOG);
    }
  };

  const handleChangeQuery = (value) => dispatch(ACTION_CATALOG.setSearchQuery(value));

  return desktop ? (
    <form className="w-1/3" onSubmit={handleSubmit}>
      <InputText
        className="bg-gray-100"
        type="search"
        name="search"
        value={query}
        iconSuffix={
          <button type="button" onClick={handleSubmit}>
            <FiSearch size={18} />
          </button>
        }
        onChange={(e) => handleChangeQuery(e.target.value)}
        placeholder={t('search_module')}
      />
    </form>
  ) : (
    <form className="absolute left-0 h-full focus-within:w-full" onSubmit={(e) => handleSubmit(e)}>
      <label className="flex h-full items-stretch" htmlFor="searchMobile">
        <div className="flex items-center cursor-pointer px-6">
          <FiSearch className="text-gray-800" size={22} />
        </div>
        <div className="w-3/5">
          <input
            className="h-full text-sm border-0 w-0 focus:w-full opacity-0 focus:opacity-100 p-0 transition-all duration-200 text-gray-500 rounded outline-none focus:ring-0"
            type="search"
            id="searchMobile"
            value={query}
            onChange={(e) => handleChangeQuery(e.target.value)}
            name="search"
            placeholder={t('search_module')}
          />
        </div>
      </label>
    </form>
  );
};

HeaderSearch.propTypes = {
  desktop: PropTypes.bool,
};

HeaderSearch.defaultProps = {
  desktop: false,
};

export default HeaderSearch;
