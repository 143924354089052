import { useState, useEffect } from 'react';
import { HELPERS } from '@/utils';
import './TablePriceList.css';

function TablePriceList({ items }) {
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (items.length > 0) setTotal(items.reduce((acc, item) => acc + item.value, 0));
  });

  return (
    <div>
      {items && (
        <table className="price-list text-sm text-gray-700 w-full">
          <tbody>
            {items?.map((item) => (
              <tr key={item.title}>
                <td>{item.title}</td>
                {item.value >= 0 && <td>Rp {HELPERS.numberFormat(item.value)}</td>}
                {item.value < 0 && (
                  <td className="text-red-500">- Rp {HELPERS.numberFormat(Math.abs(item.value))}</td>
                )}
              </tr>
            ))}

            <tr className="border-t">
              <td>Total</td>
              <td className="font-semibold">
                {total > 0 ? `Rp ${HELPERS.numberFormat(Math.abs(total))}` : 'GRATIS'}
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
}

export default TablePriceList;
