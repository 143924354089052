import http, { getAuthConfig } from './http';

const BASE_URL = '/events';

const getEventDetailData = (slug, callback) => {
  http
    .get(`${BASE_URL}/${slug}`, getAuthConfig())
    .then((res) => callback({ payload: res.data.payload, error: null, success: true }))
    .catch((error) => callback({ payload: null, error: error.response, success: false }));
};

const getTicket = (uid, callback) => {
  http
    .get(`ticket/${uid}`, getAuthConfig())
    .then((res) => callback({ payload: res.data.payload, error: null, success: true }))
    .catch((error) => callback({ payload: null, error: error.response, success: false }));
};

export const SERVICE_EVENT = {
  getEventDetailData,
  getTicket,
};
