import { TYPES_TRANSACTION } from './transaction.types';

const INITIAL_STATE = {
  list: {
    payload: [],
    error: null,
    isLoading: true,
  },
  detail: {
    payload: null,
    error: null,
    isLoading: true,
  },
};

const transactionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES_TRANSACTION.SET_TRANSACTION_LIST_LOADING:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
        },
      };
    case TYPES_TRANSACTION.GET_TRANSACTION_LIST:
      return {
        ...state,
        list: {
          payload: action.payload,
          error: action.error,
          isLoading: false,
        },
      };

    case TYPES_TRANSACTION.SET_TRANSACTION_DETAIL_LOADING:
      return {
        ...state,
        detail: {
          isLoading: action.isLoading,
        },
      };
    case TYPES_TRANSACTION.GET_TRANSACTION_DETAIL:
      return {
        ...state,
        detail: {
          payload: action.payload,
          error: action.error,
          isLoading: false,
        },
      };

    default:
      return state;
  }
};

export default transactionReducer;
