import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import { Tabs } from '@/components/atoms';
import CourseTabKilasan from './CourseTabKilasan';
import CourseTabQna from './CourseTabQna';
import CourseContentResource from './CourseContentResource';
import CourseTabTopicList from './CourseTabTopicList';

const CourseContent = ({ slug, enrolled, courseUUID, thumbnail, activeResource, onChangeActiveResource }) => {
  const { t } = useTranslation();

  const TAB_LIST = [CourseTabKilasan.Title, CourseTabQna.Title, CourseTabTopicList.Title];
  const [activeTab, setActiveTab] = useState(TAB_LIST[0].value);

  return (
    <div>
      <div>
        {activeResource && (
          <CourseContentResource
            slug={slug}
            title={activeResource.title}
            enrolled={enrolled}
            courseUUID={courseUUID}
            topicUUID={activeResource.uuid}
            isLocked={activeResource.locked}
            type={activeResource.resource_type}
            isLastResource={activeResource.isLastResource}
            url={activeResource.resource_secure_url || activeResource.resource_url}
            completed={activeResource.completed}
            thumbnail={thumbnail}
          />
        )}
      </div>

      <div className="border-t">
        <div className="border-b flex">
          {TAB_LIST.map((tab) => (
            <Tabs
              key={tab.translationKey || tab.value}
              className={`${tab.value === CourseTabTopicList.Title.value && 'lg:hidden'} flex-1`}
              title={tab.translationKey ? t(tab.translationKey) : tab.value}
              active={tab.value === activeTab}
              onClick={() => setActiveTab(tab.value)}
            />
          ))}
        </div>
        <div>
          {activeTab === CourseTabKilasan.Title.value && <CourseTabKilasan className="px-8 py-5" />}
          {activeTab === CourseTabQna.Title.value && <CourseTabQna slug={slug} />}
          {activeTab === CourseTabTopicList.Title.value && (
            <CourseTabTopicList
              className="lg:hidden"
              activeResource={activeResource}
              onChangeActiveResource={onChangeActiveResource}
            />
          )}
        </div>
      </div>
    </div>
  );
};

CourseContent.Skeleton = () => {
  return (
    <div className="space-y-3">
      <Skeleton height={350} />
      <div className="grid grid-cols-2 gap-3 px-3">
        <Skeleton height={40} />
        <Skeleton height={40} />
      </div>
      <div className="p-3 space-y-3">
        <div>
          <Skeleton className="w-40" height={20} />
          <Skeleton className="w-32" height={16} />
        </div>
        <div>
          <Skeleton className="w-40" height={25} />
        </div>
        <div>
          <Skeleton count={3} height={16} />
        </div>
        <div className="flex items-center space-x-6">
          <Skeleton circle width={65} height={65} />
          <div>
            <Skeleton className="w-32" />
            <Skeleton className="w-24" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseContent;
