import { SERVICE_COURSE } from '@/services';
import { CONSTANTS, HELPERS } from '@/utils';
import { TYPES_COURSE } from './course.types';

const getCourseListData = (queries) => {
  return (dispatch) => {
    dispatch({ type: TYPES_COURSE.SET_COURSE_LIST_LOADING });

    SERVICE_COURSE.getCourseListData(queries, (res) => {
      if (res.error) HELPERS.showNotificationServiceError(res.error);
      else
        dispatch({
          type: TYPES_COURSE.GET_COURSE_LIST_DATA,
          payload: res.payload,
        });
    });
  };
};

const getCourseDetailData = (slug) => {
  return (dispatch) => {
    dispatch({ type: TYPES_COURSE.SET_COURSE_DETAIL_LOADING });

    SERVICE_COURSE.getCourseDetailData(slug, (res) => {
      if (res.error) HELPERS.showNotificationServiceError(res.error);
      else {
        const { payload } = res;
        const chapters = payload.modules;
        const resources = chapters.map((chapter) => chapter.topics);
        const totalResource = resources.flat().length;

        dispatch({
          type: TYPES_COURSE.GET_COURSE_DETAIL_DATA,
          payload: { ...payload, total_resource: totalResource },
        });
      }
    });
  };
};

const sendCourseProgress = (slug, topicUUID, progressAction, callback) => {
  return (dispatch) => {
    if (progressAction === CONSTANTS.COURSE_PROGRESS_ACTIONS.FINISHED) {
      dispatch({ type: TYPES_COURSE.SET_COURSE_DETAIL_LOADING });
    }

    SERVICE_COURSE.sendCourseProgress(slug, topicUUID, progressAction, (res) => {
      if (res.error) HELPERS.showNotificationServiceError(res.error);
      else if (callback && progressAction === CONSTANTS.COURSE_PROGRESS_ACTIONS.FINISHED) callback();
    });
  };
};

const getCourseReport = (slug) => {
  return (dispatch) => {
    dispatch({ type: TYPES_COURSE.SET_COURSE_REPORT_LOADING });

    SERVICE_COURSE.getCourseReport(slug, (res) => {
      if (res.error) HELPERS.showNotificationServiceError(res.error);
      else {
        dispatch({ type: TYPES_COURSE.GET_COURSE_REPORT_DATA, payload: res.payload });
      }
    });
  };
};

const getCourseComment = (slug, withoutLoading, callback) => {
  return (dispatch) => {
    if (!withoutLoading) dispatch({ type: TYPES_COURSE.SET_COURSE_COMMENT_LOADING });

    SERVICE_COURSE.getCourseComment(slug, (res) => {
      if (res.error) HELPERS.showNotificationServiceError(res.error);
      else {
        dispatch({ type: TYPES_COURSE.GET_COURSE_COMMENT_DATA, payload: res.payload });
        if (callback) callback();
      }
    });
  };
};

const sendCourseComment = (slug, comment, uuid, callback) => {
  return (dispatch) => {
    dispatch({ type: TYPES_COURSE.SET_COURSE_COMMENT_SUBMITTING });

    SERVICE_COURSE.sendCourseComment(slug, comment, uuid, (res) => {
      if (res.error) HELPERS.showNotificationServiceError(res.error);
      else callback();
    });
  };
};

const sendCourseCommentReport = (uuid, reason, callback) => {
  return (dispatch) => {
    dispatch({ type: TYPES_COURSE.SET_COURSE_COMMENT_REPORT_SUBMITTING, submitting: true });

    SERVICE_COURSE.sendCourseCommentReport(uuid, reason, (res) => {
      dispatch({ type: TYPES_COURSE.SET_COURSE_COMMENT_REPORT_SUBMITTING, submitting: false });

      if (res.error) HELPERS.showNotificationServiceError(res.error);
      else callback();
    });
  };
};

const setCourseCommentLike = (uuid, likeUnlike, callback) => {
  return () => {
    SERVICE_COURSE.setCourseCommentLike(uuid, likeUnlike, (res) => {
      if (res.error) HELPERS.showNotificationServiceError(res.error);
      else callback();
    });
  };
};

export const ACTION_COURSE = {
  getCourseListData,
  getCourseDetailData,
  sendCourseProgress,
  getCourseReport,
  getCourseComment,
  sendCourseComment,
  sendCourseCommentReport,
  setCourseCommentLike,
};
