import { Container, CompanyLogo } from '@/components/atoms';
import HeaderSearch from './components/HeaderSearch';
import HeaderButtonSideNav from './components/HeaderButtonSideNav';

const HeaderMobile = ({ loggedIn, authProfile }) => {
  return (
    <div className="relative h-full flex items-center md:hidden">
      <Container>
        <div className="flex items-center justify-center">
          <HeaderSearch />
          <CompanyLogo link />
          <HeaderButtonSideNav loggedIn={loggedIn} authProfile={authProfile} />
        </div>
      </Container>
    </div>
  );
};

export default HeaderMobile;
