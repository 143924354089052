import { MdPerson } from 'react-icons/md';
import { Container, CompanyLogo, Button, LanguageSwitcher } from '@/components/atoms';
import { useTranslation } from 'react-i18next';
import PATH from '@/routes/path';
import HeaderSearch from './components/HeaderSearch';
import HeaderButtonSideNav from './components/HeaderButtonSideNav';
import HeaderButtonCatalog from './components/HeaderButtonCatalog';

const HeaderDesktop = ({ loggedIn, authProfile }) => {
  const { t } = useTranslation();

  return (
    <div className="hidden md:flex md:items-stretch h-full">
      <Container>
        <div className="w-full flex justify-between h-full">
          <div className="flex flex-1 items-center md:space-x-8 xl:space-x-12">
            <CompanyLogo link />
            <HeaderButtonCatalog />
            <HeaderSearch desktop />
          </div>

          <div className="flex space-x-2">
            <div className="flex items-center">
              <LanguageSwitcher containerClassName="py-1" />
            </div>
            {loggedIn && <HeaderButtonSideNav loggedIn={loggedIn} authProfile={authProfile} desktopMode />}
            {!loggedIn && (
              <div className="flex items-center space-x-3">
                <Button size={Button.SIZES.MEDIUM} className="flex items-center" linkTo={PATH.AUTH_LOGIN}>
                  <MdPerson className="mr-3" size={18} />
                  {t('auth_login')}
                </Button>
                <Button variant={Button.VARIANTS.SECONDARY_OUTLINE} linkTo={PATH.AUTH_REGISTER}>
                  {t('auth_register')}
                </Button>
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HeaderDesktop;
