import { useTranslation } from 'react-i18next';
import EmptyIDPIcon from '@/images/icons/profile_empty_idp.png';

const IDPSaya = () => {
  const { t } = useTranslation();

  const IDP_GROUP_ROW = 0;
  return (
    <div>
      {IDP_GROUP_ROW === 0 && (
        <div className="py-20 flex flex-col items-center text-center space-y-8">
          <img className="w-24" src={EmptyIDPIcon} alt="empty idp" />
          <div className="text-gray-400">{t('my_idp_content_empty')}</div>
        </div>
      )}
    </div>
  );
};

IDPSaya.Title = {
  value: 'IDP Saya',
  key: 'my_idp',
};

IDPSaya.Hash = '#idp-saya';

export default IDPSaya;
