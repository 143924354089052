import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ACTION_AUTH } from '@/redux/actions';
import PATH from '@/routes/path';

function HeaderMenu({ handleClose, loggedIn }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const GLOBAL_MENUS = [
    {
      exact: false,
      title: t('catalog'),
      link: PATH.CATALOG,
    },
  ];

  const MEMBER_MENUS = [
    {
      exact: false,
      title: t('profile'),
      link: PATH.PROFILE,
    },
    {
      exact: false,
      title: t('my_transaction'),
      link: PATH.TRANSACTION,
    },
    {
      title: t('auth_logout'),
      onClick: () => {
        dispatch(ACTION_AUTH.logout());
        history.push(PATH.HOME);
      },
    },
  ];

  const PUBLIC_MENUS = [
    {
      exact: false,
      title: t('auth_login'),
      link: PATH.AUTH_LOGIN,
    },
    {
      exact: false,
      title: t('auth_register'),
      link: PATH.AUTH_REGISTER,
    },
  ];

  const menus = loggedIn ? [...GLOBAL_MENUS, ...MEMBER_MENUS] : [...GLOBAL_MENUS, ...PUBLIC_MENUS];

  const className = 'text-center block text-sm px-8 py-5 text-gray-500 hover:bg-gray-100 transition';

  return (
    <ul>
      {menus.map((menu) => (
        <li key={menu.title}>
          {menu.onClick ? (
            <button
              className={`${className} w-full h-full`}
              type="button"
              onClick={() => {
                menu.onClick();
                handleClose();
              }}
            >
              {menu.title}
            </button>
          ) : (
            <Link className={`${className}`} to={menu.link} onClick={handleClose}>
              {menu.title}
            </Link>
          )}
        </li>
      ))}
    </ul>
  );
}

HeaderMenu.propTypes = {
  loggedIn: PropTypes.bool,
};

HeaderMenu.defaultProps = {
  loggedIn: false,
};

export default HeaderMenu;
