import PropTypes from 'prop-types';
import { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { FiX } from 'react-icons/fi';
import { CONSTANTS } from '@/utils';
import { InputRadio, InputText, Backdrop, Button } from '@/components/atoms';
import { MdFilterList } from 'react-icons/md';

const CatalogFilter = ({
  productType,
  priceMin,
  priceMax,
  onChangePriceMin,
  onChangePriceMax,
  onChangeProductType,
  onReset,
}) => {
  const { t } = useTranslation();

  const LIMIT_START_PRICE = 0;
  const LIMIT_END_PRICE = 20000000;

  const [showCatalogFilterMobile, setShowCatalogFilterMobile] = useState(false);

  const catalogFilterMobileContainerClasses = classNames({
    'visible opacity-100': showCatalogFilterMobile,
    'invisible opacity-0 lg:visible lg:opacity-100': !showCatalogFilterMobile,
  });

  const catalogFilterMobileClasses = classNames({
    'translate-y-0': showCatalogFilterMobile,
    'translate-y-full lg:translate-y-0': !showCatalogFilterMobile,
  });

  return (
    <>
      <Button
        onClick={() => setShowCatalogFilterMobile(true)}
        className="fixed z-10 lg:hidden rounded-full px-6 bottom-24 shadow-lg left-1/2 transform -translate-x-1/2 space-x-3"
      >
        <MdFilterList size={20} />
        <span className="text-base">Filter</span>
      </Button>

      <div
        className={`fixed w-full h-screen lg:h-auto lg:sticky lg:top-24 lg:w-5/12 left-0 bottom-0 z-50 lg:z-10 ${catalogFilterMobileContainerClasses}`}
      >
        <div className="lg:hidden">
          <Backdrop active={showCatalogFilterMobile} onClick={() => setShowCatalogFilterMobile(false)} />
        </div>
        <div
          className={`bg-white absolute bottom-0  lg:relative shadow p-6 pb-28 lg:pb-6 transition-all rounded-md transform w-full left-0 duration-300 ${catalogFilterMobileClasses}`}
        >
          <div className="flex items-center justify-between pb-3 border-b">
            <div className="flex items-center space-x-3">
              <MdFilterList size={16} />
              <span className="text-sm">Filter</span>
            </div>
            <div className="flex items-center">
              <button className="mr-6 lg:mr-0 text-secondary text-sm" type="button" onClick={onReset}>
                Reset
              </button>
              <button
                className="block lg:hidden bg-gray-100 rounded-full p-3"
                onClick={() => setShowCatalogFilterMobile(false)}
                type="button"
              >
                <FiX size={20} />
              </button>
            </div>
          </div>
          <form>
            <div className="border-b py-5">
              <div className="mb-3 text-sm font-semibold">Type</div>
              <div className="grid grid-cols-2 gap-2 md:flex lg:flex-col md:space-x-3 lg:space-x-0">
                {CONSTANTS.PRODUCT_TYPE_OPTIONS.map((type) => (
                  <InputRadio
                    key={type.value}
                    checked={productType !== '' && productType.value === type.value}
                    value={type.value}
                    label={type.title}
                    onChange={(e) => onChangeProductType(e.target.value)}
                    className="border rounded-md p-2 capitalize w-full"
                  />
                ))}
              </div>
            </div>

            <div className="pt-5">
              <div className="mb-3 text-sm font-semibold">{t('price_range')}</div>
              <div className="flex flex-col justify-center space-y-3">
                <InputText
                  type="number"
                  min={LIMIT_START_PRICE}
                  max={priceMax}
                  value={priceMin}
                  icon={<span className="text-sm">Rp.</span>}
                  onChange={(e) => onChangePriceMin(e.target.value)}
                  placeholder={t('minimum_price')}
                />
                <InputText
                  type="number"
                  min={priceMin}
                  max={LIMIT_END_PRICE}
                  value={priceMax}
                  icon={<span className="text-sm">Rp.</span>}
                  onChange={(e) => onChangePriceMax(e.target.value)}
                  placeholder={t('maximum_price')}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

CatalogFilter.propTypes = {
  onChangeProductType: PropTypes.func,
  onReset: PropTypes.func,
};

CatalogFilter.defaultProps = {
  onChangeProductType: () => {},
  onReset: () => {},
};

export default CatalogFilter;
