import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import { CONSTANTS, HELPERS } from '@/utils';
import PATH from '../path';

function TransactionListItem({ title, date, invoiceNumber, amount, paymentMethod, status }) {
  const { t } = useTranslation();

  return (
    <Link
      to={`${PATH.TRANSACTION}/${invoiceNumber}`}
      className="rounded-md shadow-md bg-white flex flex-col p-6 space-y-3 hover:bg-gray-50"
    >
      <div className="flex justify-between">
        <div className="text-blue-500">{amount > 0 ? `Rp ${HELPERS.numberFormat(amount)}` : 'GRATIS'}</div>
        <div className="text-blue-500 text-sm">{CONSTANTS.AVAILABLE_PAYMENT_METHODS_BY_VALUE[paymentMethod]}</div>
      </div>

      <div className="font-semibold text-xl">{title}</div>
      <div className="text-sm text-gray-500 font-semibold pb-3">Invoice Number : {invoiceNumber}</div>
      <div className="flex justify-between">
        <div className="text-gray-500 text-sm">{moment(date).format('DD MMMM YYYY')}</div>

        {CONSTANTS.PAYMENT_STATUS_TYPE_OPTIONS.map((option) => {
          let statusClassName = 'text-green-500';

          if (
            status === CONSTANTS.PAYMENT_STATUS_TYPE.CANCELED.value ||
            status === CONSTANTS.PAYMENT_STATUS_TYPE.REJECTED.value ||
            status === CONSTANTS.PAYMENT_STATUS_TYPE.EXPIRED.value
          ) {
            statusClassName = 'text-red-500';
          } else if (
            status === CONSTANTS.PAYMENT_STATUS_TYPE.MENUNGGU_PEMBAYARAN.value ||
            status === CONSTANTS.PAYMENT_STATUS_TYPE.MENUNGGU_KONFIRMASI
          ) {
            statusClassName = 'text-yellow-500';
          }

          return (
            option.value === status && (
              <div className={`text-right text-sm ${statusClassName}`}>{t(option.translationKey)}</div>
            )
          );
        })}
      </div>
    </Link>
  );
}

TransactionListItem.Skeleton = () => {
  return (
    <div className="rounded-md shadow-md bg-white flex flex-col p-6">
      <Skeleton className="w-16" />
      <Skeleton className="w-1/2" />
      <Skeleton className="w-1/3" />
      <div className="flex justify-between">
        <Skeleton className="w-24" />
        <Skeleton className="w-16" />
      </div>
    </div>
  );
};

export default TransactionListItem;
