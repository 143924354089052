import { SERVICE_CATEGORY } from '@/services';
import { HELPERS } from '@/utils';
import { TYPES_CATEGORY } from './category.types';

const getCategoryListData = () => {
  return (dispatch) => {
    dispatch({ type: TYPES_CATEGORY.GET_CATEGORY_LIST_DATA_LOADING, loading: true });

    SERVICE_CATEGORY.getCategoryListData((res) => {
      dispatch({ type: TYPES_CATEGORY.GET_CATEGORY_LIST_DATA_LOADING, loading: false });

      if (res.success) {
        dispatch({
          type: TYPES_CATEGORY.GET_CATEGORY_LIST_DATA,
          payload: res.payload,
        });
      } else HELPERS.showNotificationServiceError(res.error);
    });
  };
};

export const ACTION_CATEGORY = { getCategoryListData };
