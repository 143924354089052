import { Link } from 'react-router-dom';
import { FaEnvelope, FaPhone, FaLocationArrow } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Container, CompanyLogo } from '@/components/atoms';
import { CLIENT } from '@/config';
import { CONSTANTS } from '@/utils';
import PATH from '@/routes/path';

const Footer = () => {
  const year = moment().year();

  const { t } = useTranslation();

  return (
    <>
      <div className="shadow-2xl py-12">
        <Container>
          <div className="flex flex-col lg:flex-row space-y-10 lg:space-y-0 lg:space-x-16">
            <div className="lg:w-full">
              <div className="mb-3">
                <CompanyLogo width={140} link />
              </div>
              <div className="mb-3">
                <p className="text-sm text-gray-500">{CLIENT.DESCRIPTION}</p>
              </div>
              <div className="space-y-3 text-sm text-gray-500">
                <table>
                  <tbody>
                    <tr className="inline-block w-full mb-3">
                      <td>
                        <FaLocationArrow size={14} className="mr-3 auto-cols-min" />
                      </td>
                      <td>{CLIENT.ADDRESS}</td>
                    </tr>
                    <tr className="inline-block w-full mb-3">
                      <td>
                        <FaEnvelope size={14} className="mr-3 auto-cols-min" />
                      </td>
                      <td>{CLIENT.EMAIL}</td>
                    </tr>
                    <tr className="inline-block w-full mb-3">
                      <td>
                        <FaPhone size={14} className="mr-3 auto-cols-min" />
                      </td>
                      <td>{CLIENT.PHONE}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="sm:flex lg:w-full space-y-6 sm:space-y-0">
              <nav className="text-sm w-full text-secondary">
                <h1 className="font-semibold text-base mb-5 text-gray-700">{t('links')}</h1>
                <ul className="space-y-3">
                  <li>
                    <Link to="/">{t('home')}</Link>
                  </li>
                  <li>
                    <Link to={PATH.CATALOG_COURSE}>{t('explore_module')}</Link>
                  </li>
                  {CLIENT.BUSINESS_TYPE === CONSTANTS.BUSINESS_TYPE.TYPE_SUBSCRIPTION && (
                    <li>
                      <Link to="/plans">{t('package_price')}</Link>
                    </li>
                  )}
                  <li>
                    <Link to="/faq">FAQ</Link>
                  </li>
                  <li>
                    <Link to="/terms">{t('terms_of_use')}</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">{t('privacy_policy')}</Link>
                  </li>
                </ul>
              </nav>
            </div>

            <div className="lg:w-full">
              <div>
                <h1 className="font-semibold text-base mb-5 text-gray-700">{t('follow_our_social_media')}</h1>
                <div className="flex space-x-6">
                  {Object.values(CLIENT.SOCIAL_MEDIA).map((socmed) => (
                    <a key={socmed.title} href={socmed.url} target="blank">
                      {socmed.icon}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className="bg-primary py-3">
        <Container>
          <div className="text-sm text-center text-white">
            Copyright {year} &copy; {CLIENT.NAME}
          </div>
        </Container>
      </div>
    </>
  );
};

export default Footer;
