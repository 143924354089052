import { TYPES_FAQ } from './faq.types';

const INITIAL_STATE = {
  sections: [],
  loading: false,
};

const faqReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES_FAQ.GET_FAQ_DATA_LOADING:
      return { ...state, loading: action.loading || true };
    case TYPES_FAQ.GET_FAQ_LIST_DATA:
      return { ...state, sections: action.payload, loading: false };
    default:
      return state;
  }
};

export default faqReducer;
