import React from 'react';
import { BiLoaderAlt } from 'react-icons/bi';

const ContentLoader = ({ className }) => {
  return (
    <div className={`flex flex-col justify-center items-center space-y-2 ${className}`}>
      <BiLoaderAlt className="text-primary animate-spin" size={20} />
      <div>Loading...</div>
    </div>
  );
};

export default ContentLoader;
