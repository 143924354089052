import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Container } from '@/components/atoms';
import { ACTION_CATALOG } from '@/redux/actions';
import { CONSTANTS, HELPERS } from '@/utils';
import CatalogFilter from './components/CatalogFilter';
import CatalogItem from './components/CatalogItem';
import CatalogItemSkeletons from './skeletons/CatalogItemSkeletons';
import PATH from '../path';

const Catalog = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const catalogData = useSelector((state) => state.catalog.payload);
  const catalogDataLoading = useSelector((state) => state.catalog.loading);
  const query = useSelector((state) => state.catalog.query);

  const [productType, setProductType] = useState(CONSTANTS.PRODUCT_TYPE.COURSE);
  const [priceMin, setPriceMin] = useState('');
  const [priceMax, setPriceMax] = useState('');
  const [categoryId, setCategoryId] = useState('');

  const handleChangeQuery = (value) => dispatch(ACTION_CATALOG.setSearchQuery(value));

  const handleResetFilter = (isExitCatalog = false) => {
    handleChangeQuery('');
    setProductType(CONSTANTS.PRODUCT_TYPE.COURSE);
    setPriceMin('');
    setPriceMax('');
    setCategoryId('');

    if (!isExitCatalog) history.replace(PATH.CATALOG);

    dispatch(ACTION_CATALOG.getCatalog());
  };

  const handleChangeProductType = (productTypeValue) => {
    if (productTypeValue) {
      const selectedProductType = CONSTANTS.PRODUCT_TYPE_OPTIONS.filter((type) => type.value === productTypeValue);
      setProductType(selectedProductType[0]);
    } else {
      setProductType(CONSTANTS.PRODUCT_TYPE.COURSE);
    }
  };

  useEffect(() => {
    let queries = {};

    if (query) queries = { ...queries, query };
    else queries = { ...queries };

    if (priceMin) queries = { ...queries, price_min: priceMin };
    if (priceMax) queries = { ...queries, price_max: priceMax };
    if (categoryId) queries = { ...queries, category_id: categoryId };

    queries = HELPERS.objectToQueryString(queries);
    history.replace(`${PATH.CATALOG}${queries}`);

    if (queries !== '') {
      dispatch(ACTION_CATALOG.getCatalog(queries));
    }
  }, [query, priceMin, priceMax, categoryId]);

  useEffect(() => {
    let params = {};

    if (location.search) {
      params = HELPERS.queryStringToObject(location.search);

      if (params.query) handleChangeQuery(params.query);
      if (params.item_type) handleChangeProductType(params.item_type);
      if (params.price_min) setPriceMin(params.price_min);
      if (params.price_max) setPriceMax(params.price_max);
      if (params.category_id) setCategoryId(params.category_id);
    }
  }, [location.search]);

  useEffect(() => {
    return () => handleResetFilter(true);
  }, []);

  return (
    <div className="bg-gray-100">
      <Container className="lg:flex items-start space-y-6 lg:space-y-0 lg:space-x-10 py-10">
        <CatalogFilter
          productType={productType}
          priceMin={priceMin}
          priceMax={priceMax}
          onReset={handleResetFilter}
          onChangePriceMin={(value) => setPriceMin(value)}
          onChangePriceMax={(value) => setPriceMax(value)}
          onChangeProductType={handleChangeProductType}
        />

        <div className="lg:w-full">
          {catalogDataLoading && <CatalogItemSkeletons />}
          {!catalogDataLoading && (
            <div className="space-y-4">
              {productType?.value === CONSTANTS.PRODUCT_TYPE.COURSE.value && (
                <div className="space-y-4">
                  <h1 className="capitalize text-lg mb-3 text-gray-700 font-semibold">
                    {CONSTANTS.PRODUCT_TYPE.COURSE.title}
                  </h1>
                  {(!catalogData || catalogData?.courses?.length === 0) && (
                    <div className="text-gray-500 bg-white p-6 shadow-sm rounded-md text-sm text-center">
                      {t('no_search_results_found')}
                    </div>
                  )}
                  {catalogData?.courses?.length > 0 &&
                    catalogData?.courses.map((course) => (
                      <CatalogItem
                        key={course.uuid}
                        title={course.title}
                        category={course.category}
                        instructor={course.instructor}
                        thumbnail={course.thumbnail}
                        completed={course.completed}
                        enrolled={course.enrolled}
                        price={course.price}
                        slug={course.slug}
                        type={CONSTANTS.PRODUCT_TYPE.COURSE.value}
                      />
                    ))}
                </div>
              )}

              {productType?.value === CONSTANTS.PRODUCT_TYPE.EVENT.value && (
                <div className="space-y-4">
                  <h1 className="capitalize text-lg mb-3 text-gray-700 font-semibold">
                    {CONSTANTS.PRODUCT_TYPE.EVENT.title}
                  </h1>
                  {(!catalogData || catalogData?.events?.length === 0) && (
                    <div className="text-gray-500 bg-white p-6 shadow-sm rounded-md text-sm text-center">
                      {t('no_search_results_found')}
                    </div>
                  )}
                  {catalogData?.events?.length > 0 &&
                    catalogData?.events.map((event) => (
                      <CatalogItem
                        title={event.title}
                        thumbnail={event.image_url}
                        price={event.ticket_price}
                        slug={event.slug}
                        type={CONSTANTS.PRODUCT_TYPE.EVENT.value}
                      />
                    ))}
                </div>
              )}
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Catalog;
