import http, { getAuthConfig } from './http';

const BASE_URL = '/profile';

const updateProfile = (userUUID, formData, callback) => {
  http
    .post(`users/${userUUID}`, formData, getAuthConfig())
    .then((res) => {
      callback({ payload: res.data.payload, error: null });
    })
    .catch((error) => {
      callback({ payload: null, error });
    });
};

const getProfileEventTicket = (callback) => {
  http
    .get(`${BASE_URL}/events/ticket`, getAuthConfig())
    .then((res) => {
      callback({ payload: res.data.payload, error: null });
    })
    .catch((error) => {
      callback({ payload: null, error });
    });
};

const getProfileModule = (callback) => {
  http
    .get(`${BASE_URL}/courses/enrollment`, getAuthConfig())
    .then((res) => {
      callback({ payload: res.data.payload, error: null });
    })
    .catch((error) => {
      callback({ payload: null, error });
    });
};

const getReportMonthly = (startDate, endDate, callback) => {
  const query = `?start_date=${startDate}&end_date=${endDate}`;

  http
    .get(`${BASE_URL}/reports/monthly${query}`, getAuthConfig())
    .then((res) => {
      callback({ payload: res.data.payload, error: null });
    })
    .catch((error) => {
      callback({ payload: null, error });
    });
};

const getReportSummary = (callback) => {
  http
    .get(`${BASE_URL}/reports/summary`, getAuthConfig())
    .then((res) => {
      callback({ payload: res.data.payload, error: null });
    })
    .catch((error) => {
      callback({ payload: null, error });
    });
};

export const SERVICE_PROFILE = {
  updateProfile,
  getProfileEventTicket,
  getProfileModule,
  getReportMonthly,
  getReportSummary,
};
