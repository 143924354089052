import * as TYPES_CATALOG from './catalog.types';

const INITIAL_STATE = {
  query: '',
  loading: false,
  payload: null,
};

const catalogReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES_CATALOG.SET_SEARCH_QUERY:
      return {
        ...state,
        query: action.query,
      };

    case TYPES_CATALOG.GET_CATALOG_LOADING:
      return { ...state, loading: action.loading };
    case TYPES_CATALOG.GET_CATALOG:
      return {
        ...state,
        payload: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default catalogReducer;
