import { SERVICE_EVENT } from '@/services';
import { HELPERS } from '@/utils';
import * as TYPES_EVENT from './event.types';

const getEventDetail = (slug) => {
  return (dispatch) => {
    dispatch({ type: TYPES_EVENT.GET_EVENT_DETAIL_LOADING, loading: true });

    SERVICE_EVENT.getEventDetailData(slug, (res) => {
      if (res.error) HELPERS.showNotificationServiceError(res.error);
      else {
        dispatch({
          type: TYPES_EVENT.GET_EVENT_DETAIL_DATA,
          payload: res.payload,
        });
      }
    });
  };
};

const getTicket = (slug) => {
  return (dispatch) => {
    dispatch({ type: TYPES_EVENT.GET_TICKET_LOADING, loading: true });

    SERVICE_EVENT.getTicket(slug, (res) => {
      if (res.error) HELPERS.showNotificationServiceError(res.error);
      else {
        dispatch({
          type: TYPES_EVENT.GET_TICKET_DATA,
          payload: res.payload,
        });
      }
    });
  };
};

export const ACTION_EVENT = { getEventDetail, getTicket };
