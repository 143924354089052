import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Form, Formik } from 'formik';
import { notify } from 'react-notify-toast';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, InputText } from '@/components/atoms';
import { HELPERS, VALIDATION_SCHEMA } from '@/utils';
import { ACTION_AUTH } from '@/redux/actions';
import AuthContainer from './components/AuthContainer';
import PATH from '../path';

const INITIAL_VALUES = {
  password: '',
  confirmPassword: '',
};

function ResetPassword() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const isLoading = useSelector((state) => state.auth.resetPassword.isLoading);

  const [resetPasswordParams, setResetPasswordParams] = useState(null);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);

  const handleResetPassword = (values) => {
    dispatch(
      ACTION_AUTH.resetPassword(values.password, values.confirmPassword, resetPasswordParams, () => {
        notify.show('Reset password berhasil', 'success');
        history.push(PATH.AUTH_LOGIN);
      })
    );
  };

  useEffect(() => {
    if (location.search) {
      const params = HELPERS.queryStringToObject(location.search);
      setResetPasswordParams(params);
    } else history.goBack();
  }, []);

  return (
    <div>
      <AuthContainer>
        <div className="mb-12">
          <h1 className="text-2xl font-semibold mb-3 text-primary">Atur Ulang Kata Sandi</h1>
          <p className="text-gray-500 text-sm">Masukkan password baru Anda</p>
        </div>
        <Formik
          initialValues={INITIAL_VALUES}
          onSubmit={handleResetPassword}
          validationSchema={VALIDATION_SCHEMA.createNewPasswordSchema}
        >
          {({ values, handleChange, handleSubmit, getFieldMeta }) => (
            <Form>
              <div className="mb-6 space-y-6">
                <InputText
                  name="password"
                  value={values.password}
                  meta={getFieldMeta('password')}
                  className="mb-3"
                  variant={InputText.VARIANTS.MATERIAL}
                  iconSuffix={
                    <button
                      className="hover:text-gray-400"
                      type="button"
                      onClick={() => setIsShowPassword(!isShowPassword)}
                    >
                      {isShowPassword ? <FaEye size={20} /> : <FaEyeSlash size={20} />}
                    </button>
                  }
                  onChange={handleChange}
                  type={isShowPassword ? 'text' : 'password'}
                  placeholder="Password"
                  autoComplete="off"
                />

                <InputText
                  className="mb-3"
                  variant={InputText.VARIANTS.MATERIAL}
                  iconSuffix={
                    <button
                      className="hover:text-gray-400"
                      type="button"
                      onClick={() => setIsShowConfirmPassword(!isShowConfirmPassword)}
                    >
                      {isShowConfirmPassword ? <FaEye size={20} /> : <FaEyeSlash size={20} />}
                    </button>
                  }
                  type={isShowConfirmPassword ? 'text' : 'password'}
                  onChange={handleChange}
                  name="confirmPassword"
                  value={values.confirmPassword}
                  meta={getFieldMeta('confirmPassword')}
                  placeholder="Confirm Password"
                  autoComplete="off"
                />
              </div>
              <Button className="w-full" onClick={handleSubmit} type="submit" isLoading={isLoading}>
                Atur Ulang Password
              </Button>
            </Form>
          )}
        </Formik>
      </AuthContainer>
    </div>
  );
}

export default ResetPassword;
