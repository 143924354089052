import { CONSTANTS } from '@/utils';
import PATH from '@/routes/path';
import { Container, ProductCard } from '@/components/atoms';
import { useTranslation } from 'react-i18next';
import HomeSection from '../components/HomeSection/HomeSection';
import HomeModuleSkeleton from '../skeletons/HomeModuleSkeleton';
import { HomeModuleSlider } from '../components/HomeModuleSlider/HomeModuleSlider';

const HomeModule = ({ data, error, isLoading }) => {
  const { t } = useTranslation();

  return (
    <div className="bg-gray-100 py-20 space-y-20">
      {(isLoading || error) && <HomeModuleSkeleton />}
      {!isLoading &&
        data &&
        data.map((product) => {
          let type = null;

          if (product.type === CONSTANTS.PRODUCT_TYPE.COURSE.id) type = CONSTANTS.PRODUCT_TYPE.COURSE.path;
          else if (product.type === CONSTANTS.PRODUCT_TYPE.EVENT.id) type = CONSTANTS.PRODUCT_TYPE.EVENT.path;
          else if (product.type === CONSTANTS.PRODUCT_TYPE.LEARNING_PATH.id) {
            type = CONSTANTS.PRODUCT_TYPE.LEARNING_PATH.path;
          }

          const route = type ? `${PATH.CATALOG}?item_type=${type}` : null;

          return (
            <HomeSection
              key={product.title}
              className="pt-0 pb-0"
              containerMode={Container.MODE.MEDIUM}
              title={product.title}
              linkTo={route}
            >
              <div className="px-3">
                {(!product.items || product.items.length === 0) && (
                  <div className="text-center text-gray-400">{t('item_empty')}</div>
                )}
                {product.items && product.items.length > 0 && (
                  <HomeModuleSlider type={type} product={product}>
                    {product.items.map((item) => (
                      <ProductCard
                        className="mx-3 mb-3"
                        key={item.uuid}
                        title={item.title}
                        image={item.course_image}
                        category={item.category.name}
                        instructor={item.instructor}
                        linkTo={`${type}/${item.slug}`}
                      />
                    ))}
                  </HomeModuleSlider>
                )}
              </div>
            </HomeSection>
          );
        })}
    </div>
  );
};

export default HomeModule;
