import store from 'store';
import { CONSTANTS, HELPERS } from '@/utils';
import { SERVICE_AUTH } from '@/services';
import { TYPES_AUTH } from './auth.types';
import { ACTION_ACTIVITY } from '../activity/activity.action';

const register = (data, callback) => {
  return (dispatch) => {
    dispatch(ACTION_ACTIVITY.showTopLoadingBar(true));

    SERVICE_AUTH.register(data, (res) => {
      dispatch(ACTION_ACTIVITY.showTopLoadingBar(false));

      if (res.success) callback();
      else HELPERS.showNotificationServiceError(res.error);
    });
  };
};

const forgotPassword = (data, callback) => {
  return (dispatch) => {
    dispatch(ACTION_ACTIVITY.showTopLoadingBar(true));
    dispatch({ type: TYPES_AUTH.FORGOT_PASSWORD_LOADING, isLoading: true });

    SERVICE_AUTH.forgotPassword(data, (res) => {
      dispatch(ACTION_ACTIVITY.showTopLoadingBar(false));
      dispatch({ type: TYPES_AUTH.FORGOT_PASSWORD_LOADING, isLoading: false });
      if (res.success) callback(res.payload);
      else HELPERS.showNotificationServiceError(res.error);
    });
  };
};

const resetPassword = (password, confirmPassword, params, callback) => {
  return (dispatch) => {
    dispatch(ACTION_ACTIVITY.showTopLoadingBar(true));
    dispatch({ type: TYPES_AUTH.RESET_PASSWORD_LOADING, isLoading: true });

    SERVICE_AUTH.resetPassword(password, confirmPassword, params, (res) => {
      dispatch(ACTION_ACTIVITY.showTopLoadingBar(false));
      dispatch({ type: TYPES_AUTH.RESET_PASSWORD_LOADING, isLoading: false });

      if (res.success) callback(res.payload);
      else HELPERS.showNotificationServiceError(res.error);
    });
  };
};

const changePassword = (oldPassword, newPassword, callback) => {
  return (dispatch) => {
    dispatch(ACTION_ACTIVITY.showTopLoadingBar(true));
    dispatch({ type: TYPES_AUTH.CHANGE_PASSWORD_LOADING, isLoading: true });

    SERVICE_AUTH.changePassword(oldPassword, newPassword, (res) => {
      dispatch(ACTION_ACTIVITY.showTopLoadingBar(false));
      dispatch({ type: TYPES_AUTH.CHANGE_PASSWORD_LOADING, isLoading: false });

      if (res.success && callback) {
        callback(res.payload);
      } else if (!res.success) {
        HELPERS.showNotificationServiceError(res.error);
      }
    });
  };
};

const login = (data, callback) => {
  return (dispatch) => {
    dispatch(ACTION_ACTIVITY.showTopLoadingBar(true));

    SERVICE_AUTH.login(data, (res) => {
      dispatch(ACTION_ACTIVITY.showTopLoadingBar(false));

      if (res.success) {
        store.set(CONSTANTS.AUTH_TOKEN, res.payload.token);

        if (callback) callback();

        dispatch({
          type: TYPES_AUTH.LOGIN,
          profile: res.payload.profile,
          token: res.payload.token,
        });
      } else HELPERS.showNotificationServiceError(res.error);
    });
  };
};

const emailActivation = (email, token, callback) => {
  return (dispatch) => {
    dispatch(ACTION_ACTIVITY.showTopLoadingBar(true));

    SERVICE_AUTH.emailActivation(email, token, (res) => {
      dispatch(ACTION_ACTIVITY.showTopLoadingBar(false));
      if (res.success) callback();
      else HELPERS.showNotificationServiceError(res.error);
    });
  };
};

const logout = () => {
  return (dispatch) => {
    store.clearAll();
    store.remove(CONSTANTS.AUTH_TOKEN);
    dispatch({ type: TYPES_AUTH.LOGOUT });
  };
};

export const ACTION_AUTH = {
  login,
  logout,
  register,
  forgotPassword,
  resetPassword,
  changePassword,
  emailActivation,
};
