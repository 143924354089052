import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { notify } from 'react-notify-toast';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from '@/components/atoms';
import { ACTION_COURSE } from '@/redux/actions';

function QnaReportModalForm({ uuid, open, onClose }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const REASON_OPTIONS = [
    t('mengandung_unsur_tidak_sopan,_provokasi_atau_kekerasan'),
    t('mengandung_iklan,_penipuan_dan_mencurigakan'),
  ];

  const [selectedReasonIndex, setSelectedReasonIndex] = useState(null);
  const [error, setError] = useState(null);

  const isSubmitting = useSelector((state) => state.course.commentReport.submitting);

  const handleSubmitReport = () => {
    const selectedReason = REASON_OPTIONS[selectedReasonIndex];
    dispatch(
      ACTION_COURSE.sendCourseCommentReport(uuid, selectedReason, () => {
        onClose();
        setSelectedReasonIndex(null);
        notify.show(t('berhasil_melaporkan_komentar'), 'success');
      })
    );
  };

  const handleClickReport = () => {
    if (selectedReasonIndex !== null) handleSubmitReport();
    else setError('Pilih salah satu');
  };

  const handleChangeReason = (index) => {
    if (!isSubmitting) setSelectedReasonIndex(index);
  };

  const handleClose = () => {
    if (!isSubmitting) onClose();
  };

  useEffect(() => {
    if (selectedReasonIndex !== null) setError(null);
  }, [selectedReasonIndex]);

  return (
    <Modal open={open} onClose={handleClose} title={t('kenapa_anda_melaporkan_hal_ini?')}>
      <div className="py-4 md:py-6">
        <div className="space-y-3">
          {REASON_OPTIONS.map((option, index) => (
            <button
              type="button"
              key={option}
              onClick={() => handleChangeReason(index)}
              className={`px-5 py-3 rounded-md border w-full text-left text-sm md:text-base ${
                selectedReasonIndex === index ? 'bg-primary text-white' : 'text-gray-800 hover:bg-gray-100'
              }`}
            >
              {option}
            </button>
          ))}
        </div>
        {error && <div className="text-red-500 mt-6 inline-block text-sm">{error}</div>}
      </div>
      <hr />
      <div className="flex flex-col sm:flex-row justify-between mt-6 gap-3">
        <Button disabled={isSubmitting} variant={Button.VARIANTS.PRIMARY_OUTLINE} onClick={handleClose}>
          {t('cancel')}
        </Button>
        <Button isLoading={isSubmitting} onClick={handleClickReport}>
          {t('report')}
        </Button>
      </div>
    </Modal>
  );
}

export default QnaReportModalForm;
