import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TestimonialSlider = ({ items, duration }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemHeight, setItemHeight] = useState(null);

  const ITEMS_LENGTH = items && items.length;

  const goTo = (index) => setCurrentIndex(index);
  const handleNext = () => setCurrentIndex(currentIndex === ITEMS_LENGTH - 1 ? 0 : currentIndex + 1);

  useEffect(() => {
    if (items) {
      const elTestimonialsItem = document.querySelector('#testimonials .active');
      setItemHeight(elTestimonialsItem.clientHeight);

      const timeout = setTimeout(() => handleNext(), duration);
      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [currentIndex]);

  return (
    <>
      <div
        id="testimonials"
        className="relative overflow-hidden flex flex-col transition-all duration-500 items-center"
        style={{ height: itemHeight }}
      >
        {items &&
          items.map((item, index) => {
            const showClass = classNames({
              'opacity-0 -translate-y-24': currentIndex !== index,
              'active opacity-100 delay-500': currentIndex === index,
            });

            return (
              <div
                key={item.name}
                className={`absolute transform flex flex-col items-center text-center w-full transition-all duration-700 md:w-9/12 ${showClass}`}
              >
                <div className="mb-12">
                  <p className="text-gray-500">{item.testimonial}</p>
                </div>
                <div className="w-20 h-20 overflow-hidden mb-3">
                  <img className="rounded-full w-full h-full object-cover" src={item.avatar} alt="" />
                </div>
                <div className="text-primary font-medium">{item.name}</div>
                <div className="text-gray-500">
                  {item.role} at {item.institution}
                </div>
              </div>
            );
          })}
      </div>

      <div className="flex justify-center space-x-3 mt-10">
        {items &&
          items.map((item, index) => {
            const activeClass = classNames({
              'bg-primary': currentIndex === index,
              'bg-gray-400': currentIndex !== index,
            });

            return (
              <button
                key={item.name}
                type="button"
                className={`w-2 h-2 rounded-full transition-colors duration-200 ${activeClass}`}
                onClick={() => goTo(index)}
              >
                <span className="hidden">{index}</span>
              </button>
            );
          })}
      </div>
    </>
  );
};

TestimonialSlider.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      role: PropTypes.string,
      institution: PropTypes.string,
      avatar: PropTypes.string,
      testimonial: PropTypes.string,
    })
  ).isRequired,
  duration: PropTypes.number,
};

TestimonialSlider.defaultProps = {
  duration: 5000,
};

export default TestimonialSlider;
