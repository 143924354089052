import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ACTION_ACTIVITY } from '@/redux/actions';
import { HELPERS } from '@/utils';
import { Backdrop, LanguageSwitcher } from '@/components/atoms';
import Avatar from '@/images/avatar/avatar.png';
import SideNavMenu from './SideNavMenu';

const SideNav = () => {
  const dispatch = useDispatch();
  const show = useSelector((state) => state.activity.showSideNav);
  const { loggedIn, profile } = useSelector((state) => state.auth);
  const containerClass = show ? 'visible' : 'invisible';
  const sideNavClass = show ? `translate-x-0` : `translate-x-full`;
  const sideNavWidth = loggedIn ? 350 : 300;

  const handleClose = () => dispatch(ACTION_ACTIVITY.toggleSideNav());

  useEffect(() => HELPERS.disablePageScroll(show), [show]);

  return (
    <div className={`${containerClass} fixed w-screen h-screen inset-0 transition-all z-40`}>
      <Backdrop active={show} onClick={() => handleClose()} />
      <div
        className={`${sideNavClass} absolute top-0 right-0 bg-white shadow-lg h-screen overflow-y-scroll transform transition duration-300`}
        style={{ width: sideNavWidth }}
      >
        {loggedIn && profile && (
          <div className="flex items-center space-x-2 p-8 border-b">
            <img className="rounded-full w-16" src={Avatar} alt="Avatar" />
            <span className="text-gray-700">{HELPERS.truncateText(profile.name, 15)}</span>
          </div>
        )}
        <SideNavMenu handleClose={handleClose} loggedIn={loggedIn} />
        <div className="flex justify-center mt-6">
          <LanguageSwitcher containerClassName="py-1" />
        </div>
      </div>
    </div>
  );
};

export default SideNav;
