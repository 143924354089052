import React, { useState } from 'react';
import { MdFilterList } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { Backdrop, Button, InputRadio } from '@/components/atoms';
import { CONSTANTS } from '@/utils';

const TransactionListFilter = ({ params, setParams }) => {
  const { t } = useTranslation();

  const [isOpenFilter, setIsOpenFilter] = useState(false);

  return (
    <>
      <div
        className={`fixed bottom-0 left-0 z-20 lg:z-10 w-screen h-full lg:rounded-md lg:shadow-md lg:w-1/3 lg:sticky lg:top-24 overflow-hidden ${
          isOpenFilter ? 'visible' : 'invisible'
        } lg:visible`}
      >
        <Backdrop className="block lg:hidden" active={isOpenFilter} onClick={() => setIsOpenFilter(false)} />
        <div
          className={`fixed ${
            isOpenFilter ? 'translate-y-0' : 'translate-y-full'
          } transition-all bottom-0 left-0 transform p-8 rounded-t-lg bg-white w-full lg:relative lg:translate-y-0 lg:left-0 lg:h-full`}
        >
          <div className="text-primary text-xl font-semibold mb-6">Filter</div>
          <div className="space-y-4">
            {CONSTANTS.PAYMENT_STATUS_TYPE_OPTIONS.map((filter) => (
              <InputRadio
                key={filter.label}
                checked={params.status === filter.value}
                onChange={() => setParams({ ...params, status: filter.value, per_page: 10 })}
                color={InputRadio.COLORS.PRIMARY}
                label={t(filter.translationKey)}
              />
            ))}
          </div>
        </div>
      </div>

      <Button
        className="block lg:hidden fixed bottom-24 left-1/2 transform -translate-x-1/2 space-x-3"
        onClick={() => setIsOpenFilter(!isOpenFilter)}
      >
        <MdFilterList size={20} />
        <span>Filter</span>
      </Button>
    </>
  );
};

export default TransactionListFilter;
