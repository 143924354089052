import http, { getAuthConfig } from './http';

const BASE_URL = '/courses';

const getCourseListData = (queries, callback) => {
  http
    .get(`${BASE_URL}${queries}`, getAuthConfig())
    .then((res) => callback({ payload: res.data.payload, error: null, success: true }))
    .catch((error) => callback({ payload: null, error: error.response, success: false }));
};

const getCourseDetailData = (slug, callback) => {
  http
    .get(`${BASE_URL}/${slug}`, getAuthConfig())
    .then((res) => callback({ payload: res.data.payload, error: null, success: true }))
    .catch((error) => callback({ payload: null, error: error.response, success: false }));
};

const sendCourseProgress = (slug, topicUUID, action, callback) => {
  const data = { topic_uuid: topicUUID, action };

  http
    .post(`${BASE_URL}/${slug}/progress`, data, getAuthConfig())
    .then((res) => callback({ payload: res.data.payload, error: null, success: true }))
    .catch((error) => callback({ payload: null, error: error.response, success: false }));
};

const getCourseReport = (slug, callback) => {
  http
    .get(`${BASE_URL}/${slug}/report`, getAuthConfig())
    .then((res) => callback({ payload: res.data.payload, error: null, success: true }))
    .catch((error) => callback({ payload: null, error: error.response, success: false }));
};

const getCourseComment = (slug, callback) => {
  http
    .get(`${BASE_URL}/${slug}/comments`, getAuthConfig())
    .then((res) => callback({ payload: res.data.payload, error: null, success: true }))
    .catch((error) => callback({ payload: null, error: error.response, success: false }));
};

const sendCourseComment = (slug, comment, commentUuid, callback) => {
  const data = { course_slug: slug, comment };

  if (commentUuid) Object.assign(data, { comment_uuid: commentUuid });

  http
    .post('/comments', data, getAuthConfig())
    .then((res) => callback({ payload: res.data.payload, error: null, success: true }))
    .catch((error) => callback({ payload: null, error: error.response, success: false }));
};

const sendCourseCommentReport = (uuid, reason, callback) => {
  const data = { reason };

  http
    .post(`/comments/${uuid}/reports`, data, getAuthConfig())
    .then((res) => callback({ payload: res.data.payload, error: null, success: true }))
    .catch((error) => callback({ payload: null, error: error.response, success: false }));
};

const setCourseCommentLike = (commentUuid, likeUnlike, callback) => {
  const data = { like: likeUnlike };

  http
    .post(`/comments/${commentUuid}/likes`, data, getAuthConfig())
    .then((res) => callback({ payload: res.data.payload, error: null, success: true }))
    .catch((error) => callback({ payload: null, error: error.response, success: false }));
};

export const SERVICE_COURSE = {
  getCourseListData,
  getCourseDetailData,
  sendCourseProgress,
  getCourseReport,
  getCourseComment,
  sendCourseComment,
  sendCourseCommentReport,
  setCourseCommentLike,
};
