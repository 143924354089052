const GET_TRANSACTION_LIST = 'GET_TRANSACTION_LIST';
const SET_TRANSACTION_LIST_LOADING = 'SET_TRANSACTION_LIST_LOADING';
const GET_TRANSACTION_DETAIL = 'GET_TRANSACTION_DETAIL';
const SET_TRANSACTION_DETAIL_LOADING = 'SET_TRANSACTION_DETAIL_LOADING';

export const TYPES_TRANSACTION = {
  GET_TRANSACTION_LIST,
  SET_TRANSACTION_LIST_LOADING,
  GET_TRANSACTION_DETAIL,
  SET_TRANSACTION_DETAIL_LOADING,
};
