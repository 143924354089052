import { Button } from '@/components/atoms';
import React from 'react';
import { useTranslation } from 'react-i18next';

function QnaEmpty({ onClickAskButton }) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center text-center space-y-3 py-10">
      <img src={require('@/images/icons/ic_empty.svg').default} alt="empty qna icon" />
      <div className="text-sm text-gray-500 pb-6">
        {t('no_questions_yet')}. <br /> {t('be_the_first_to_ask')}
      </div>
      <Button onClick={onClickAskButton} variant={Button.VARIANTS.SECONDARY}>
        {t('ask')}
      </Button>
    </div>
  );
}

export default QnaEmpty;
