import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { notify } from 'react-notify-toast';
import { useTranslation } from 'react-i18next';
import { Button, ContentLoader } from '@/components/atoms';
import { ACTION_COURSE } from '@/redux/actions';
import PATH from '@/routes/path';
import QnaEmpty from './CourseTabQna/QnaEmpty';
import QnaItem from './CourseTabQna/QnaItem';
import QnaModalFormAsk from './CourseTabQna/QnaModalFormAsk';

const CourseTabQna = ({ slug, className }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [isOpenAskModal, setIsOpenAskModal] = useState(false);

  const isLoggedIn = useSelector((state) => state.auth.loggedIn);
  const comments = useSelector((state) => state.course.comments.payload);
  const isLoading = useSelector((state) => state.course.comments.loading);
  const isSubmitting = useSelector((state) => state.course.comments.submitting);

  const handleClickAskButton = (message, uuid = null) => {
    if (isLoggedIn) {
      dispatch(
        ACTION_COURSE.sendCourseComment(slug, message, uuid, () => {
          dispatch(ACTION_COURSE.getCourseComment(slug, true));
          setIsOpenAskModal(false);
        })
      );
    } else {
      history.push(`${PATH.AUTH_LOGIN}?redirect=${PATH.COURSE}/${slug}`);
      notify.show(t('anda_harus_login_terlebih_dahulu'), 'warning');
    }
  };

  useEffect(() => dispatch(ACTION_COURSE.getCourseComment(slug)), []);

  return (
    <div className={className}>
      {isLoading && <ContentLoader className="py-24" />}
      {!isLoading && comments?.length === 0 && <QnaEmpty onClickAskButton={() => setIsOpenAskModal(true)} />}
      {!isLoading && comments?.length > 0 && (
        <div>
          <div className="overflow-y-scroll border-b" style={{ maxHeight: '450px' }}>
            {comments.map((comment) => (
              <Fragment key={comment.uuid}>
                <QnaItem
                  className="px-8 py-5"
                  content={comment.content}
                  replies={comment.replies}
                  createdAt={comment.created_at}
                  author={comment.author}
                  uuid={comment.uuid}
                  hasLiked={comment.has_liked}
                  likesCount={comment.likes_count}
                  onReply={handleClickAskButton}
                />
                <hr className="last:hidden" />
              </Fragment>
            ))}
          </div>

          <div className="flex flex-col items-center justify-center text-center space-y-5 px-8 py-10">
            <div className="text-sm text-gray-400">
              {t('tidak_menemukan_jawaban_yang_sesuai_dengan_pertanyaanmu?')}
            </div>
            <Button onClick={() => setIsOpenAskModal(!isOpenAskModal)} size={Button.SIZES.SMALL} className="px-9">
              {t('ask')}
            </Button>
          </div>
        </div>
      )}

      <QnaModalFormAsk
        onClose={() => setIsOpenAskModal(false)}
        open={isOpenAskModal}
        onSubmit={handleClickAskButton}
        isLoading={isSubmitting}
      />
    </div>
  );
};

CourseTabQna.Hash = '#qna';
CourseTabQna.Title = {
  value: 'Q&A',
  translationKey: null,
};

export default CourseTabQna;
