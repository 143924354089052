const GET_VOUCHER_CODE = 'GET_VOUCHER_CODE';
const SET_VOUCHER_CODE_LOADING = 'SET_VOUCHER_CODE_LOADING';
const SUBMIT_PAYMENT = 'SUBMIT_PAYMENT';
const SET_SUBMIT_PAYMENT_LOADING = 'SET_SUBMIT_PAYMENT_LOADING';
const SET_BANK_ACCOUNTS_LOADING = 'SET_BANK_ACCOUNTS_LOADING';
const GET_BANK_ACCOUNTS = 'GET_BANK_ACCOUNTS';
const PUT_UPLOAD_RECEIPT = 'PUT_UPLOAD_RECEIPT';
const SET_UPLOAD_RECEIPT_LOADING = 'SET_UPLOAD_RECEIPT_LOADING';
const SET_CANCEL_PAYMENT_LOADING = 'SET_CANCEL_PAYMENT_LOADING';

export const TYPES_PAYMENT = {
  GET_VOUCHER_CODE,
  SET_VOUCHER_CODE_LOADING,
  SUBMIT_PAYMENT,
  SET_SUBMIT_PAYMENT_LOADING,
  SET_BANK_ACCOUNTS_LOADING,
  GET_BANK_ACCOUNTS,
  PUT_UPLOAD_RECEIPT,
  SET_UPLOAD_RECEIPT_LOADING,
  SET_CANCEL_PAYMENT_LOADING,
};
