import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import PATH from '@/routes/path';
import { CLIENT } from '@/config';

const CompanyLogo = ({ src, width, link }) => {
  const logoStyle = { width };

  return link ? (
    <Link style={logoStyle} className="inline-flex items-center" to={PATH.HOME}>
      <img className="max-h-full max-w-full" src={src} alt="skydu cloud learner" />
    </Link>
  ) : (
    <span style={logoStyle} className="inline-flex items-center">
      <img className="max-h-full max-w-full" src={src} alt="skydu cloud learner" />
    </span>
  );
};

CompanyLogo.propTypes = {
  width: PropTypes.number,
  link: PropTypes.bool,
  src: PropTypes.string,
};

CompanyLogo.defaultProps = {
  width: 120,
  link: false,
  src: CLIENT.LOGO,
};

export default CompanyLogo;
