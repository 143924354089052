import { SERVICE_CATALOG } from '@/services';
import { HELPERS } from '@/utils';
import * as TYPES_CATALOG from './catalog.types';

const setSearchQuery = (query) => {
  return {
    type: TYPES_CATALOG.SET_SEARCH_QUERY,
    query,
  };
};

const getCatalog = (query) => {
  return (dispatch) => {
    dispatch({ type: TYPES_CATALOG.GET_CATALOG_LOADING, loading: true });

    SERVICE_CATALOG.getCatalog(query, (res) => {
      if (res.error) HELPERS.showNotificationServiceError(res.error);
      else {
        dispatch({ type: TYPES_CATALOG.GET_CATALOG, payload: res.payload });
      }
    });
  };
};

export const ACTION_CATALOG = { setSearchQuery, getCatalog };
