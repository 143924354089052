import { useSelector } from 'react-redux';
import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';

const Header = () => {
  const { loggedIn, profile } = useSelector((state) => state.auth);
  const HEIGHT = 85;

  return (
    <header className="fixed top-0 w-full shadow-lg bg-white z-40" style={{ height: HEIGHT }}>
      <HeaderMobile loggedIn={loggedIn} authProfile={profile} />
      <HeaderDesktop loggedIn={loggedIn} authProfile={profile} />
    </header>
  );
};

export default Header;
