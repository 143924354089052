import { SERVICE_QUIZ } from '@/services';
import { HELPERS } from '@/utils';
import { TYPES_QUIZ } from './quiz.types';

const getQuiz = (courseUUID) => {
  return (dispatch) => {
    dispatch({ type: TYPES_QUIZ.GET_QUIZ_LOADING, isLoading: true });

    SERVICE_QUIZ.getQuiz(courseUUID, (res) => {
      if (res.error) HELPERS.showNotificationServiceError(res.error);

      dispatch({
        type: TYPES_QUIZ.GET_QUIZ,
        payload: res.payload,
        error: res.error,
      });
    });
  };
};

const submitQuiz = (quizUUID, quizAnswers, callback) => {
  return (dispatch) => {
    dispatch({ type: TYPES_QUIZ.POST_QUIZ_ASSESSMENT_LOADING, isLoading: true });

    SERVICE_QUIZ.submitQuiz(quizUUID, quizAnswers, (res) => {
      if (res.error) HELPERS.showNotificationServiceError(res.error);
      else if (res.payload.is_passed) callback();

      dispatch({
        type: TYPES_QUIZ.POST_QUIZ_ASSESSMENT,
        payload: res.payload,
        error: res.error,
      });
    });
  };
};

const resetAssessment = () => {
  return (dispatch) => {
    dispatch({
      type: TYPES_QUIZ.POST_QUIZ_ASSESSMENT,
      payload: null,
      error: null,
    });
  };
};

export const ACTION_QUIZ = {
  getQuiz,
  submitQuiz,
  resetAssessment,
};
