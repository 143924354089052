const PATH = {
  HOME: '/',
  AUTH_LOGIN: '/login',
  AUTH_REGISTER: '/register',
  AUTH_FORGOT_PASSWORD: '/forgot-password',
  AUTH_RESET_PASSWORD: '/reset-password',
  AUTH_CREATE_NEW_PASSWORD: '/create-new-password',
  AUTH_OTP: '/otp',
  CATALOG: '/catalog',
  CATALOG_COURSE: '/catalog?item_type=course',
  CATALOG_EVENT: '/catalog?item_type=event',
  COURSE: '/course',
  COURSE_CERITIFICATE: '/course/ceritificate',
  EVENT: '/event',
  FAQ: '/faq',
  TICKET: '/ticket',
  PROFILE: '/profile',
  PROFILE_MY_TICKET: '/profile#my-ticket',
  PROFILE_MY_MODULE: '/profile#my-module',
  PROFILE_MY_PERFORMANCE: '/profile#performa-saya',
  PROFILE_MY_IDP: '/profile#idp-saya',
  PROFILE_UPDATE: '/profile/update',
  PROFILE_CHANGE_PASSWORD: '/profile/change-password',
  PAYMENT: '/payment',
  TRANSACTION: '/transaction',
  TRANSACTION_CANCELLED: '/transaction/cancelled',
};

export default PATH;
