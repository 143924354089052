import { AdsBanner } from '@/components/molecules';
import { HomeAdsSlider } from '../components/HomeAdsSlider/HomeAdsSlider';
import HomeSection from '../components/HomeSection/HomeSection';

function HomeAds({ data }) {
  return (
    <HomeSection className="bg-gray-100 pb-0">
      {data && (
        <HomeAdsSlider>
          {data.map((item) => (
            <AdsBanner key={item.id} className="w-full" image={item.image_url} url={item.related_url} />
          ))}
        </HomeAdsSlider>
      )}
    </HomeSection>
  );
}

export default HomeAds;
