import PropTypes from 'prop-types';
import { Container } from '@/components/atoms';

const AuthContainer = ({ children }) => {
  return (
    <div className="bg-gray-100 py-14">
      <Container>
        <div className="flex justify-center">
          <div className="bg-white rounded p-14 flex flex-col" style={{ width: 500 }}>
            {children}
          </div>
        </div>
      </Container>
    </div>
  );
};

AuthContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContainer;
