import { useState, useEffect } from 'react';
import { MdArrowBack } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Container, Button } from '@/components/atoms';
import { HELPERS, CONSTANTS } from '@/utils';
import PATH from '@/routes/path';
import { ACTION_PAYMENT } from '@/redux/actions';
import PaymentVoucher from './PaymentVoucher';
import './Payment.css';

const Payment = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [activeVoucher, setActiveVoucher] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const paymentMethod = CONSTANTS.PAYMENT_METHODS.MIDTRANS.value;

  const { isLoading } = useSelector((state) => state.payment.submit);

  const handleUseVoucher = (voucherData) => {
    setActiveVoucher({
      code: voucherData.code,
      amount: HELPERS.discountAmount(voucherData.discount, voucherData.max_amount, totalPrice),
    });
  };

  const handleCancelVoucher = () => {
    setActiveVoucher(null);
    setTotalPrice(location.state?.price);
  };

  const handleSubmitPayment = () => {
    const quantity = 1;
    dispatch(
      ACTION_PAYMENT.submitPayment(
        location.state?.uuid,
        activeVoucher?.code,
        location.state?.productType,
        paymentMethod,
        location.state?.item_data,
        quantity,
        ({ invoice_number: invoiceNumber }) => {
          history.push(`${PATH.TRANSACTION}/${invoiceNumber}`, null);
        }
      )
    );
  };

  useEffect(() => {
    if (location.state && 'price' in location.state && activeVoucher) {
      setTotalPrice(location.state?.price - activeVoucher.amount);
    } else if (location.state && 'price' in location.state) setTotalPrice(location.state?.price);
    else history.goBack();
  }, [location, activeVoucher]);

  useEffect(() => {
    return () => {
      history.replace({ ...history.location, state: {} });
      setActiveVoucher(null);
    };
  }, []);

  return (
    <div className="bg-gray-100 py-8">
      <Container mode={Container.MODE.NARROW}>
        <div className="bg-white shadow-2xl p-8 space-y-5">
          <div className="flex items-center">
            <button className="mr-3" type="button" onClick={() => history.goBack()}>
              <MdArrowBack size={22} />
            </button>
            <div className="font-semibold">Pembayaran</div>
          </div>

          {location.state?.price > 0 && (
            <PaymentVoucher onSuccess={handleUseVoucher} onCancel={handleCancelVoucher} />
          )}

          <div className="font-semibold">Detail</div>
          <table className="payment-table text-sm text-gray-700 w-full">
            <tbody>
              <tr>
                <td>{location.state?.title}</td>
                <td>
                  {location.state?.price > 0 ? `Rp ${HELPERS.numberFormat(location.state?.price || 0)}` : 'GRATIS'}
                </td>
              </tr>
              {activeVoucher && (
                <tr key={activeVoucher.title}>
                  <td>Kode Voucher {activeVoucher.code}</td>
                  <td className="text-red-500">- Rp {HELPERS.numberFormat(activeVoucher?.amount || 0)}</td>
                </tr>
              )}
              <tr className="border-t">
                <td>Total</td>
                <td className="font-semibold">
                  {totalPrice > 0 ? `Rp ${HELPERS.numberFormat(totalPrice)}` : 'GRATIS'}
                </td>
              </tr>
            </tbody>
          </table>
          <Button
            type="submit"
            isLoading={isLoading}
            onClick={handleSubmitPayment}
            className="w-full"
            variant={Button.VARIANTS.PRIMARY}
          >
            Lanjutkan
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default Payment;
