import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Notification, { notify } from 'react-notify-toast';
import TopLoader from 'react-top-loader';
import { Online, Offline } from 'react-detect-offline';
import { COLOR } from '@/config';
import { Header, SideNav, CatalogMenu, Footer, OfflineContent, BottomNav } from '@/components/organisms';
import Routes from './routes';

const App = () => {
  const history = useHistory();

  const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';
  const { showTopLoadingBar, showHeader, showFooter, showBottomNav } = useSelector((state) => state.activity);
  const isLoggedIn = useSelector((state) => state.auth.token);

  const handleNetworkChange = (online) => {
    const GO_TO_OFFLINE_MESSAGE = 'Tidak bisa terhubung ke server, silahkan cek koneksi Anda';
    const GO_TO_ONLINE_MESSAGE = 'Koneksi telah terhubung';
    const message = online ? GO_TO_ONLINE_MESSAGE : GO_TO_OFFLINE_MESSAGE;
    notify.show(message, online ? 'success' : 'warning');
  };

  useEffect(() => {
    const unlisten = history.listen(() => window.scrollTo({ top: 0 }));
    return () => unlisten();
  }, []);

  return (
    <>
      <Notification />
      <TopLoader
        show={showTopLoadingBar}
        backgroundColor="#ddd"
        thickness={3}
        zIndex={100}
        delay={0}
        color={COLOR.PRIMARY}
        duration={1000}
      />

      {showHeader && <Header />}

      <SideNav />
      <CatalogMenu />

      <div className={`${showHeader && 'mt-20'}`}>
        {IS_DEVELOPMENT && <Routes />}
        {!IS_DEVELOPMENT && (
          <>
            <Online onChange={handleNetworkChange}>
              <Routes />
            </Online>
            <Offline onChange={handleNetworkChange}>
              <OfflineContent />
            </Offline>
          </>
        )}
      </div>

      {isLoggedIn && showBottomNav && <BottomNav />}
      {showFooter && <Footer />}
    </>
  );
};

export default App;
