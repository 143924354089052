import PropTypes from 'prop-types';
import { FiMenu } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { ACTION_ACTIVITY } from '@/redux/actions';
import { HELPERS } from '@/utils';
import Avatar from '@/images/avatar/avatar.png';

const HeaderButtonSideNav = ({ loggedIn, authProfile, desktopMode }) => {
  const dispatch = useDispatch();
  const handleToggleSideNav = () => dispatch(ACTION_ACTIVITY.toggleSideNav());

  const className = !desktopMode ? 'absolute right-0 inset-y-0' : '';

  return (
    <button
      className={`${className} px-5 inline-block cursor-pointer hover:bg-gray-200`}
      onClick={() => handleToggleSideNav()}
      type="button"
    >
      {!loggedIn && <FiMenu size="22" />}
      {loggedIn && (
        <div className="flex items-center space-x-2">
          <img className="rounded-full w-12" src={authProfile?.avatar_url || Avatar} alt="Avatar" />
          {desktopMode && authProfile && (
            <span className="text-sm">{HELPERS.truncateText(authProfile.name, 15)}</span>
          )}
        </div>
      )}
    </button>
  );
};

HeaderButtonSideNav.propTypes = {
  desktopMode: PropTypes.bool,
};

HeaderButtonSideNav.defaultProps = {
  desktopMode: false,
};

export default HeaderButtonSideNav;
