import { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { HiLockClosed } from 'react-icons/hi';
import { CgDanger } from 'react-icons/cg';
import { AiFillCheckCircle, AiFillWarning } from 'react-icons/ai';
import { MdVideoLibrary, MdAudiotrack, MdPictureAsPdf, MdBookmark } from 'react-icons/md';
import Skeleton from 'react-loading-skeleton';
import { CONSTANTS } from '@/utils';

const CourseTopicList = ({ title, resources, activeResource, open, onChangeActiveResource }) => {
  const isLoggedIn = useSelector((state) => state.auth.loggedIn);

  const [isOpen, setIsOpen] = useState(open);

  return (
    <div>
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="w-full flex text-left items-center justify-between space-x-3 cursor-pointer px-6 py-5 border-b bg-white hover:bg-gray-50"
      >
        <span className="text-sm font-semibold line-clamp-2 leading-relaxed">{title}</span>
        <span>
          {isOpen && <FiChevronUp size={20} />}
          {!isOpen && <FiChevronDown size={20} />}
        </span>
      </button>

      <div>
        {isOpen && resources?.length === 0 && (
          <div className="p-6 text-center text-gray-400 h-full flex items-center justify-center space-x-3 text-sm border-b">
            <CgDanger size={18} />
            <span>No Resource Found</span>
          </div>
        )}

        {isOpen &&
          resources?.length > 0 &&
          resources.map((resource) => (
            <button
              key={resource.uuid}
              type="button"
              className={`w-full flex items-center p-6 cursor-pointer border-b bg-primary hover:bg-opacity-20 ${
                activeResource && activeResource.uuid === resource.uuid
                  ? 'bg-opacity-10 border-l-4 border-l-primary'
                  : 'bg-opacity-0'
              }`}
              onClick={() => onChangeActiveResource(resource)}
            >
              <div className="mr-5">
                {resource?.resource_type === CONSTANTS.LEARNING_RESOURCE_TYPE.VIDEO && (
                  <MdVideoLibrary size="22" />
                )}
                {resource?.resource_type === CONSTANTS.LEARNING_RESOURCE_TYPE.AUDIO && <MdAudiotrack size="22" />}
                {resource?.resource_type === CONSTANTS.LEARNING_RESOURCE_TYPE.PDF && <MdPictureAsPdf size="22" />}
                {resource?.resource_type === CONSTANTS.LEARNING_RESOURCE_TYPE.QUIZ && <MdBookmark size="22" />}
                {!resource?.resource_type && <AiFillWarning size="22" />}
              </div>
              <div className="flex-1 space-y-1">
                <div className="text-left text-sm">{resource.title}</div>
                <div className="text-xs text-gray-400 flex items-center space-x-3">
                  {(!isLoggedIn || resource.locked) && (
                    <span className="flex items-center space-x-1">
                      <HiLockClosed size={12} /> <span>Locked</span>
                    </span>
                  )}
                  {isLoggedIn && !resource.locked && (
                    <>
                      {resource.resource_type === CONSTANTS.LEARNING_RESOURCE_TYPE.VIDEO && (
                        <div>
                          <span>{resource.resource_length?.length > 1 ? resource.resource_length : '00:00'}</span>
                        </div>
                      )}
                      {resource.resource_type === CONSTANTS.LEARNING_RESOURCE_TYPE.QUIZ && (
                        <div>
                          <span>{resource.resource_length || 'No Question'}</span>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="text-right ml-5">
                {resource.completed && <AiFillCheckCircle size={20} className="text-green-700" />}
              </div>
            </button>
          ))}
      </div>
    </div>
  );
};

CourseTopicList.Skeleton = () => {
  const renderAccordionItem = () => {
    return (
      <div className="flex flex-col space-y-1 border-b-2 px-6 py-3">
        <div className="flex items-center space-x-3">
          <Skeleton height={40} width={40} />
          <div className="w-full">
            <Skeleton height={25} />
            <Skeleton width={150} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col space-y-1">
      <div>
        <div className="flex flex-col space-y-1 border-b-2 px-6 py-4">
          <Skeleton height={40} />
        </div>
        <div>
          {renderAccordionItem()}
          {renderAccordionItem()}
          {renderAccordionItem()}
        </div>
      </div>
    </div>
  );
};

CourseTopicList.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool,
};

CourseTopicList.defaultProps = {
  open: false,
};

export default CourseTopicList;
