import PropTypes from 'prop-types';

// NOTE the wrapper should be : wrapper={(childs) => <Component>{childs}</Component>}

const ConditionalWrapper = ({ condition, wrapper, children }) => {
  return condition ? <>{wrapper(children)}</> : <>{children}</>;
};

ConditionalWrapper.propTypes = {
  condition: PropTypes.bool.isRequired,
  wrapper: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

export default ConditionalWrapper;
