import http, { getAuthConfig } from './http';

const BASE_URL = '/search';

const getCatalog = (query = '', callback) => {
  http
    .get(`${BASE_URL}${query}`, getAuthConfig())
    .then((res) => callback({ payload: res.data.payload, error: null, success: true }))
    .catch((error) => callback({ payload: null, error: error.response, success: false }));
};

export const SERVICE_CATALOG = { getCatalog };
