exports.COLOR = {
  PRIMARY: {
    100: '#ddf4f8',
    200: '#bce9f0',
    300: '#9adee9',
    400: '#79d3e1',
    DEFAULT: '#57c8da',
    500: '#57c8da',
    600: '#46a0ae',
    700: '#347883',
    800: '#235057',
    900: '#11282c',
  },
  SECONDARY: {
    100: '#ccd2df',
    200: '#99a6bf',
    300: '#6679a0',
    400: '#334d80',
    DEFAULT: '#002060',
    500: '#002060',
    600: '#001a4d',
    700: '#00133a',
    800: '#000d26',
    900: '#000613',
  },
};
