const GET_COURSE_LIST_DATA = 'GET_COURSE_LIST_DATA';
const SET_COURSE_LIST_LOADING = 'SET_COURSE_LIST_LOADING';
const GET_COURSE_DETAIL_DATA = 'GET_COURSE_DETAIL_DATA';
const SET_COURSE_DETAIL_LOADING = 'SET_COURSE_DETAIL_LOADING';
const SET_COURSE_REPORT_LOADING = 'SET_COURSE_REPORT_LOADING';
const GET_COURSE_REPORT_DATA = 'GET_COURSE_REPORT_DATA';
const SET_COURSE_COMMENT_LOADING = 'SET_COURSE_COMMENT_LOADING';
const SET_COURSE_COMMENT_SUBMITTING = 'SET_COURSE_COMMENT_SUBMITTING';
const GET_COURSE_COMMENT_DATA = 'GET_COURSE_COMMENT_DATA';
const SET_COURSE_COMMENT_REPORT_SUBMITTING = 'SET_COURSE_COMMENT_REPORT_SUBMITTING';

export const TYPES_COURSE = {
  GET_COURSE_LIST_DATA,
  SET_COURSE_LIST_LOADING,
  GET_COURSE_DETAIL_DATA,
  SET_COURSE_DETAIL_LOADING,
  SET_COURSE_REPORT_LOADING,
  GET_COURSE_REPORT_DATA,
  SET_COURSE_COMMENT_LOADING,
  SET_COURSE_COMMENT_SUBMITTING,
  GET_COURSE_COMMENT_DATA,
  SET_COURSE_COMMENT_REPORT_SUBMITTING,
};
