import ReactPlayer from 'react-player';
import { Container, Button } from '@/components/atoms';
import { useTranslation } from 'react-i18next';
import PATH from '@/routes/path';
import { CLIENT } from '@/config';
import BannerSkeleton from '../skeletons/HomeBannerSkeleton';

const HomeBanner = () => {
  const { t } = useTranslation();
  const isLoading = true;
  const isShowVideo = true;

  return (
    <div className="py-28 relative bg-gray-100">
      <Container>
        {!isLoading && <BannerSkeleton />}
        {isLoading && (
          <>
            <div className="absolute left-0 top-0 w-full h-full">
              <div className="absolute left-0 top-0 w-full h-full bg-secondary opacity-70" />
              {CLIENT.BANNER && <img className="w-full h-full object-cover" src={CLIENT.BANNER} alt="" />}
            </div>

            <div className="relative flex flex-col-reverse md:flex-row items-center space-y-reverse space-y-10 md:space-y-0 md:space-x-10">
              <div className="w-full md:w-1/2">
                <h1 className="text-white text-4xl mb-3 font-bold leading-snug capitalize">{CLIENT.NAME}</h1>
                <h3 className="text-white font-primary text-opacity-70 text-lg mb-10 leading-relaxed">
                  {CLIENT.DESCRIPTION}
                </h3>
                <Button linkTo={PATH.CATALOG}>{t('explore_module')}</Button>
              </div>

              {isShowVideo && CLIENT.CAMPAIGN_VIDEO && (
                <div className="w-full md:w-1/2 rounded">
                  <div className="shadow-lg rounded overflow-hidden">
                    <ReactPlayer controls width="100%" height="300px" url={CLIENT.CAMPAIGN_VIDEO} />
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </Container>
    </div>
  );
};

export default HomeBanner;
