import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ACTION_HOME } from '@/redux/actions';
import HomeBanner from './sections/HomeBanner';
import HomeAbout from './sections/HomeAbout';
import HomeModule from './sections/HomeModule';
import HomeAds from './sections/HomeAds';
import HomeContinueModule from './sections/HomeContinueModule';

const Home = () => {
  const dispatch = useDispatch();
  const homeData = useSelector((state) => state.home);

  useEffect(() => dispatch(ACTION_HOME.getHomeData()), []);

  return (
    <>
      <HomeBanner />
      <HomeAbout />
      {homeData.payload?.last_accessed_course && (
        <HomeContinueModule data={homeData.payload.last_accessed_course} />
      )}
      <HomeAds data={homeData.payload?.banners} />
      <HomeModule
        isLoading={homeData.isLoading}
        error={homeData.error}
        data={homeData.payload ? homeData.payload.sections : null}
      />
    </>
  );
};

export default Home;
