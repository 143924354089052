import * as TYPES_EVENT from './event.types';

const INITIAL_STATE = {
  detail: {
    payload: null,
    loading: true,
  },
  ticket: {
    payload: null,
    loading: true,
  },
};

const eventReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES_EVENT.GET_EVENT_DETAIL_LOADING:
      return {
        ...state,
        detail: { ...state.detail, loading: action.loading },
      };
    case TYPES_EVENT.GET_EVENT_DETAIL_DATA:
      return {
        ...state,
        detail: {
          payload: action.payload,
          loading: false,
        },
      };

    case TYPES_EVENT.GET_TICKET_LOADING:
      return {
        ...state,
        ticket: { ...state.ticket, loading: action.loading },
      };
    case TYPES_EVENT.GET_TICKET_DATA:
      return {
        ...state,
        ticket: {
          payload: action.payload,
          loading: false,
        },
      };
    default:
      return state;
  }
};

export default eventReducer;
