import http, { getAuthConfig } from './http';

const BASE_URL = '/auth';

const login = (data, callback) => {
  http
    .post(`${BASE_URL}/login`, data)
    .then((res) => callback({ payload: res.data.payload, error: null, success: true }))
    .catch((error) => callback({ payload: null, error, success: false }));
};

const register = (data, callback) => {
  http
    .post(`${BASE_URL}/register`, data)
    .then((res) => callback({ payload: res.data.payload, error: null, success: true }))
    .catch((error) => callback({ payload: null, error, success: false }));
};

const forgotPassword = (data, callback) => {
  http
    .post(`${BASE_URL}/password/forget`, data)
    .then((res) => callback({ payload: res.data.payload, error: null, success: true }))
    .catch((error) => callback({ payload: null, error, success: false }));
};

const resetPassword = (password, confirmPassword, params, callback) => {
  const data = {
    email: decodeURIComponent(params.email),
    token: params.token,
    password,
    password_confirmation: confirmPassword,
  };

  http
    .post(`${BASE_URL}/password/reset`, data)
    .then((res) => callback({ payload: res.data.payload, error: null, success: true }))
    .catch((error) => callback({ payload: null, error, success: false }));
};

const changePassword = (oldPassword, newPassword, callback) => {
  const data = {
    old_password: oldPassword,
    new_password: newPassword,
    new_password_confirmation: newPassword,
  };

  http
    .post(`${BASE_URL}/password`, data, getAuthConfig())
    .then((res) => callback({ payload: res.data.payload, error: null, success: true }))
    .catch((error) => callback({ payload: null, error, success: false }));
};

const emailActivation = (email, token, callback) => {
  const data = {
    email: decodeURIComponent(email),
    token,
  };

  http
    .post(`${BASE_URL}/email/verify`, data)
    .then((res) => callback({ payload: res.data.payload, error: null, success: true }))
    .catch((error) => callback({ payload: null, error, success: false }));
};

export const SERVICE_AUTH = { login, register, forgotPassword, resetPassword, changePassword, emailActivation };
