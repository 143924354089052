import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { MdFavorite, MdFavoriteBorder } from 'react-icons/md';
import { ACTION_COURSE } from '@/redux/actions';

const QnaLikeButton = ({ uuid, hasLiked, count }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const UNLIKE = 0;
  const LIKE = 1;

  const [hasLikedState, setHasLikedState] = useState(hasLiked);
  const [countState, setCountState] = useState(count);

  const handleLike = () => {
    setHasLikedState(!hasLikedState);
    setCountState(hasLikedState ? countState - 1 : countState + 1);

    dispatch(ACTION_COURSE.setCourseCommentLike(uuid, hasLiked ? UNLIKE : LIKE));
  };

  return (
    <button
      className={`flex items-center space-x-2 ${
        hasLikedState ? 'text-primary' : 'text-gray-400'
      } hover:text-primary transition-all text-xs md:text-sm`}
      type="button"
      onClick={handleLike}
    >
      {hasLikedState ? <MdFavorite size={16} /> : <MdFavoriteBorder size={16} />}
      <span>
        {countState || 0} {t('like')}
      </span>
    </button>
  );
};

export default QnaLikeButton;
